import React from 'react';
import { DashboardSkeleton } from './DashboardSkeleton';
import { DashboardError } from './DashboardError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { DashboardIcon } from '../Header/Icons';
import { Title } from '../Header';
import { LastRefreshDate } from '../Header/LastRefreshDate';


const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const basePath = '/api/metrics/v1';

const type = 'book-of-business';
const dashboardApiPath = `${basePath}/${type}`;

export function BookOfBusiness() {
  const dashboardDetails = useViewDetails(dashboardApiPath);
  const { fetching, success, error, data } = dashboardDetails;

  const right = <LastRefreshDate />;

  return (
    <>
      <Title
        icon={DashboardIcon}
        titleText={'Dashboard'}
        right={right}
      />
      {fetching && <DashboardSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <DashboardError />}
    </>
  );
}
