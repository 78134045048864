import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
}));

export function RightAlignedCardActions(props) {
  const classes = useStyles();
  return (
    <CardActions className={classes.actions}>{props.children}</CardActions>
  );
}
