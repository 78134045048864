import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 436,
    minHeight: 300,
  },
  titleRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 0,
    background: '#CBD5DD',
    '& h2': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 'initial',
    color: '#4A4A4A',
  },
  saveChangesBtn: {
    background: '#2D6089',
    borderRadius: 4,
    '&:hover': {
      background: '#2D6089',
    },
  },
  disableBtn: {
    background: 'rgba(0, 0, 0, 0.26)',
  },
  saveLabel: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
  closeBtn: {
    background: '#d5dfe7',
    borderRadius: 4,
    '&:hover': {
      background: '#d5dfe7',
    },
  },
  closeBtnLabel: {
    color: '#2D6089',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
}));

export function CustomizeDataAccessDialog(props) {
  const {
    open,
    onClose,
    saveFilters,
    setMode,
    FILTER_MODE_KEY,
    AMS_MODE_KEY,
    mode,
    infoChange,
    onlyOn,
  } = props;
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogTitle classes={{ root: classes.titleRoot, h6: classes.titleFlex }}>
        <Typography className={classes.dialogTitle}>
          Customize Data Access
        </Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button
          classes={{ root: classes.closeBtn, label: classes.closeBtnLabel }}
          onClick={onClose}
        >
          close
        </Button>
        {mode === AMS_MODE_KEY && (
          <>
            {onlyOn && (
              <Tooltip
                title={
                  onlyOn ? 'Cannot switch off customize data access' : null
                }
                placement="start-top"
              >
                <span>
                  <Button
                    classes={{
                      root: onlyOn
                        ? classes.disableBtn
                        : classes.saveChangesBtn,
                      label: classes.saveLabel,
                    }}
                    disabled={true}
                  >
                    Switch Off
                  </Button>
                </span>
              </Tooltip>
            )}

            {!onlyOn && (
              <Button
                classes={{
                  root: onlyOn ? classes.disableBtn : classes.saveChangesBtn,
                  label: classes.saveLabel,
                }}
                onClick={() => {
                  setMode(AMS_MODE_KEY);
                  saveFilters();
                }}
                disabled={onlyOn}
              >
                Switch Off
              </Button>
            )}
          </>
        )}
        {mode === AMS_MODE_KEY && (
          <Button
            classes={{
              root: !infoChange ? classes.disableBtn : classes.saveChangesBtn,
              label: classes.saveLabel,
            }}
            onClick={() => {
              if (infoChange) {
                setMode(FILTER_MODE_KEY);
                saveFilters();
              } else {
                onClose();
              }
            }}
            disabled={!infoChange}
          >
            Save Changes
          </Button>
        )}
        {mode === FILTER_MODE_KEY && (
          <Button
            classes={{
              root: classes.saveChangesBtn,
              label: classes.saveLabel,
            }}
            onClick={() => {
              setMode(FILTER_MODE_KEY);
              saveFilters();
            }}
          >
            Save changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
