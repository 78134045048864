import React from 'react';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView/TableauMarkSelectionView';
import { FilterWidgetSkeleton } from './FilterWidgetSkeleton';
import { FilterWidgetError } from './FilterWidgetError';
import PropTypes from 'prop-types';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '300px',
  vizWidth: '100%',
  vizHeight: '300px',
};

const PATH = `/api/metrics/v1/caseFilterPreview/`;

export function TableauFilterWidget(props) {
  const { onFilter, userId } = props;
  const { fetching, success, error, data } = useViewDetails(`${PATH}${userId}`);

  return (
    <div>
      {fetching && <FilterWidgetSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
          onFilter={onFilter}
        />
      )}
      {error && <FilterWidgetError />}
    </div>
  );
}
TableauFilterWidget.propTypes = {
  userId: PropTypes.string.isRequired,
  onFilter: PropTypes.func,
};
