import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/quotes/v1/getQuoteEnabled';

export function useGetQuoteEnabled(getEnableState) {
  const [quoteEnabled, setQuoteEnabled] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getQuoteEnabled = async () => {
      setQuoteEnabled({ fetching: true, error: false, success: false });
      try {
        const response = await axios.get(path);
        if (response.status !== 200) {
          setQuoteEnabled({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setQuoteEnabled({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
        }
      } catch (e) {
        setQuoteEnabled({ fetching: false, error: true, success: false });
      }
    };
    if (getEnableState === true) {
      getQuoteEnabled();
    }
  }, [getEnableState]);
  return quoteEnabled;
}
