import axios from 'axios';

export const setActivationEmailResend = async ({ userID }) => {
  const path = `/api/admin/v1/user/activationEmail/${userID}`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const userDetails = {};
  const { status, data } = await axios.post(path, userDetails, { headers });
  return { status, data };
};
