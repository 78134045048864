import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useGetSubUsers } from './useGetSubUsers';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListSubheader from '@material-ui/core/ListSubheader';
import Skeleton from '@material-ui/lab/Skeleton';
import { gaEvent } from '../GoogleAnalytics/gaEvent';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 30,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#fff',
    fontWeight: 500,
  },
  menuItem: {
    fontWeight: 500,
    fontSize: 14,
    marginRight: 24,
    '&.Mui-selected': {
      background: '#DEE4E8',
      borderRadius: 3,
      marginLeft: 4,
      marginRight: 4,
    },
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  check: {
    position: 'absolute',
    right: 6,
  },
}));

const TeamSelector = (props) => {
  const classes = useStyles();
  const { value, setValue, page } = props;
  const handleChange = (event) => {
    setValue(event.target.value);
    gaTracking(event.target.value);
  };
  const { fetching, success, data } = useGetSubUsers();

  const gaTracking = (userId) => {
    const category = 'TeamClick';
    const action = 'From ' + page;
    const label = '';
    const value = userId;
    gaEvent(category, action, label, value);
  };

  const check = (
    <div className={classes.check}>
      <CheckIcon color="primary" />
    </div>
  );

  function getLabel(user) {
    const { userInitials, firstName, lastName, userType } = user;
    const label = `${userInitials} - ${firstName} ${lastName} (${
      userType.charAt(0).toUpperCase() + userType.slice(1)
    })`;
    return label;
  }

  let subUsers;
  let menuItems;
  if (success) {
    const myBusiness = (
      <MenuItem key={-2} value={-1} classes={{ root: classes.menuItem }}>
        My Business {value === -1 && check}
      </MenuItem>
    );
    let category;
    data && data.length > 0
      ? (category = <ListSubheader key={-1}>Team</ListSubheader>)
      : (category = (
          <ListSubheader key={-1}>No Team Members Available</ListSubheader>
        ));

    subUsers = data.map((user, index) => {
      const { userID } = user;
      const label = getLabel(user);
      return (
        <MenuItem
          key={index}
          value={userID}
          classes={{ root: classes.menuItem }}
        >
          {label}
          {value === userID && check}
        </MenuItem>
      );
    });

    menuItems = [myBusiness, category, subUsers];
  }

  return (
    <>
      {fetching && <Skeleton variant="rect" width="150" height="30" />}
      {success && (
        <>
          <Typography variant="subtitle1" className={classes.label}>
            Select View
          </Typography>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            color="primary"
          >
            <Select
              labelId="team-selector-label"
              id="team-selector"
              value={value}
              onChange={handleChange}
              classes={{
                root: classes.select,
              }}
              color="primary"
              IconComponent={ExpandMoreIcon}
              renderValue={() => {
                if (value) {
                  if (value < 0) {
                    return 'My Business';
                  } else {
                    let label;
                    data.forEach((user) => {
                      if (user.userID === value) {
                        label = getLabel(user);
                      }
                    });
                    return label;
                  }
                }
              }}
            >
              {menuItems}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
};

TeamSelector.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

export { TeamSelector };
