import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/ai/v1/rule/bundles';

export function useRuleBundles(getRules, setGetRules) {
  const [ruleBundles, setRuleBundles] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getRuleBundles = async () => {
      setRuleBundles({ fetching: true, error: false, success: false });
      try {
        const random = Math.random();
        const response = await axios.get(`${path}?random=${random}`);
        if (response.status !== 200 || (response.data && response.data.error === true)) {
          setRuleBundles({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setRuleBundles({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
        }
      } catch (e) {
        setRuleBundles({ fetching: false, error: true, success: false });
      }
      setGetRules(false);
    };
    if (getRules === true) {
      getRuleBundles();
    }
  }, [getRules]);
  return ruleBundles;
}
