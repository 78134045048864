import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';

export default function BackButton(props) {
  const history = useHistory();

  const back = () => {
    history.push(props.parentPath);
  };
  return (
    <IconButton color="primary" onClick={back} aria-label="back">
      <ArrowBackIcon />
    </IconButton>
  );
}

BackButton.propTypes = {
  parentPath: PropTypes.string.isRequired,
};
