import axios from 'axios';
import { useEffect, useState } from 'react';

function useValidateEmailID(
  emailValidationEndpoint,
  emailValidation,
  stopEmailValidation,
  emailToBeValidated
) {
  const [response, setResponse] = useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });

  const path = `${emailValidationEndpoint}`;

  const headers = {
    'Content-Type': 'application/json',
  };

  const requestObject = {
    emailID: emailToBeValidated,
  };

  useEffect(() => {
    const validateEmailId = async () => {
      setResponse({
        fetching: true,
        success: false,
        error: false,
        data: undefined,
      });
      try {
        stopEmailValidation();
        const { error, data } = await axios.post(path, requestObject, {
          headers,
        });
        if (error || data?.error) {
          setResponse({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setResponse({
            fetching: false,
            success: true,
            error: false,
            data: data.data,
          });
        }
      } catch (error) {
        setResponse({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      }
    };
    if (emailValidation) {
      validateEmailId();
    }
  }, [
    path,
    headers,
    requestObject,
    stopEmailValidation,
    emailValidation,
    emailToBeValidated,
  ]);

  return response;
}

export default useValidateEmailID;
