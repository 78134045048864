import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import { JiraFabWithDialog } from './../Jira';
import { CustomerCount } from '../Header/CustomerCount';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '100%',
    minHeight: '100vh',
  },
  paper: {
    width: '100vw !important',
  },
  content: {},
  customerCount: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    right: 83,
    top: 8,
  },
  floatLeftTop: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(4),
    zIndex: theme.zIndex.appBar,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function OneViewDialog(props) {
  const { open, handleClose } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen={true}
        maxWidth={false}
        scroll="body"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.dialogContent}>
          <div className={classes.customerCount}>
            <CustomerCount view="oneview" />
          </div>
          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            className={classes.floatLeftTop}
          >
            <ArrowBackIcon />
          </IconButton>
          <JiraFabWithDialog />
          <div className={classes.content}>{props.children}</div>
        </div>
      </Dialog>
    </div>
  );
}

OneViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
