import axios from 'axios';

export const removeAvatar = async () => {
  const path = '/api/image/v1/clearAvatar';
  const headers = {
    'Content-Type': 'application/json',
  };
  const { status, data } = await axios.post(path, {}, { headers });
  return { status, data };
};
