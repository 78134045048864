import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { useUserDetails } from '../useUserDetails';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { LogoutIcon } from './Icons';

const logoutPath = '/api/auth/logout';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftPadded: {
    paddingLeft: theme.spacing(3),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
  menuItemChip: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    minHeight: theme.spacing(5),
  },
  rightPadded: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.6),
  },
  userName: {
    maxWidth: 532,
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  paperMenu: {
    top: '69px !important',
  },
}));

export function AgencySetupUserInfo() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { success, data: userDetails } = useUserDetails();
  const username = user.getFullName();
  const initials = user.getInitials();
  let avatarURL;
  if (success) {
    ({ avatarURL } = userDetails);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    window.location.href = logoutPath;
  };
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="profile"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar alt={username} className={classes.avatar} src={avatarURL}>
          {initials}
        </Avatar>
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.paperMenu }}
      >
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          <ListItemIcon className={classes.listIcon}>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
