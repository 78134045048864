import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';

function LoginMessage(props) {
  const { type } = props;
  let severity;
  let title;
  let message;
  if (type === 'error') {
    severity = 'info';
    title = 'Logging In';
    message = 'Getting you logged in into the app';
  } else if (type === 'info') {
    severity = 'info';
    title = 'Not Logged In';
    message = 'You must login to use the application';
  } else if (type === 'logout') {
    severity = 'info';
    title = 'Logged Out';
    message = 'You have successfully logged out';
  }
  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}

LoginMessage.propTypes = {
  type: PropTypes.oneOf(['error', 'info', 'logout']),
};

export { LoginMessage };
