import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  overlay: (props) => ({
    position: 'absolute',
    width: props.width,
    height: props.height,
    top: props.top,
    left: props.left,
    overflow: 'auto',
    backgroundColor: 'white',
  }),
});

export function Overlay({ children, ...otherProps }) {
  const classes = useStyles(otherProps);
  return <div className={classes.overlay}>{children}</div>;
}

Overlay.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};
