import React from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  alignSaveButton: {
    marginTop: theme.spacing(2),
  },
  centered: {
    alignItems: 'center',
  },
  checked: {
    color: '#4C94EC !important',
  },
  track: {
    backgroundColor: '#4C94EC !important',
  },
}));

function ActionList(props) {
  const classes = useStyles();
  const { checked, enabledSaveFilter, onlyOn, onlyOff, isDisable } = props;
  const saveFilters = () => {
    props.saveFilters();
  };
  const toggleChecked = (toCheck) => {
    props.toggleChecked(toCheck);
  };
  let toCheck;

  const noAction = onlyOn || onlyOff;
  let title;
  if (onlyOn) {
    toCheck = true;
    title = 'Custom data access is mandatory for this user';
  } else if (onlyOff) {
    toCheck = false;
    title = 'Custom data access is not available for this role';
  } else {
    toCheck = checked;
    title =
      'By default, DONNA shows users the customers they are assigned to in your agency management system. This switch allows you to override these setting to customize which agency customers this user has access to.';
  }

  return (
    <Switch
      checked={toCheck}
      onChange={(e) => toggleChecked(toCheck)}
      name="amsMode"
      disabled={!isDisable}
      classes={{
        checked: classes.checked,
        track: toCheck && classes.track,
      }}
    />
  );
}

ActionList.prototypes = {
  checked: PropTypes.bool.isRequired,
  enabledSaveFilter: PropTypes.bool.isRequired,
  saveFilters: PropTypes.func.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  onlyOn: PropTypes.bool.isRequired,
  onlyOff: PropTypes.bool.isRequired,
};

export default ActionList;
