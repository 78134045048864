import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/ai/v1/rule/set/bundles';

export function useSaveBundleChanges(
  saveBundleChanges,
  changes,
  setSaveBundleChanges,
  openSnackbar
) {
  const [bundleChanges, setBundleChanges] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const setChanges = async () => {
      setBundleChanges({ fetching: true, error: false, success: false });
      try {
        const payload = Object.keys(changes).map((key) => ({ bundleId: key, enabled: changes[key] }));
        const response = await axios.post(path, payload);
        if (response.status !== 200 || response.data.error === true) {
          setBundleChanges({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setBundleChanges({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
          setSaveBundleChanges(false);
          openSnackbar();
        }
      } catch (e) {
        setBundleChanges({ fetching: false, error: true, success: false });
      }
    };
    if (saveBundleChanges) {
      setChanges();
    }
  }, [
    saveBundleChanges,
    changes,
    setSaveBundleChanges,
  ]);
  return bundleChanges;
}
