import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from '@aureus/donna-roles';
import { getLocationMapping, getReportsToMapping } from './columns';
import { CellDiff } from './CellDiff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import getDuplicateUser from './getDuplicateUser';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  warningDiv: {
    display: 'flex',
  },
}));

const STATUS = {
  NO_DETAILS: 'no-details',
  PENDING_ACTIVATION: 'pending-activation',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
};

const getStatus = (userData) => {
  const { hasBasicDetails, graviteeProfileCreated, graviteeProfileActivated } =
    userData;
  let status;
  if (!hasBasicDetails) {
    status = STATUS.NO_DETAILS;
  } else if (!graviteeProfileCreated) {
    status = STATUS.PENDING_ACTIVATION;
  } else if (graviteeProfileActivated) {
    status = STATUS.ACTIVE;
  } else if (!graviteeProfileActivated) {
    status = STATUS.SUSPENDED;
  }
  return status;
};

export function ConfirmationDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    onNewConfirm,
    onChangeConfirm,
    onUpdateAndActivateConfirm,
    updateData,
    users,
    locations,
    onNewAndActivateConfirm,
    onChangeAndActivateConfirm,
  } = props;
  const classes = useStyles();
  const { isEdit, oldData: oldUserData, newData: userData, isNew } = updateData;
  let userInitials,
    firstName,
    oldFirstName,
    lastName,
    oldLastName,
    emailID,
    userType,
    oldUserType,
    reportsTo,
    oldReportsTo,
    location,
    oldLocation,
    designation,
    oldDesignation;
  if (oldUserData) {
    ({
      firstName: oldFirstName,
      lastName: oldLastName,
      userType: oldUserType,
      reportsTo: oldReportsTo,
      location: oldLocation,
      designation: oldDesignation,
    } = oldUserData);
  }
  if (userData) {
    ({
      userInitials,
      firstName,
      lastName,
      emailID,
      userType,
      reportsTo,
      location,
      designation,
    } = userData);
  }
  const userMapping = getReportsToMapping(users);
  const locationMapping = getLocationMapping(locations);
  let duplicateUserDetails = {};

  const status = getStatus(userData);
  const pendingActivation = status === STATUS.PENDING_ACTIVATION;
  const noDetails = status === STATUS.NO_DETAILS;

  const showActivationBtn = pendingActivation || isNew || noDetails;

  const validateNewUser = (newUser) => {
    const duplicateUser = getDuplicateUser(users, newUser);
    if (duplicateUser.emailID) {
      duplicateUserDetails = duplicateUser;
      return true;
    } else {
      return false;
    }
  };
  const duplicateError = isEdit || isNew ? validateNewUser(userData) : false;
  const disableConfirm =
    validateNewUser(userData) && duplicateUserDetails.graviteeProfileCreated;
  const confirm = (activateUser) => {
    const userDetails = getReportToValue(userData);
    if (activateUser && isEdit) {
      onChangeAndActivateConfirm(userDetails);
    } else if (activateUser && isNew) {
      onNewAndActivateConfirm(userDetails);
    } else if (isEdit) {
      onChangeConfirm(userDetails);
    } else if (isNew) {
      onNewConfirm(userDetails);
    } else if (activateUser) {
      onUpdateAndActivateConfirm(userDetails);
    } else {
      onConfirm(userDetails);
    }
    onClose();
  };
  let title;
  let confirmBtnText;
  let activateBtnText;
  if (isNew) {
    title = 'Confirm New User Details';
    confirmBtnText = 'Save';
    activateBtnText = 'Save & Activate';
  } else {
    title = 'Confirm User Details Change';
    confirmBtnText = 'Update';
    activateBtnText = 'Update & Activate';
  }

  const getReportToValue = (userData) => {
    const { reportsTo } = userData;

    let reportsToVal;
    if (typeof reportsTo === 'number') {
      reportsToVal = reportsTo;
    } else if (typeof reportsTo === 'string') {
      if (reportsTo === '') {
        reportsToVal = 0;
      } else if (reportsTo.includes('U-')) {
        reportsToVal = parseInt(reportsTo.substring(2));
      } else {
        reportsToVal = parseInt(reportsTo);
      }
    } else {
      reportsToVal = 0;
    }
    return { ...userData, reportsTo: reportsToVal };
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <List component="div" role="list">
          {isNew && (
            <ListItem>
              <ListItemText
                primary="Customize Data Access"
                secondaryTypographyProps={{ component: 'div' }}
                secondary="Customizing Data Access for this user can help in setting the customers and policies that this user has access to. It will be available by expanding the user row. By default, this user will have access to all customers and policies."
              />
            </ListItem>
          )}
          {!isNew && !!userInitials && (
            <ListItem>
              <ListItemText
                primary="ID"
                secondaryTypographyProps={{ component: 'div' }}
                secondary={userInitials}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary="First Name"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldFirstName}
                  currentValue={firstName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last Name"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldLastName}
                  currentValue={lastName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <div className={classes.warningDiv}>
                  {duplicateError && (
                    <div>
                      <WarningIcon
                        fontSize="small"
                        className={classes.warningIcon}
                      />
                    </div>
                  )}
                  <div>Email ID</div>
                </div>
              }
              secondary={
                <>
                  {duplicateError ? (
                    <>
                      <Typography variant="body2">
                        {duplicateUserDetails.emailID} has already been used for
                        user{' '}
                        {duplicateUserDetails.userID
                          ? duplicateUserDetails.userID
                          : ''}{' '}
                        - {duplicateUserDetails.firstName}{' '}
                        {duplicateUserDetails.lastName} with role{' '}
                        {getLabelForRole(duplicateUserDetails.userType)} .You
                        cannot have more than 1 active user with the same email.
                      </Typography>
                    </>
                  ) : (
                    <div>{emailID}</div>
                  )}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="User Type"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={getLabelForRole(oldUserType)}
                  currentValue={getLabelForRole(userType)}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Designation"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldDesignation}
                  currentValue={designation}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reports To"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={
                    isEdit
                      ? userMapping['U-' + oldReportsTo]
                      : userMapping[oldReportsTo]
                  }
                  currentValue={
                    isEdit
                      ? userMapping['U-' + reportsTo]
                      : userMapping[reportsTo]
                  }
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Location"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={locationMapping[oldLocation]}
                  currentValue={locationMapping[location]}
                />
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        {confirmBtnText && (
          <Button
            disabled={disableConfirm}
            onClick={() => confirm(false)}
            color="primary"
          >
            {confirmBtnText}
          </Button>
        )}
        {activateBtnText && showActivationBtn && (
          <Button
            disabled={disableConfirm}
            onClick={() => confirm(true)}
            color="primary"
          >
            {activateBtnText}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeConfirm: PropTypes.func.isRequired,
  onNewConfirm: PropTypes.func.isRequired,
  updateData: PropTypes.shape({
    isEdit: PropTypes.bool.isRequired,
    oldData: PropTypes.shape({
      userInitials: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      reportsTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      designation: PropTypes.string.isRequired,
    }),
    newData: PropTypes.shape({
      userInitials: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      reportsTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      designation: PropTypes.string.isRequired,
    }),
    isNew: PropTypes.bool.isRequired,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
    })
  ),
};
