import React, { useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

export function UserGuiding() {
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();

  useEffect(() => {
    window.userguidingRole = user.getTopRole();
    if (tenantApi) {
      window.userguidingPlanType = tenantApi.getPlanType();
    }
    window.userGuiding.identify(
      `donna-${user.getTenant()}-${user.getTenantLevelUserId()}`
    );
  }, [user, tenantApi]);
  return <></>;
}
