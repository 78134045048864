import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  isExpired: {
    background: 'rgba(235, 61, 61, 0.2)',
    border: '1px solid #EB3D3D',
    borderRadius: 5,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    paddingLeft: theme.spacing(1),
  },
  supportButton: {
    borderRadius: 4,
    padding: theme.spacing(1),
    textTransform: 'none',
    margin: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '17px',
  },
}));

export function JiraButtonDonnaSupport(props) {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      className={classes.supportButton}
      onClick={onClick}
    >
      DONNA Support
    </Button>
  );
}

JiraButtonDonnaSupport.propTypes = {
  onClick: PropTypes.func.isRequired,
};
