import React from 'react';
import { makeStyles } from '@material-ui/core';
import QuickViewSkeletonImport from '@aureus/cov-quick-view/dist/Skeleton/QuikViewSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(12),
  },
}));

export function QuickViewSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <QuickViewSkeletonImport />
    </div>
  );
}
