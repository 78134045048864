import React from 'react';
import { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LoginMessage } from './../../components/LoginMessage';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
  },
}));

export function LoginPrompt() {
  useTheme();
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/api/auth/login';
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const classes = useStyles();
  let match = useRouteMatch();
  return (
    <Container className={classes.root}>
      <Switch>
        <Route path={`${match.path}/prompt`}>
          <LoginMessage type="info" />
        </Route>
        <Route path={`${match.path}/retry`}>
          <LoginMessage type="error" />
        </Route>
        <Route path={`${match.path}/logoutConfirmation`}>
          <LoginMessage type="logout" />
        </Route>
      </Switch>
    </Container>
  );
}
