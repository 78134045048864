import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(12),
  }
}));

export function OneViewSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={2} sm={2} md={1}>
          <Skeleton variant="circle" width={70} height={70} />
        </Grid>
        <Grid item xs={10} sm={10} md={11}>
          <Skeleton variant="rect" height={70} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" height={450} />
        </Grid>
      </Grid>
    </div>
  );
}
