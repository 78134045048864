import {
  isAnalyticsEnabledForRole,
  isUserManagementAllowedForRole,
  isSettingChangeAllowedForRole,
  getLabelForRole,
  isVizAccessAllowedForRole,
  isLinkingAllowedForRole,
  isPortfolioAllowedForRole,
  isPersonalDashboardAllowedForRole,
  isScopedOverallDashboardAllowedForRole,
  isOverallDashboardAllowedForRole,
  isScopedAnalyticsEnabledForRole,
  isPersonalAnalyticsEnabledForRole,
  isOverallDiemEnabledForRole,
  isScopedDiemEnabledForRole,
  isPersonalDiemEnabledForRole,
  isCustomerAnalyticsEnabledForRole,
  isTeamAnalyticsEnabledForRole,
  isCarrierAnalyticsEnabledForRole,
  isMetricsAnalyticsEnabledForRole,
  isTrendsAnalyticsEnabledForRole,
  isInsightsAnalyticsEnabledForRole,
  isBillingAllowedForRole,
  isDataFilterAllowedForRole,
  isForecastEnabledForRole,
  isEnrichmentDashboardEnabledForRole,
  isBenchmarkEnabledForRole,
} from './roles';
import { Tenant } from './Tenant';

export class User {
  constructor(profile) {
    const { fetching, success, error, data } = profile;
    this.fetching = fetching;
    this.success = success;
    this.error = error;
    if (success) {
      const { user, tenant: tenantDetails, subscription } = data;
      this.tenantDetails = tenantDetails;
      this.tenantApi = new Tenant({tenantDetails, subscription});
      const { name, _json: json } = user;
      this.name = name;
      this.profileJson = json;
    }
    this.isFetching = this.isFetching.bind(this);
    this.isError = this.isError.bind(this);
    this.isSuccess = this.isSuccess.bind(this);
    this.isSetupUser = this.isSetupUser.bind(this);
    this.getTenant = this.getTenant.bind(this);
    this.getTopRole = this.getTopRole.bind(this);
    this.getFullName = this.getFullName.bind(this);
    this.isOverallDashboardEnabled = this.isOverallDashboardEnabled.bind(this);
    this.isScopedOverallDashboardEnabled =
      this.isScopedOverallDashboardEnabled.bind(this);
    this.isPersonalDashboardEnabled =
      this.isPersonalDashboardEnabled.bind(this);
    this.isPortfolioEnabled = this.isPortfolioEnabled.bind(this);
    this.isAnalyticsEnabled = this.isAnalyticsEnabled.bind(this);
    this.isCustomerAnalyticsEnabled =
      this.isCustomerAnalyticsEnabled.bind(this);
    this.isTeamAnalyticsEnabled = this.isTeamAnalyticsEnabled.bind(this);
    this.isCarrierAnalyticsEnabled = this.isCarrierAnalyticsEnabled.bind(this);
    this.isMetricsAnalyticsEnabled = this.isMetricsAnalyticsEnabled.bind(this);
    this.isTrendsAnalyticsEnabled = this.isTrendsAnalyticsEnabled.bind(this);
    this.isInsightsAnalyticsEnabled =
      this.isInsightsAnalyticsEnabled.bind(this);
    this.isScopedAnalyticsEnabled = this.isScopedAnalyticsEnabled.bind(this);
    this.isPersonalAnalyticsEnabled =
      this.isPersonalAnalyticsEnabled.bind(this);
    this.isOverallDiemEnabled = this.isOverallDiemEnabled.bind(this);
    this.isScopedDiemEnabled = this.isScopedDiemEnabled.bind(this);
    this.isPersonalDiemEnabled = this.isPersonalDiemEnabled.bind(this);
    this.getInitials = this.getInitials.bind(this);
    this.isUserManagementAllowed = this.isUserManagementAllowed.bind(this);
    this.isSettingChangeAllowed = this.isSettingChangeAllowed.bind(this);
    this.getTenantLevelUserId = this.getTenantLevelUserId.bind(this);
    this.getTopRoleLabel = this.getTopRoleLabel.bind(this);
    this.isVizAccessAllowed = this.isVizAccessAllowed.bind(this);
    this.isLinkable = this.isLinkable.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.isBillingAllowed = this.isBillingAllowed.bind(this);
    this.isForecastAllowed = this.isForecastAllowed.bind(this);
    this.isEnrichmentDashboardAllowed =
      this.isEnrichmentDashboardAllowed.bind(this);
    this.isBenchmarkAllowed = this.isBenchmarkAllowed.bind(this);

    this.getCustomerAnalyticsPath = this.getCustomerAnalyticsPath.bind(this);
    this.getTeamAnalyticsPath = this.getTeamAnalyticsPath.bind(this);
    this.getTrendsAnalyticsPath = this.getTrendsAnalyticsPath.bind(this);
    this.getPortfolioPath = this.getPortfolioPath.bind(this);
    this.isAgencyVisionSetup = this.isAgencyVisionSetup.bind(this);
  }

  ANALYTICS_PATH = '/home/analytics';
  SCOPED_ANALYTICS_PATH = '/home/team-analytics';
  PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
  ANALYTICS_CUSTOMER = '/customer/all';
  ANALYTICS_TEAM = '/team';
  ANALYTICS_TRENDS = '/trends';
  ANALYTICS_CUSTOMER_PATH = `${this.ANALYTICS_PATH}${this.ANALYTICS_CUSTOMER}`;
  ANALYTICS_TEAM_PATH = `${this.ANALYTICS_PATH}${this.ANALYTICS_TEAM}`;
  ANALYTICS_TRENDS_PATH = `${this.ANALYTICS_PATH}${this.ANALYTICS_TRENDS}`;
  SCOPED_ANALYTICS_CUSTOMER_PATH = `${this.SCOPED_ANALYTICS_PATH}${this.ANALYTICS_CUSTOMER}`;
  SCOPED_ANALYTICS_TEAM_PATH = `${this.SCOPED_ANALYTICS_PATH}${this.ANALYTICS_TEAM}`;
  SCOPED_ANALYTICS_TRENDS_PATH = `${this.SCOPED_ANALYTICS_PATH}${this.ANALYTICS_TRENDS}`;
  PERSONAL_ANALYTICS_CUSTOMER_PATH = `${this.PERSONAL_ANALYTICS_PATH}${this.ANALYTICS_CUSTOMER}`;
  PERSONAL_ANALYTICS_TEAM_PATH = `${this.PERSONAL_ANALYTICS_PATH}${this.ANALYTICS_TEAM}`;
  PERSONAL_ANALYTICS_TRENDS_PATH = `${this.PERSONAL_ANALYTICS_PATH}${this.ANALYTICS_TRENDS}`;

  isError() {
    return this.error;
  }

  isFetching() {
    return this.fetching;
  }

  isSuccess() {
    return this.success;
  }

  getTenant() {
    const { tenant } = this.profileJson;
    return tenant;
  }

  isSetupUser() {
    const { is_setup_user: setupUser } = this.profileJson;
    return setupUser;
  }

  isAgencyVisionSetup() {
    const { agencyvision_setup } = this.profileJson;
    if (typeof agencyvision_setup === 'boolean') {
      return agencyvision_setup;
    } else if (typeof agencyvision_setup === 'string' && agencyvision_setup === 'true') {
      return true;
    }
    return false;
  }

  getTenantApi() {
    return this.tenantApi;
  }

  getTopRole() {
    const { top_role: topRole } = this.profileJson;
    return topRole;
  }

  getFullName() {
    const { givenName, familyName } = this.name;
    const fullName = `${givenName} ${familyName}`;
    return fullName;
  }

  getFirstName() {
    const { givenName } = this.name;
    return givenName;
  }

  getLastName() {
    const { familyName } = this.name;
    return familyName;
  }

  getEmail() {
    const { email } = this.profileJson;
    return email;
  }

  getInitials() {
    const { givenName, familyName } = this.name;
    let initials = '';
    if (typeof givenName === 'string' && givenName.length > 0) {
      initials = givenName[0];
    }
    if (typeof familyName === 'string' && familyName.length > 0) {
      initials = initials + familyName[0];
    }
    return initials;
  }

  isOverallDashboardEnabled() {
    return isOverallDashboardAllowedForRole(this.getTopRole());
  }

  isScopedOverallDashboardEnabled() {
    return isScopedOverallDashboardAllowedForRole(this.getTopRole());
  }

  isPersonalDashboardEnabled() {
    return isPersonalDashboardAllowedForRole(this.getTopRole());
  }

  isPortfolioEnabled() {
    return isPortfolioAllowedForRole(this.getTopRole());
  }

  isAnalyticsEnabled() {
    return isAnalyticsEnabledForRole(this.getTopRole());
  }

  isCustomerAnalyticsEnabled() {
    return isCustomerAnalyticsEnabledForRole(this.getTopRole());
  }

  isTeamAnalyticsEnabled() {
    return isTeamAnalyticsEnabledForRole(this.getTopRole());
  }

  isCarrierAnalyticsEnabled() {
    return isCarrierAnalyticsEnabledForRole(this.getTopRole());
  }

  isMetricsAnalyticsEnabled() {
    return isMetricsAnalyticsEnabledForRole(this.getTopRole());
  }

  isTrendsAnalyticsEnabled() {
    return isTrendsAnalyticsEnabledForRole(this.getTopRole());
  }

  isInsightsAnalyticsEnabled() {
    return isInsightsAnalyticsEnabledForRole(this.getTopRole());
  }

  isScopedAnalyticsEnabled() {
    return isScopedAnalyticsEnabledForRole(this.getTopRole());
  }

  isPersonalAnalyticsEnabled() {
    return isPersonalAnalyticsEnabledForRole(this.getTopRole());
  }

  isOverallDiemEnabled() {
    return isOverallDiemEnabledForRole(this.getTopRole());
  }

  isScopedDiemEnabled() {
    return isScopedDiemEnabledForRole(this.getTopRole());
  }

  isPersonalDiemEnabled() {
    return isPersonalDiemEnabledForRole(this.getTopRole());
  }

  isUserManagementAllowed() {
    return isUserManagementAllowedForRole(this.getTopRole());
  }

  isSettingChangeAllowed() {
    return isSettingChangeAllowedForRole(this.getTopRole());
  }

  getTenantLevelUserId() {
    const { tenant_level_user_id: stringTenantLevelUserId } = this.profileJson;
    let tenantLevelUserId;
    const integerTenantLevelUserId = parseInt(stringTenantLevelUserId);
    if (!isNaN(integerTenantLevelUserId)) {
      tenantLevelUserId = integerTenantLevelUserId;
    }
    return tenantLevelUserId;
  }

  getTopRoleLabel() {
    return getLabelForRole(this.getTopRole());
  }

  isVizAccessAllowed() {
    return isVizAccessAllowedForRole(this.getTopRole());
  }

  isLinkable() {
    return isLinkingAllowedForRole(this.getTopRole());
  }

  isBillingAllowed() {
    return isBillingAllowedForRole(this.getTopRole());
  }

  isDataFilterAllowed() {
    return isDataFilterAllowedForRole(this.getTopRole());
  }

  isForecastAllowed() {
    return isForecastEnabledForRole(this.getTopRole());
  }

  isEnrichmentDashboardAllowed() {
    return isEnrichmentDashboardEnabledForRole(this.getTopRole());
  }

  isBenchmarkAllowed() {
    return isBenchmarkEnabledForRole(this.getTopRole());
  }

  getPortfolioPath() {
    const hasPortfolio = this.isPortfolioEnabled();
    const PORTFOLIO_PATH = '/home/portfolio';
    return hasPortfolio ? PORTFOLIO_PATH : '';
  }

  getCustomerAnalyticsPath() {
    let customerAnalyticsLink;
    const hasAnalytics = this.isAnalyticsEnabled();
    const hasScopedAnalytics = this.isScopedAnalyticsEnabled();
    const hasPersonalAnalytics = this.isPersonalAnalyticsEnabled();
    const hasCustomerAnalytics = this.isCustomerAnalyticsEnabled();

    if (hasPersonalAnalytics) {
      customerAnalyticsLink = hasCustomerAnalytics
        ? this.PERSONAL_ANALYTICS_CUSTOMER_PATH
        : '';
    } else if (hasScopedAnalytics) {
      customerAnalyticsLink = hasCustomerAnalytics
        ? this.SCOPED_ANALYTICS_CUSTOMER_PATH
        : '';
    } else if (hasAnalytics) {
      customerAnalyticsLink = hasCustomerAnalytics
        ? this.ANALYTICS_CUSTOMER_PATH
        : '';
    }
    return customerAnalyticsLink;
  }

  getTeamAnalyticsPath() {
    let teamAnalyticsLink;
    const hasAnalytics = this.isAnalyticsEnabled();
    const hasScopedAnalytics = this.isScopedAnalyticsEnabl;
    const hasPersonalAnalytics = this.isPersonalAnalyticsEnabled();
    const hasTeamAnalytics = this.isTeamAnalyticsEnabled();

    if (hasPersonalAnalytics) {
      teamAnalyticsLink = hasTeamAnalytics
        ? this.PERSONAL_ANALYTICS_TEAM_PATH
        : '';
    } else if (hasScopedAnalytics) {
      teamAnalyticsLink = hasTeamAnalytics
        ? this.SCOPED_ANALYTICS_TEAM_PATH
        : '';
    } else if (hasAnalytics) {
      teamAnalyticsLink = hasTeamAnalytics ? this.ANALYTICS_TEAM_PATH : '';
    }
    return teamAnalyticsLink;
  }

  getTrendsAnalyticsPath() {
    let trendsAnalyticsLink;
    const hasAnalytics = this.isAnalyticsEnabled();
    const hasScopedAnalytics = this.isScopedAnalyticsEnabled();
    const hasPersonalAnalytics = this.isPersonalAnalyticsEnabled();
    const hasTrendsAnalytics = this.isTrendsAnalyticsEnabled();

    if (hasPersonalAnalytics) {
      trendsAnalyticsLink = hasTrendsAnalytics
        ? this.PERSONAL_ANALYTICS_TRENDS_PATH
        : '';
    } else if (hasScopedAnalytics) {
      trendsAnalyticsLink = hasTrendsAnalytics
        ? this.SCOPED_ANALYTICS_TRENDS_PATH
        : '';
    } else if (hasAnalytics) {
      trendsAnalyticsLink = hasTrendsAnalytics
        ? this.ANALYTICS_TRENDS_PATH
        : '';
    }
    return trendsAnalyticsLink;
  }
}
