import { useState, useEffect } from 'react';

export default function useActivities({ customerId }) {
  const [activities, setActivities] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getActivities = async () => {
      setActivities({ fetching: true, error: false, success: false });
      try {
        const path = `/api/ai/v1/getActivities/${customerId}`;
        const response = await fetch(path);
        if (response.redirected) {
          window.location.href = response.url;
        }
        const responseObject = await response.json();
        const { error, errorMessage, data: activities } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setActivities({
          fetching: false,
          error: false,
          success: true,
          data: activities,
        });
      } catch (error) {
        setActivities({ fetching: false, error: true, success: false });
      }
    };
    getActivities();
  }, [customerId]);
  return activities;
}
