import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/quotes/v1/setQuoteEnabled';

export function useSetQuoteEnabled(saveEnableState, payload, setGetEnaleState) {
  const [quoteEnabled, setQuoteEnabled] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const setQuoteEnable = async () => {
      setQuoteEnabled({ fetching: true, error: false, success: false });
      try {
        const response = await axios.post(path, payload);
        if (response.status !== 200) {
          setQuoteEnabled({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setQuoteEnabled({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
          setGetEnaleState(true);
        }
      } catch (e) {
        setQuoteEnabled({ fetching: false, error: true, success: false });
      }
    };
    if (saveEnableState) {
      setQuoteEnable();
    }
  }, [saveEnableState, setGetEnaleState, payload]);
  return quoteEnabled;
}
