import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { HomeIcon } from '../Header/Icons';
import { TitleHome } from '../Header';
import { MyListContext } from '../CardView/MyListContext';
import { CustomerSearch } from './CustomerSearch';
import base64url from 'base64url';
import { LastRefreshDate } from '../Header/LastRefreshDate';
import { DefaultView } from './DefaultView';
import {
  UserConfigContext,
  PROPERTIES,
  VALUES,
  isValueValid,
  MAPPING,
} from '../UserConfig';
import { CustomerCardLayout } from './CustomerCardLayout';
import { CircularLoader } from './CircularLoader';

export function Diem(props) {
  const { fetching, error, getProperty } = useContext(UserConfigContext);
  const homeScreen = getProperty(PROPERTIES.HOME_SCREEN);
  const { type } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const right = <LastRefreshDate />;
  const goToViewAll = (context) => {
    history.push(`/home/view/${type}/viewAll/${context}?backPath=${match.url}`);
  };
  const onSearch = (searchTerm) => {
    const searchTermPathParam = base64url(encodeURIComponent(searchTerm));
    history.push(`${match.url}/search/input/${searchTermPathParam}`);
  };

  const defaultView = (
    <>
      <TitleHome
        icon={HomeIcon}
        titleText="Home"
        onSearch={onSearch}
        right={right}
      />
      <DefaultView
        type={`/diem/${type}`}
        path={match.url}
        goToViewAll={goToViewAll}
        contextWithoutFilter={true}
      />
    </>
  );

  const getSelectedView = (homeScreen, error) => {
    if (
      error ||
      homeScreen === undefined ||
      homeScreen === VALUES.HOME_SCREEN.DEFAULT ||
      !isValueValid(PROPERTIES.HOME_SCREEN, homeScreen)
    ) {
      return defaultView;
    } else {
      const context = MAPPING[PROPERTIES.HOME_SCREEN].CONTEXT[homeScreen];
      const view = (
        <CustomerCardLayout
          hasBackButton={false}
          icon={HomeIcon}
          backPath={match.url}
          quick={true}
          type={`/diem/${type}`}
          updateButton={false}
          context={context}
          contextWithoutFilter={false}
          path={match.url}
        />
      );
      return view;
    }
  };

  return (
    <>
      <Switch>
        <Route path={`${match.url}/search/input/:searchInput`}>
          <CustomerSearch type={`/diem/${type}/search`} basePath={match.url} />
        </Route>
        <Route exact path={`${match.url}/search/input/`}>
          <Redirect
            to={{
              pathname: `${match.url}/search`,
            }}
          />
        </Route>
        <Route path={`${match.url}/search`}>
          <CustomerSearch type={`/diem/${type}/search`} basePath={match.url} />
        </Route>
        <Route path="/">
          {fetching && <CircularLoader />}
          {!fetching && getSelectedView(homeScreen, error)}
        </Route>
      </Switch>
    </>
  );
}

Diem.contextType = MyListContext;

Diem.propTypes = {
  type: PropTypes.string.isRequired,
};
