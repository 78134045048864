const getCardDatafromTableData = (tableData) => {
  const cardDatafromTableData = [];

  tableData.forEach((row) => {
    const customerId =
      row['Customer Id'] || row['Customer ID'] || row['CustomerId'];
    const DvCustomerAnnualPremium = row.DvCustomerAnnualPremium
      ? row.DvCustomerAnnualPremium
      : '';
    const cardDataStructure = {
      Main: customerId,
      Household: {
        [customerId]: {
          customerDetails: {
            ...row,
            'Customer ID.': customerId,
            Dvnewupliftpremiumvalue: row.DvNewUpliftPremiumValue,
            DvNextRenewalDate: row.DvNextRenewalDate,
            DvCustomerName: getValidCustomerName(row),
            'Kpi Sentimeter Value': row.KPISentimeterValue,
            Sentimeter: row.KPISentimeterValue,
            DvCustomerCategory: row.Regular || row.DvCustomerCategory,
            'Customer Name': getValidCustomerName(row),
            Cslastchangedirection: row.CsLastChangeDirection,
            Cslastchangemagnitude: row.CsLastchangeMagnitude,
            'AGG(Count of Records)': row.CountOfRecords,
            'AGG(sortingDimension1Ranked-bucket)':
              row['sortingDimension1Ranked-bucket'],
            'ATTR(logPREMSM)': row.logPREMSM,
            'Customer ID': customerId,
            DvCustomerAnnualPremium: DvCustomerAnnualPremium.replace(/,/g, ''),
            DvCustomerType: row.DvCustomerType,
          },
        },
      },
    };
    cardDatafromTableData.push(cardDataStructure);
  });

  return cardDatafromTableData;
};

const getValidCustomerName = (customerDetails) => {
  try {
    if (customerDetails && Object.keys(customerDetails).length > 0) {
      const { DvCustomerName: name, Dvcustomername: fallbackName } =
        customerDetails;
      const customerName = customerDetails
        ? customerDetails?.['Customer Name']
        : '-';
      let dvcustomerName = '-';
      if (name) {
        dvcustomerName = name;
      } else if (fallbackName) {
        dvcustomerName = fallbackName;
      } else if (customerName) {
        dvcustomerName = customerName;
      }
      return dvcustomerName;
    }
    return '-';
  } catch (e) {
    return '-';
  }
};

export default getCardDatafromTableData;
