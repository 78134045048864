import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const basePath = '/api/cov/v1/data';
const Cache = require('in-memoriam');
const capacity = 5;
const ttl = 120000;
const cache = new Cache(capacity, ttl);
function useCustomerData({ customerId }) {
  const [customerData, setCustomerData] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getCov = async () => {
      const isExists = cache.get(customerId);
      if (isExists !== null) {
        const customerArr = cache.get(customerId);
        setCustomerData({
          fetching: false,
          error: false,
          success: true,
          data: customerArr,
        });
      }
      if (isExists === null) {
        const URI = `${basePath}/${customerId}`;
        setCustomerData({ fetching: true, error: false, success: false });
        try {
          const response = await fetch(URI, {
            cache: 'no-store',
          });

          if (response.redirected) {
            window.location.href = response.url;
          }
          const responseObject = await response.json();
          const { error, errorMessage, data } = responseObject;
          if (error) {
            throw new Error(errorMessage);
          }

          const compatibleData = makeCompatibleCustomerData(data);
          cache.set(customerId, compatibleData);
          setCustomerData({
            fetching: false,
            error: false,
            success: true,
            data: compatibleData,
          });
        } catch (error) {
          setCustomerData({ fetching: false, error: true, success: false });
        }
      }
    };
    getCov();
  }, [customerId]);
  return customerData;
}

const makeCompatibleCustomerData = (data) => {
  if (data !== null && data !== undefined) {
    const { Household, main_customer } = data;
    const householdKey = Object.keys(Household)[0];
    const householdValues = Object.values(Household)[0];

    const { customerDetails } = householdValues ? householdValues : {};

    const updatedCustomerDetails = getUpdatedCustomerDetails(customerDetails);
    const householdData = {
      [householdKey]: {
        ...householdValues,
        customerDetails: updatedCustomerDetails,
      },
    };

    const { customerDetails: main_customer_details } = main_customer
      ? main_customer
      : {};

    const updatedMainCustomer = getUpdatedCustomerDetails(
      main_customer_details
    );

    const mainCustomerData = {
      ...main_customer,
      customerDetails: updatedMainCustomer,
    };

    return {
      ...data,
      Household: householdData,
      main_customer: mainCustomerData,
    };
  }
  return data;
};

const getUpdatedCustomerDetails = (customerDetails) => {
  if (customerDetails && Object.keys(customerDetails).length > 0) {
    const { DvCustomerName: name, Dvcustomername: fallbackName } =
      customerDetails;
    let dvcustomerName = '-';
    if (name) {
      dvcustomerName = name;
    } else if (fallbackName) {
      dvcustomerName = fallbackName;
    }

    return {
      ...customerDetails,
      DvCustomerName: dvcustomerName,
      'Customer Name': dvcustomerName,
    };
  }
  return customerDetails;
};

useCustomerData.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export { useCustomerData };
