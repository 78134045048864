import axios from 'axios';
import { gaEvent } from '../GoogleAnalytics/gaEvent';

const path = '/api/insuredMine/v1/createLead';
const headers = {
  'Content-Type': 'application/json',
};

const gaTracking = (userId, value) => {
  const category = 'leadGenerated';
  const action = 'InsuredMine';
  const label = userId;
  gaEvent(category, action, label, value);
};

const createInsuredLead = async (requestObject) => {
  try {
    const { status, data } = await axios.post(path, requestObject, {
      headers,
    });
    if (status !== 200) {
      if (status === 401) {
        return {
          error: true,
          errorMessage: 'Authentication Failure',
          status,
        };
      }
      throw new Error('Error while creating lead');
    }
    const { error, errorMessage } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    const { data: responseData } = data;
    const { userID, message } = responseData;
    gaTracking(userID, message);
    return data;
  } catch (error) {
    return {
      error: true,
      errorMessage: error.message,
    };
  }
};

export default createInsuredLead;
