import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const uppy = new Uppy({
  meta: { type: 'avatar ' },
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/*'],
    minNumberOfFiles: 1,
    maxFileSize: 2000000,
  },
  autoProceed: false,
  allowMultipleUploads: false,
  locale: {
    strings: {
      retryUpload: 'Retry',
      dropPasteImportFiles: 'Drop profile picture here, or %{browseFiles}:',
      uploadXFiles: {
        0: 'Set As Profile Picture',
      },
      xFilesSelected: {
        0: 'Donna Profile Picture Selected',
      },
    },
  },
});

uppy.use(XHRUpload, {
  endpoint: '/api/image/v1/setAvatar',
  formData: true,
  fieldName: 'media',
});

export function ProfileImageUpload(props) {
  const { onSuccess } = props;
  useEffect(() => {
    uppy.reset();
    uppy.off('upload-success');
    uppy.on('upload-success', () => {
      onSuccess();
    });
  }, [onSuccess]);
  return (
    <div>
      <Dashboard
        uppy={uppy}
        height={250}
        proudlyDisplayPoweredByUppy={false}
        note="Square portrait pictures work best with Donna"
      />
    </div>
  );
}

ProfileImageUpload.props = {
  avatarURL: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
};
