import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/planMapping/v1/getPlansMapping';

function useGetPlansMapping() {
  const [plansMapping, setPlansMapping] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getPlansMapping = async () => {
      setPlansMapping({
        fetching: true,
        error: false,
        success: false,
        data: undefined,
      });
      try {
        const { error, data } = await axios.get(path);
        if (error || data.error) {
          setPlansMapping({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setPlansMapping({
            fetching: false,
            success: true,
            error: false,
            data: data.data,
          });
        }
      } catch (e) {
        setPlansMapping({
          fetching: false,
          error: true,
          success: false,
          data: undefined,
        });
      }
    };
    getPlansMapping();
  }, []);

  return plansMapping;
}

export default useGetPlansMapping;
