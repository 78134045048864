import React from 'react';
import logo from './../../assets/donna.png';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTenantDetails } from './useTenantDetails';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  logo: {
    height: 50,
  },
  logoAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tenantText: {
    marginLeft: theme.spacing(2),
  },
}));

export function AppLogoAgencySetup() {
  const classes = useStyles();
  const { fetching, error, success, data: tenantDetails } = useTenantDetails();
  let agencyLogo;
  if (success) {
    ({ agencyLogo } = tenantDetails);
  }
  const showAgencyLogo = success && agencyLogo;
  const showDefaultLogo = (success && !agencyLogo) || error;

  const homePath = '/home';

  return (
    <div className={classes.root}>
      {showAgencyLogo && (
        <Link to={homePath}>
          <img className={classes.logo} src={agencyLogo} alt={'donna'} />
        </Link>
      )}
      {fetching && <Skeleton variant="rect" height={41} width={160} />}
      {showDefaultLogo && (
        <div className={classes.logoAlt}>
          <Link to={homePath}>
            <img className={classes.logo} src={logo} alt="Donna" />
          </Link>
        </div>
      )}
    </div>
  );
}
