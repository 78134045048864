import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { NestedList } from './NestedList';

const ANCHOR = 'left';

export function DrawerMenu(props) {
  const { open, onClose } = props;
  return (
    <Drawer anchor={ANCHOR} open={open} onClose={onClose}>
      <NestedList onClose={onClose} />
    </Drawer>
  );
}

DrawerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
