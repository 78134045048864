import { getSingleMarkValue } from './marks';

const customerIdKeys = [
  'Customer Id',
  'Customer ID',
  'customer id',
  'customer ID',
  'customerId',
  'customerID',
  'CustomerId',
  'CustomerID',
  'CUSTOMER_ID',
];

export const getCustomerIdFromMarks = (marks) => {
  return getSingleMarkValue(marks, customerIdKeys);
};
