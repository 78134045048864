import React, { useState, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getUnderlyingSummaryData,
  TableauMarkSelectionView,
} from '../TableauView';
import {
  isCustomerValid,
  getCovFromCustomerData,
} from './getCovFromCustomerData';
import { useViewDetails } from './../useViewDetails';
import CONTEXT from '@aureus/cov-context';
import addFilter from './addFilter';
import useAllCustomers from './useAllCustomers';

const dimensions = {
  placeholderWidth: '0px',
  placeholderHeight: '0px',
  vizWidth: '0px',
  vizHeight: '0px',
};
const CONTEXT_KEY = 'context';
const SEARCH_KEY = 'searchQuery';

const contextTableauMapping = {
  [CONTEXT.RENEWAL]: 'renewal',
  [CONTEXT.RETAIN]: 'retention',
  [CONTEXT.ROUNDOUT]: 'roundout',
};

const dedupeCustomers = (customers) => {
  if (Array.isArray(customers)) {
    const seen = {};
    return customers.filter((customer) => {
      const { Main: customerId } = customer;
      if (seen[customerId] === true) {
        return false;
      } else {
        seen[customerId] = true;
        return true;
      }
    });
  } else {
    return customers;
  }
};

const isCustomerRemoved = (customer, toRemove) => {
  const { Main: custId } = customer;
  return toRemove[custId];
};

const ContextCustomerProvider = (props) => {
  const { context, type, children, search, maxCustomers } = props;
  const [customers, setCustomers] = useState();
  const apiPath = `/api/metrics/v1/${type}`;
  const parameters = `${context}/${search}`;
  const view = useViewDetails(apiPath, parameters);
  const { fetching, success, error, data } = view;
  useEffect(() => {
    if (fetching === true) {
      setCustomers(undefined);
    }
  }, [fetching]);

  const onFirstInteraction = async (viz) => {
    const summaryData = await getUnderlyingSummaryData(viz);
    const validSummaryData = summaryData.filter((customerSummary) =>
      isCustomerValid(customerSummary)
    );
    const covData = validSummaryData.map((customerSummary) =>
      getCovFromCustomerData(customerSummary)
    );
    covData.map((customer, index) => {
      customer.originalIndex = index;
      return customer;
    });
    return setCustomers(covData);
  };
  let dataWithAdditionalFilter = { ...data };
  if (success) {
    const contextValue = contextTableauMapping[context];
    if (contextValue) {
      dataWithAdditionalFilter = addFilter(
        dataWithAdditionalFilter,
        CONTEXT_KEY,
        contextValue
      );
    }
    if (search) {
      dataWithAdditionalFilter = addFilter(
        dataWithAdditionalFilter,
        SEARCH_KEY,
        search
      );
    }
  }

  const [load, setLoad] = useState(true);

  const reload = () => {
    setLoad(true);
  };

  const stopLoad = () => {
    setLoad(false);
  };

  const {
    fetching: fetchingCustomers,
    success: successCustomers,
    error: errorCustomers,
    data: allCustomersInSegment,
  } = useAllCustomers(context, load, stopLoad);

  const overallFetching =
    fetching ||
    fetchingCustomers ||
    (success && customers === undefined) ||
    (success && allCustomersInSegment === undefined);
  const overallSuccess =
    success &&
    successCustomers &&
    customers !== undefined &&
    allCustomersInSegment !== undefined;
  const overallError = error || errorCustomers;

  let filteredCustomers;
  if (Array.isArray(customers) && Array.isArray(allCustomersInSegment)) {
    const toRemove = {};
    allCustomersInSegment.forEach((customerInSegment) => {
      const { customerId } = customerInSegment;
      toRemove[customerId] = true;
    });
    filteredCustomers = dedupeCustomers(customers).filter((customer) => {
      return !isCustomerRemoved(customer, toRemove);
    });
    if (maxCustomers) {
      filteredCustomers = filteredCustomers.slice(0, maxCustomers);
    }
  }

  return (
    <>
      {success && (
        <TableauMarkSelectionView
          {...dataWithAdditionalFilter}
          {...dimensions}
          adjustHeight={false}
          onFirstInteraction={onFirstInteraction}
        />
      )}
      {cloneElement(children, {
        fetching: overallFetching,
        success: overallSuccess,
        error: overallError,
        customers: filteredCustomers,
        context,
        reload,
      })}
    </>
  );
};

ContextCustomerProvider.propTypes = {
  type: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  search: PropTypes.string,
  maxCustomers: PropTypes.number,
};

export { ContextCustomerProvider };
