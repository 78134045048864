import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export function MyProfileSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton variant="rect" height={500} />
    </div>
  );
}
