import axios from 'axios';

const actions = ['setHomeScreen'];

const basePath = '/api/userConfig/v1';

const setUserConfig = async ({ action, value }) => {
  if (actions.indexOf(action) < 0) {
    throw new Error('Invalid action');
  }
  const path = `${basePath}/${action}/${value}`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const { status, data } = await axios.post(path, {}, { headers });
  return { status, data };
};

export default setUserConfig;
