import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContextProvider } from './../UserContextProvider';
import { UserConfigContextProvider } from './../UserConfig';
import { Home } from './../Home';
import { Header } from './../Header';
import { Settings } from './../Settings';
import { LoginPrompt } from './../LoginPrompt';
import { GoogleAnalytics } from './../GoogleAnalytics';
import { Hotjar } from './../Hotjar';
import { UserGuiding } from './../UserGuiding';
import { GuideStepper } from './../Guide';
import { HeaderAgencySetup } from '../Header/HeaderAgencySetup';
import { AgencySetup, Setup } from '../Setup';
import { SetupUserContextProvider } from '../UserContextProvider/SetupUserContextProvider';

export function Content() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login/prompt" />
      </Route>
      <Route path="/login">
        <LoginPrompt />
      </Route>
      <Route path="/agency-setup">
        <SetupUserContextProvider>
          <AgencySetup>
            <HeaderAgencySetup />
            <Setup />
          </AgencySetup>
        </SetupUserContextProvider>
      </Route>
      <Route path="/home">
        <UserContextProvider>
          <UserConfigContextProvider>
            <GoogleAnalytics>
              <Hotjar>
                <UserGuiding />
                <Switch>
                  <Route path="/home/settings">
                    <Header
                      centerContent={<GuideStepper activeStep={0} />}
                      title="Getting Started"
                    />
                    <Settings />
                  </Route>
                  <Route path="/home">
                    <Header />
                    <Home />
                  </Route>
                </Switch>
              </Hotjar>
            </GoogleAnalytics>
          </UserConfigContextProvider>
        </UserContextProvider>
      </Route>
    </Switch>
  );
}
