import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/agencyZoom/v1/setAgencyZoomEnabled';

export function useSetAgencyZoomEnabled(
  saveAgencyZoomEnableState,
  agencyZoomPayload,
  setGetAgencyZoomEnableState
) {
  const [agencyZoomEnabled, setAgencyZoomEnabled] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const setAgencyZoomEnable = async () => {
      setAgencyZoomEnabled({ fetching: true, error: false, success: false });
      try {
        const response = await axios.post(path, agencyZoomPayload);
        if (response.status !== 200) {
          setAgencyZoomEnabled({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setAgencyZoomEnabled({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
          setGetAgencyZoomEnableState(true);
        }
      } catch (e) {
        setAgencyZoomEnabled({ fetching: false, error: true, success: false });
      }
    };
    if (saveAgencyZoomEnableState) {
      setAgencyZoomEnable();
    }
  }, [
    saveAgencyZoomEnableState,
    agencyZoomPayload,
    setGetAgencyZoomEnableState,
  ]);
  return agencyZoomEnabled;
}
