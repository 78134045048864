import axios from 'axios';

const path = '/api/agencyZoom/v1/login';

const agencyZoomLogin = async (requestBody) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    const { status, data } = await axios.post(path, requestBody, {
      headers,
    });
    if (status !== 200) {
      throw new Error('Error in authentication');
    }
    const { error, errorMessage } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    return data;
  } catch (error) {
    return {
      error: true,
      errorMessage: error.message,
    };
  }
};

export default agencyZoomLogin;
