import { isStringValid } from '../../utils/stringValidator';

export const getReportToUsersList = (users) => {
  const nameList = [];
  const emailList = [];
  const initialsList = [];
  const nameEmailList = [];
  const nameInitialsList = [];

  users.forEach((user) => {
    const { userID } = user;
    const name = getName(user);
    const emailID = getValue(user, 'emailID');
    const userInitials = getValue(user, 'userInitials');

    if (name !== '') {
      if (emailID !== '') {
        const val = `${name} (${emailID})=${userID}`;
        nameEmailList.push(val);
      } else if (userInitials !== '') {
        const val = `${name} (${userInitials})=${userID}`;
        nameInitialsList.push(val);
      } else {
        nameList.push(`${name}=${userID}`);
      }
    } else if (emailID !== '') {
      emailList.push(`(${emailID})=${userID}`);
    } else if (userInitials !== '') {
      initialsList.push(`(${userInitials})=${userID}`);
    }
  });

  const nameEmailObj = getObject(nameEmailList, '=');
  const nameInitialsObj = getObject(nameInitialsList, '=');
  const namesObj = getObject(nameList, '=');
  const emailObj = getObject(emailList, '=');
  const initialsObj = getObject(initialsList, '=');
  const resultantObj = Object.assign(
    { 'U-0': 'Unassigned' },
    nameEmailObj,
    nameInitialsObj,
    namesObj,
    emailObj,
    initialsObj
  );
  return resultantObj;
};

const getObject = (arrayList, separator) => {
  const resultantObj = {};
  if (arrayList !== null && arrayList !== undefined && arrayList.length !== 0) {
    arrayList.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    arrayList.map((element, index) => {
      const arrElements = element.split(separator);
      const key = 'U-' + arrElements[1].toString();
      Object.assign(resultantObj, { [key]: arrElements[0] });
    });
  }
  return resultantObj;
};

const getName = (user) => {
  let fullName = '';
  const { firstName, lastName } = user;
  if (isStringValid(firstName)) {
    fullName = firstName + ' ';
  }
  if (isStringValid(lastName)) {
    fullName += lastName;
  }
  return fullName;
};

const getValue = (user, key) => {
  if (key in user) {
    const value = user[key];
    return value;
  }
  return '';
};
