import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(10.5),
    marginRight: theme.spacing(10.5),
  },
  skeleton: {
    width: `100%`,
    margin: theme.spacing(2),
  },
}));

const CardsSkeleton = () => {
  const classes = useStyles();
  const skeletonArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div className={classes.root}>
      <Grid container justify="center">
        {skeletonArr.map((val) => {
          return (
            <Grid container item xs={4}>
              <Skeleton
                className={classes.skeleton}
                key={val}
                variant="rect"
                height={150}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export { CardsSkeleton };
