import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { useHistory } from 'react-router-dom';
import { isEnrichmentDashboardAllowedForPlan } from './../../utils/donnaPlansUtil';
import { COVDataEnrichment } from '@aureus/donna-cov-views';

const BILLING_PAYMENTS_PATH = '/home/settings/billing-details';

const DataEnrichment = (props) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const isEnrichmentAllowed = isEnrichmentDashboardAllowedForPlan(planType);
  const goToPlans = () => {
    history.push(BILLING_PAYMENTS_PATH);
  };
  const getDataEnrichmentStatus = async () => {
    return isEnrichmentAllowed;
  };
  return (
    <COVDataEnrichment
      {...props}
      goToPlans={goToPlans}
      getDataEnrichmentStatus={getDataEnrichmentStatus}
    />
  );
};

export { DataEnrichment };
