import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import PaymentHistory from '@aureus/stripe-billing-automation/lib/components/PaymentHistory/PaymentHistory';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { Title } from '../Header/Title';
import { BillingError } from './BillingError';

const API_PATH_PAYMENT_HISTORY = '/api/stripe/v1/getPaymentHistory';
const API_PATH_PAYMENT_HISTORY_CACHED =
  '/api/stripe/v1/getCachedPaymentHistory';
const APP_NAME = 'donna';

export default function PaymentHistoryDetails(props) {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const isBillingAllowed = user.isBillingAllowed();

  return (
    <div>
      <Title
        titleText="Payment History"
        hasBackButton={true}
        parentPath={props.parentPath}
      />
      <Container>
      {!isBillingAllowed && (<BillingError />)}
      {isBillingAllowed && (<PaymentHistory
          tenant={tenant}
          application={APP_NAME}
          endpointGetCachedPaymentHistory={API_PATH_PAYMENT_HISTORY_CACHED}
          endpointGetPaymentHistory={API_PATH_PAYMENT_HISTORY}
        />)}
      </Container>
    </div>
  );
}
PaymentHistoryDetails.propTypes = {
  parentPath: PropTypes.string.isRequired,
};
