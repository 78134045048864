import React, { useEffect, useState } from 'react';
import { Title } from '../Header';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CardsSkeleton } from './CardsSkeleton';
import { CustomerCardError } from './CustomerCardError';
import PropTypes from 'prop-types';
import { contextProperties } from './ContextProperties';
import CardsWithFilter from './CardsWithFilter';
import { LastRefreshDate } from '../Header/LastRefreshDate';
import {
  dataGridViewTypes,
  dataGridScopes,
  ServerSideGrid,
} from '@aureus/donna-data-grid';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import CONTEXT from '@aureus/cov-context';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import managerConfig from '../OneView/ManagerConfig.json';
import { isTableauDownloadAllowedForPlan } from '../UserContextProvider/plans';

const useStyles = makeStyles((theme) => ({
  leftArrow: {
    paddingTop: theme.spacing(3),
  },
  iconGrid: {
    alignSelf: 'center',
    marginBottom: 7,
    textAlign: 'end',
    paddingRight: 16,
  },
  updateButton: {
    background: '#19588036',
    color: '#195880',
    textTransform: 'inherit',
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
  },
  relative: {
    position: 'relative',
  },
  actionButtonContainer: {
    position: 'relative',
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
}));

const getDataEndpoint = '/api/tableau/v1/view';
const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';
const validateViewNameEndpoint = '/api/view/v1/validateViewName';
const updateViewNameEndpoint = '/api/view/v1/updateViewName';

const CustomerCardView = (props) => {
  const {
    customers,
    myListCustomers,
    context,
    onSelect,
    reload,
    icon,
    hasBackButton,
    customerCount,
  } = props;
  const { fetching, success, error } = props;
  const { backPath } = props;

  const classes = useStyles();
  const currentContextProperties = contextProperties[context];
  const defaultSortType = currentContextProperties.defaultSortType;

  let hasCustomers = false;
  if (Array.isArray(customers)) {
    hasCustomers = true;
  }

  let cardContext;
  if (currentContextProperties.propagate) {
    cardContext = context;
  }
  let Icon;
  if (typeof icon === 'boolean' && icon === true) {
    Icon = currentContextProperties.icon;
  } else if (typeof icon === 'function') {
    Icon = icon;
  }

  let initialView = '';
  let scope = '';
  if (cardContext === CONTEXT.MY_LIST) {
    initialView = dataGridViewTypes.CARD_VIEW;
    scope = dataGridScopes.MY_LIST;
  }
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const allowDownload = isTableauDownloadAllowedForPlan(planType);
  
  return (
    <div>
      {fetching && <CardsSkeleton />}
      {hasCustomers ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs className={classes.relative}>
              {customers.length === 0 && (
                <Title
                  hasBackButton={hasBackButton}
                  parentPath={backPath}
                  titleText={currentContextProperties.title}
                  subtitleText={currentContextProperties.subTitle}
                  icon={Icon}
                  right={<LastRefreshDate />}
                  homeContext={context}
                  homeButton={true}
                />
              )}
              {customers.length > 0 && (
                <Title
                  key={cardContext}
                  hasBackButton={hasBackButton}
                  parentPath={backPath}
                  titleText={currentContextProperties.title}
                  subtitleText={currentContextProperties.subTitle}
                  count={customerCount}
                  icon={Icon}
                  right={<LastRefreshDate />}
                  homeContext={context}
                  homeButton={true}
                />
              )}
            </Grid>
          </Grid>
          <CardsWithFilter
            key={cardContext}
            customers={customers}
            context={cardContext}
            onSelect={onSelect}
            reload={reload}
            defaultSortType={defaultSortType}
            {...props}
          />
        </>
      ) : (
        success && (
          <>
            <Grid container spacing={1}>
              <Grid item xs className={classes.relative}>
                {myListCustomers.length === 0 && (
                  <Title
                    hasBackButton={hasBackButton}
                    parentPath={backPath}
                    titleText={currentContextProperties.title}
                    subtitleText={currentContextProperties.subTitle}
                    icon={Icon}
                    right={<LastRefreshDate />}
                    homeContext={context}
                    homeButton={true}
                  />
                )}
                {myListCustomers.length > 0 && (
                  <Title
                    hasBackButton={hasBackButton}
                    parentPath={backPath}
                    titleText={currentContextProperties.title}
                    subtitleText={currentContextProperties.subTitle}
                    count={myListCustomers.length}
                    icon={Icon}
                    right={<LastRefreshDate />}
                    homeContext={context}
                    homeButton={true}
                  />
                )}
              </Grid>
            </Grid>

            <ServerSideGrid
              scope={dataGridScopes.MY_LIST}
              insertUserViewEndpoint={insertUserViewEndpoint}
              getUserViewNamesEndpoint={getUserViewNamesEndpoint}
              getUserViewEndpoint={getUserViewEndpoint}
              deleteUserViewEndpoint={deleteUserViewEndpoint}
              onSelect={onSelect}
              MyListContext={MyListContext}
              useOverallTrace={useOverallTrace}
              gaTracking={gaTracking}
              tenant={tenant}
              managerConfig={managerConfig}
              allowDownload={allowDownload}
              initialView={dataGridViewTypes.CARD_VIEW}
              validateViewNameEndpoint={validateViewNameEndpoint}
              updateViewNameEndpoint={updateViewNameEndpoint}
            />
          </>
        )
      )}
      {error && <CustomerCardError />}
    </div>
  );
};

CustomerCardView.propTypes = {
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  reload: PropTypes.func,
  icon: PropTypes.any,
};

export { CustomerCardView };
