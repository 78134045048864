import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { LockImageIcon } from '../Header/Icons';

const useStyles = makeStyles((theme) => ({
  lockDiv: {
    backgroundColor: 'rgb(45,96,137,0.2)',
    width: 15,
    height: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    position: 'absolute',
    right: 0,
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#4A4A4A',
    fontSize: 14,
    fontWeight: 500,
    width: 139,
    padding: '7px 10px',
  },
  arrow: {
    color: 'white',
    '&::before': {
      boxShadow: '-1px 3px 2px rgb(45 96 137 / 10%)',
    },
  },
}));

export function LockMenu() {
  const classes = useStyles();
  return (
    <Tooltip
      title="Upgrade to higher plan 
    to unlock this feature"
      placement="right"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <div className={classes.lockDiv}>
        <LockImageIcon />
      </div>
    </Tooltip>
  );
}
