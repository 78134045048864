import axios from 'axios';

export const setUpdateUserDetailsAndActivate = async ({
  userID,
  userInitials,
  firstName,
  lastName,
  userType,
  emailID,
  reportsTo,
  location,
  designation,
}) => {
  const path = `/api/admin/v1/user/updateDetailsAndActivateUser/${userID}`;
  const userDetails = {
    userInitials,
    firstName,
    lastName,
    userType,
    emailID,
    reportsTo,
    location,
    designation,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  const { status, data } = await axios.post(path, userDetails, { headers });
  return { status, data };
};
