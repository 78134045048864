import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ContactSupportIcon } from '../Header/Icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 30,
  },
}));

export function JiraDrawerItem(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon classes={{ root: classes.icon }}>
        <ContactSupportIcon />
      </ListItemIcon>
      <ListItemText primary="Contact Support" />
    </ListItem>
  );
}

JiraDrawerItem.propTypes = {
  onClick: PropTypes.func.isRequired,
};
