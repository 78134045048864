import { useState, useEffect } from 'react';
import { removeAvatar } from './removeAvatar';

export const actionTypes = {
  REMOVE_AVATAR: 'removeTenantLogo',
};

export const actionTypeFailureMessages = {
  [actionTypes.REMOVE_AVATAR]: 'Failed to remove tenant logo',
};

export function useSettingAction({ type, payload }) {
  const [userAction, setUserAction] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const userAction = async () => {
      setUserAction({ fetching: true, error: false, success: false });
      try {
        if (type === actionTypes.REMOVE_AVATAR) {
          const { status, data: response } = await removeAvatar();
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        }
      } catch (e) {
        setUserAction({ fetching: false, error: true, success: false });
      }
    };
    if (type !== undefined) {
      userAction();
    }
  }, [type, payload]);
  return userAction;
}
