import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { FeatureNotAvailableSplash } from './../Setup/FeatureNotAvailableSplash';

const logoutPath = '/api/auth/logout';
const homePath = '/home';

function Logout() {
  const onClick = () => {
    window.location.href = logoutPath;
  };
  const onClickHome = () => {
    window.location.href = homePath;
  };
  return (
    <>
      <Button size="large" onClick={onClickHome}>
        Home
      </Button>
      <Button size="large" onClick={onClick}>
        Logout
      </Button>
    </>
  );
}

export function FeatureNotAvailable(props) {
  const { user } = useContext(UserContext);
  let { feature } = useParams();
  const isBillingAllowed = user.isBillingAllowed();
  return (
    <div>
      <FeatureNotAvailableSplash
        feature={feature}
        isBillingAllowed={isBillingAllowed}
      />
    </div>
  );
}
