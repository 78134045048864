import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    maxWidth: theme.breakpoints.values.sm,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'transparent',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const stepMessages = ['Getting Started', 'Complete Profile', 'Add More People'];

const isStepSkipped = (index, activeStep) => {
  if (index < activeStep) {
    return true;
  }
  return false;
};

export function GuideStepper(props) {
  const classes = useStyles();
  const { activeStep } = props;
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {stepMessages.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index, activeStep)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

GuideStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
};
