import axios from 'axios';
import { useEffect, useState } from 'react';

const path = '/api/customerData/v1/overallTrace';

const insertOverallTrace = (customer, overallTrace, customerId) => {
  const customerWithOverallTrace = {
    ...customer,
  };
  try {
    const { Household } = customerWithOverallTrace;
    const { customerDetails } = Household[customerId];
    Household[customerId].customerDetails = Object.assign(customerDetails, {
      overallTrace,
    });
  } catch (error) {}
  try {
    const { main_customer: mainCustomer } = customerWithOverallTrace;
    const { customerDetails } = mainCustomer;
    mainCustomer.customerDetails = Object.assign(customerDetails, {
      overallTrace,
    });
  } catch (error) {}
  return customerWithOverallTrace;
};

const extractOverallTrace = (response, customerId) => {
  let overallTrace;
  let customer;
  try {
    const { data } = response;
    if (Array.isArray(data)) {
      const [overallTraceData] = data;
      customer = overallTraceData[customerId];
    } else {
      customer = data[customerId];
    }
    ({ overallTrace } = customer);
  } catch (error) {}
  return overallTrace;
};

function useOverallTrace(
  customer,
  needOverallTrace,
  resetCustomerNeedOverallTrace
) {
  const [customerData, setCustomerData] = useState(customer);
  useEffect(() => {
    const getOverallTrace = async () => {
      const { Main: customerId } = customer;
      if (customer[customerId]?.customerDetails?.overallTrace) {
        setCustomerData(customer);
        return;
      }
      // const requestObject = {
      //   customerId: customerId,
      // };
      // const headers = {
      //   'Content-Type': 'application/json',
      // };
      // const { status, data: response } = await axios.post(path, requestObject, {
      //   headers,
      // });
      // if (status === 200) {
      //   const overallTrace = extractOverallTrace(response, customerId);
      //   const customerData = insertOverallTrace(
      //     customer,
      //     overallTrace,
      //     customerId
      //   );
      //   setCustomerData(customerData);
      // }
    };
    if (needOverallTrace) {
      resetCustomerNeedOverallTrace();
      getOverallTrace();
    }
  }, [customer, needOverallTrace, resetCustomerNeedOverallTrace]);
  return customerData;
}

export default useOverallTrace;
