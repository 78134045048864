import React from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { RoundedButton } from './../Guide';

const USER_MANAGEMENT_GUIDE_PATH = '/home/settings/users/guide';

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 38,
    marginBottom: 20,
  },
  skipButtonAbsolute: {
    position: 'absolute',
    right: 'top',
    bottom: 18,
    right: 96,
  },
}));

export function SkipCompleteProfile(props) {
  const { changeRoleMode, noRoleSetMode } = props;
  const history = useHistory();
  const onClick = () => {
    history.push(USER_MANAGEMENT_GUIDE_PATH);
  };
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.buttonDiv, {
        [classes.skipButtonAbsolute]: !changeRoleMode && !noRoleSetMode,
      })}
    >
      <Button variant="outlined" size="large" onClick={onClick}>
        Skip
      </Button>
    </div>
  );
}

export function SkipCompleteProfileRounded() {
  const history = useHistory();
  const onClick = () => {
    history.push(USER_MANAGEMENT_GUIDE_PATH);
  };
  return (
    <RoundedButton
      label="Next"
      size="medium"
      isSubmit={false}
      onClick={onClick}
    />
  );
}
