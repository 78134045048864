import React from 'react';
import { UserContext } from '../UserContext';
import { Redirect } from 'react-router-dom';
import { useSetupUserProfile } from './useSetupUserProfile';
import { SetupUser } from './SetupUser';

export function SetupUserContextProvider(props) {
  const profile = useSetupUserProfile();
  const user = new SetupUser(profile);
  const success = user.isSuccess();
  const fetching = user.isFetching();
  const error = user.isError();

  return (
    <UserContext.Provider value={{ user: user }}>
      {fetching && <div>Loading...</div>}
      {error && <Redirect to="/login/retry" />}
      {success && props.children}
    </UserContext.Provider>
  );
}
