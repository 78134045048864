import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderRadius: 99999999,
    borderStyle: 'solid',
    width: '100%',
  },
  indicator: {
    display: 'none',
  },
}));

export function StyledTabs(props) {
  const classes = useStyles();
  return (
    <Tabs
      {...props}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      centered
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
    />
  );
}
