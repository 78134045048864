import React from 'react';
import PropTypes from 'prop-types';
import { BenchmarkSkeleton } from './BenchmarkSkeleton';
import { BenchmarkError } from './BenchmarkError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function BenchmarkType(props) {
  const { mainType, type } = props;
  const benchmarkApiPath = `/api/metrics/v1/${mainType}/${type}`;
  const benchmarkDetails = useViewDetails(benchmarkApiPath);
  const { fetching, success, error, data } = benchmarkDetails;
  return (
    <div>
      {fetching && <BenchmarkSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <BenchmarkError />}
    </div>
  );
}

BenchmarkType.propTypes = {
  mainType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
