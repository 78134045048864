import React from 'react';
import PropTypes from 'prop-types';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { EnrichmentError } from './EnrichmentError';

const useStyles = makeStyles((theme) => ({
  hover: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    opacity: 0,
    zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '200px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function EnrichmentWidget(props) {
  const classes = useStyles();
  const { detailsPath } = props;
  const infoBarDetails = useViewDetails(detailsPath);
  const { fetching, success, error, data } = infoBarDetails;
  return (
    <div>
      <div className={classes.roundedNavBar}>
        {fetching && (
          <Skeleton variant="rect" height={dimensions.placeholderHeight} />
        )}
        {success && (
          <>
            <TableauMarkSelectionView
              {...data}
              {...dimensions}
              adjustHeight={true}
            />
          </>
        )}
        {error && <EnrichmentError />}
      </div>
    </div>
  );
}

EnrichmentWidget.propTypes = {
  detailsPath: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
};
