import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useRequestForUpdate from '../Enrichment/useRequestForUpdate';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  snackBar: {
    top: 89,
    bottom: 'auto',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ConfirmationModal = (props) => {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [showSnackBar, setSnack] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const API_PATH = '/api/jira/v1/sendRequest';
  const fields = {
    summary: 'Data enrichments update request',
    description: 'Customer want to update the Data Enrichment',
    category: process.env.REACT_APP_BASE_JIRA_DATA_ENRICHMENT_UPDATE_REQUEST_ID,
  };

  const { requests, onSubmit } = useRequestForUpdate();
  const { fetching, success, error } = requests;

  const onSuccess = () => {
    setSnack(true);
    handleClose();
  };

  const sendUpdateRequest = () => onSubmit(fields, API_PATH, onSuccess);
  if (fetching) {
    document.body.style.cursor = 'wait';
  } else {
    document.body.style.cursor = 'default';
  }
  return (
    <div>
      {success && (
        <Snackbar
          open={showSnackBar}
          autoHideDuration={5000}
          classes={{
            root: classes.snackBar,
          }}
          onClose={() => setSnack(false)}
        >
          <MuiAlert severity="success">
            The request has been placed successfully. Further updates will be
            notified to you through email.
          </MuiAlert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          open={showSnackBar}
          autoHideDuration={5000}
          classes={{
            root: classes.snackBar,
          }}
          onClose={() => setSnack(false)}
        >
          <MuiAlert severity="error">
            There was a problem processing your request. Please contact DONNA
            Support.
          </MuiAlert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          You are sending a request to DONNA support team for refreshing your
          Data. Do you want to proceed?
        </DialogContent>
        <DialogActions>
          <Button
            style={{ cursor: fetching ? 'wait' : 'pointer' }}
            autoFocus
            onClick={handleClose}
            color="primary"
          >
            No
          </Button>
          <Button
            style={{ cursor: fetching ? 'wait' : 'pointer' }}
            autoFocus
            onClick={sendUpdateRequest}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationModal;
