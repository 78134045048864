import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import donnagpt from '../../assets/icons/donnagpt.png';
import { useTenantDetails } from './useTenantDetails';

const useStyles = makeStyles((theme) => ({
  bottomRight: {
    position: 'fixed',
    bottom: 166,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
  margin: {
    margin: theme.spacing(3),
  },
  image: {
    height: 50,
    width: 50,
  },
}));

const DonnaGPT = () => {
  const classes = useStyles();

  const onClick = () => {
    window.open(process.env.REACT_APP_DONNA_GPT_URL, '_blank');
  };

  const { success, data } = useTenantDetails();
  let showDonnaKnows = false;
  if (success && data) {
    showDonnaKnows = data.showDonnaKnows === 'true' || data.showDonnaKnows === true;
  }
  return (
    <>
      {showDonnaKnows && (
        <div className={classes.bottomRight}>
          <Tooltip title="DONNAKnows">
            <Fab
              size="medium"
              color="primary"
              aria-label="donna-gpt"
              className={classes.margin}
              onClick={onClick}
            >
              <img src={donnagpt} className={classes.image} />
            </Fab>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DonnaGPT;
