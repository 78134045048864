import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import {
  ProfileSetting,
  UserSettings,
  HelpVideoSettings,
  MyProfileSettings,
  BillingAutomationSettings,
} from './../ProfileSetting';
import { GuideSplash } from './../Guide';
import { NoSubscription } from './../BillingAutomation';
import { FeatureNotAvailable } from './../BillingAutomation';
import { FeatureUsed } from './../BillingAutomation';
import { Settings as DonnaSetting } from '../DonnaSetting';

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
}));

export function Settings() {
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <Container className={classes.screenContainer} maxWidth="xl">
      <Switch>
        <Route path={`${match.path}/users`}>
          <UserSettings />
        </Route>
        <Route path={`${match.path}/profile`}>
          <ProfileSetting />
        </Route>
        <Route path={`${match.path}/app-settings`}>
          <DonnaSetting />
        </Route>
        <Route path={`${match.path}/help`}>
          <HelpVideoSettings />
        </Route>
        <Route path={`${match.path}/setup/guide`}>
          <GuideSplash />
        </Route>
        <Route path={`${match.path}/my-profile`}>
          <MyProfileSettings />
        </Route>
        <Route path={`${match.path}/billing-details`}>
          <BillingAutomationSettings />
        </Route>
        <Route path={`${match.path}/no-active-subscription`}>
          <NoSubscription />
        </Route>
        <Route exact path={`${match.path}/feature-not-available`}>
          <FeatureNotAvailable />
        </Route>
        <Route path={`${match.path}/feature-not-available/:feature`}>
          <FeatureNotAvailable />
        </Route>
        <Route path={`${match.path}/feature-used/:feature/:count/:timePeriod`}>
          <FeatureUsed />
        </Route>
        <Route path={`${match.path}/feature-used`}>
          <FeatureUsed />
        </Route>
      </Switch>
    </Container>
  );
}
