import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MyProfile } from './../MyProfile';

export function MyProfileSettings() {
  return (
    <Switch>
      <Route>
        <MyProfile guide={false} />
      </Route>
    </Switch>
  );
}