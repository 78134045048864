import axios from 'axios';

export const setUserLink = async (targetUserDetails) => {
  const path = `/api/admin/v1/user/linkUser`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const { status, data } = await axios.post(path, targetUserDetails, {
    headers,
  });
  return { status, data };
};
