import axios from 'axios';
import { useEffect, useState } from 'react';
import { contextProperties } from './../Diem/ContextProperties';

const getData = (customers) => {
  const data = [];
  if (customers) {
    customers.forEach((customer) => {
      const customerId = customer[2];
      try {
        const stateJson = JSON.parse(customer[6]);
        const context = stateJson.context;
        data.push({ customerId: customerId, context: context });
      } catch (error) {
        return {
          error: true,
          errorMessage: 'Unable to parse state',
        };
      }
    });
  }
  return {
    error: false,
    data: data,
  };
};

function useAllCustomers(context, load, stopLoad) {
  const [allCustomers, setAllCustomers] = useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });
  const customerContext = contextProperties[context];
  const { customerSegmentPath: path } = customerContext;
  if (load === undefined) {
    load = true;
  }
  useEffect(() => {
    const getAllCustomers = async () => {
      setAllCustomers({
        fetching: true,
        success: false,
        error: false,
        data: undefined,
      });
      const requestObject = {};
      const headers = {
        'Content-Type': 'application/json',
      };
      const { status, data } = await axios.post(path, requestObject, {
        headers,
      });
      if (status !== 200) {
        setAllCustomers({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      } else {
        const filteredData = getData(data.data);
        if (filteredData.error) {
          setAllCustomers({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setAllCustomers({
            fetching: false,
            success: true,
            error: false,
            data: filteredData.data,
          });
        }
      }
    };
    if (load) {
      if (typeof stopLoad === 'function') {
        stopLoad();
      }
      getAllCustomers();
    }
  }, [path, stopLoad, load]);
  return allCustomers;
}

export default useAllCustomers;
