import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { CompleteProfile } from './CompleteProfile';

export function ProfileSetting() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/complete/guide`}>
        <CompleteProfile guide={true} />
      </Route>
      <Route path={`${match.path}/complete`}>
        <CompleteProfile guide={false} />
      </Route>
    </Switch>
  );
}
