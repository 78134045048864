import axios from 'axios';
import { quoteTypes } from './quoteTypes';
import { gaEvent } from '../GoogleAnalytics/gaEvent';

const path = '/api/quotes/v1/sendQuote';

const gaTracking = (userId, value) => {
  const category = 'quoteRequested';
  const action = 'Tarmika';
  const label = userId;
  gaEvent(category, action, label, value);
};

const sendQuote = async (payload, type, email) => {
  let requestObject = {};
  let response = {};
  if (type === quoteTypes.ACTIVITY) {
    requestObject = {
      customerId: payload.customerID,
      activityId: payload.activityID,
      emailID: email,
    };
  }

  if (type === quoteTypes.RELATIONSHIP) {
    requestObject = {
      customerId: payload['Customer ID'],
      relationshipId: payload['Relationship ID'],
      emailID: email,
    };
  }
  try {
    const { status, data } = await axios.post(path, requestObject);
    if (status !== 200) {
      throw new Error('Error sending quote request');
    }
    const { error, errorMessage, data: quoteData } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    quoteData['quoteReferenceNumber'] = quoteData['partnerRequestReferenceId'];
    gaTracking(
      payload.customerID,
      parseFloat(quoteData['quoteReferenceNumber'])
    );
    response = { error, errorMessage, data: quoteData };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default sendQuote;
