import { useState, useEffect } from 'react';
import { actionTypes } from './actionTypes';
import { setUserDetails } from './setUserDetails';
import { setNewUser } from './setNewUser';
import { setUpdateUserDetails } from './setUpdateUserDetails';
import { setUserActivation } from './setUserActivation';
import { setUserLink } from './setUserLink';
import { setUserEnable } from './setUserEnable';
import { setUserDisable } from './setUserDisable';
import { setActivationEmailResend } from './setActivationEmailResend';
import { setCompleteProfile } from './setCompleteProfile';
import { setUpdateUserDetailsAndActivate } from './setUpdateUserDetailsAndActivate';
import { setNewUserAndActivate } from './setNewUserAndActivate';
import { setUserDetailsAndActivate } from './setUserDetailsAndActivate';

export function useUserAction({ type, payload }) {
  const [userAction, setUserAction] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const userAction = async () => {
      setUserAction({ fetching: true, error: false, success: false });
      try {
        if (type === actionTypes.SET_DETAILS) {
          const { status, data: response } = await setUserDetails(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.SET_DETAILS_AND_ACTIVATE) {
          const { status, data: response } = await setUserDetailsAndActivate(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.NEW_USER) {
          const { status, data: response } = await setNewUser(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.ADD_AND_ACTIVATE_USER) {
          const { status, data: response } = await setNewUserAndActivate(
            payload
          );
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.UPDATE_DETAILS) {
          const { status, data: response } = await setUpdateUserDetails(
            payload
          );
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.UPDATE_AND_ACTIVATE_USER) {
          const { status, data: response } =
            await setUpdateUserDetailsAndActivate(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.ACTIVATE) {
          const { status, data: response } = await setUserActivation(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.DISABLE) {
          const { status, data: response } = await setUserDisable(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.ENABLE) {
          const { status, data: response } = await setUserEnable(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.ACTIVATION_EMAIL) {
          const { status, data: response } = await setActivationEmailResend(
            payload
          );
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.LINK) {
          const { status, data: response } = await setUserLink(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else if (type === actionTypes.COMPLETE_PROFILE) {
          const { status, data: response } = await setCompleteProfile(payload);
          if (status !== 200) {
            throw new Error();
          }
          const { error, errorMessage, data } = response;
          if (error) {
            throw new Error(errorMessage);
          }
          setUserAction({
            fetching: false,
            error: false,
            success: true,
            data,
          });
        } else {
          throw new Error('Unknown action type');
        }
      } catch (e) {
        setUserAction({ fetching: false, error: true, success: false });
      }
    };
    if (type !== undefined) {
      userAction();
    }
  }, [type, payload]);
  return userAction;
}
