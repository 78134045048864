const addFilter = (tableauData, key, value) => {
  const { filter } = tableauData;
  let newFilter;
  if (typeof filter === 'string') {
    if (filter === '') {
      newFilter = `${key}=${value}`;
    } else {
      newFilter = `${filter}&${key}=${value}`;
    }
  }
  const newTableauData = { ...tableauData };
  newTableauData.filter = newFilter;
  return newTableauData;
};

export default addFilter;
