import React from 'react';
import logo from './../../assets/donna.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  logo: {
    height: 32,
  },
  logoAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export function AppLogo() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoAlt}>
        <img className={classes.logo} src={logo} alt="Donna" />
      </div>
    </div>
  );
}
