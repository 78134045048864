import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import BillingAndPayments from '@aureus/stripe-billing-automation/lib/components/BillingAndPayments/BillingAndPayments';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { Title } from '../Header/Title';
import { BillingError } from './BillingError';

const API_PATH_DETAILS = '/api/stripe/v1/getBillingDetails';
const API_PATH_BILLING = '/api/stripe/v1/addBillingInfo';
const API_PATH_PLANS = '/api/stripe/v1/getAllPlans';
const API_PATH_PAYMENT = '/api/stripe/v1/addPaymentMethod';
const API_PATH_PAY = '/api/stripe/v1/pay';
const API_PATH_DELETE_PAYMENT_METHOD = '/api/stripe/v1/deletePaymentMethod';
const API_PATH_CANCEL_SUBSCRIPTION = '/api/stripe/v1/cancelSubscription';
const API_PATH_SERVICE_REQUEST_TICKET = '/api/jira/v1/sendRequest';
const API_PATH_GET_PAYMENT = '/api/stripe/v1/getPaymentDetails';
const APP_NAME = 'donna';

const API_PATH_SUBSCRIPTION_CREATE = '/api/stripe/v1/createSubscriptionAndPay';
const API_PATH_SUBSCRIPTION_UPGRADE =
  '/api/stripe/v1/upgradeSubscriptionAndPay';
const API_PATH_GET_TAX = '/api/stripe/v1/getTaxRate';
const API_PATH_GET_TAX_DETAILS = '/api/stripe/v1/getTaxDetails';
const API_PATH_GET_PRORATION = '/api/stripe/v1/getProration';
const API_PATH_VALIDATE_COUPON = '/api/stripe/v1/getDiscount';

const API_GET_SUBSCRIPTION_DETAILS = '/api/stripe/v1/getSubscriptionDetails';
const API_ADJUSTMENTS = '/api/stripe/v1/getCustomerBalance';
const API_PATH_GET_INVOICE_DETAILS = '/api/stripe/v1/getCurrentInvoice';

export default function BillingPaymentDetails(props) {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const isBillingAllowed = user.isBillingAllowed();
  const tenantApi = user.getTenantApi();
  const amsName = tenantApi.getAmsName();


  return (
    <div>
      <Title
        titleText="Subscribing for a plan"
        hasBackButton={true}
        parentPath={props.parentPath + '/plans'}
      />
      <Container maxWidth="xl">
      {!isBillingAllowed && (<BillingError />)}
        {isBillingAllowed && (
          <BillingAndPayments
            tenant={tenant}
            application={APP_NAME}
            parentPath={props.parentPath}
            endpointGetBillingPaymentsDetails={API_PATH_DETAILS}
            endpointAddBillingInfo={API_PATH_BILLING}
            endpointGetAllPlans={API_PATH_PLANS}
            endpointAddPaymentInfo={API_PATH_PAYMENT}
            endpointPay={API_PATH_PAY}
            endpointDeletePaymentMethod={API_PATH_DELETE_PAYMENT_METHOD}
            endPointCancelSubscription={API_PATH_CANCEL_SUBSCRIPTION}
            enpointServiceRequestTicket={API_PATH_SERVICE_REQUEST_TICKET}
            email={''}
            agency={''}
            endPointGetPaymentDetails={API_PATH_GET_PAYMENT}
            endpointGetSubscriptionDetails={API_GET_SUBSCRIPTION_DETAILS}
            endpointGetInvoiceDetails={API_PATH_GET_INVOICE_DETAILS}
            endpointAdjustments={API_ADJUSTMENTS}
            enpointCreateSubscription={API_PATH_SUBSCRIPTION_CREATE}
            endPointUpgradeSubscription={API_PATH_SUBSCRIPTION_UPGRADE}
            endPointGetTax={API_PATH_GET_TAX_DETAILS}
            endPointGetProration={API_PATH_GET_PRORATION}
            endPointValidateCoupon={API_PATH_VALIDATE_COUPON}
            amsName={amsName}
          />
        )}
      </Container>
    </div>
  );
}
BillingPaymentDetails.propTypes = {
  parentPath: PropTypes.string.isRequired,
};
