import validator from 'validator';
import { getAssignableRoleKeys } from '@aureus/donna-roles';

const MAX_NAME_LENGTH = 45;
const roleKeys = getAssignableRoleKeys();

export const validateEmail = (emailID, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof emailID !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (
    !validator.isEmail(emailID, {
      allow_utf8_local_part: false,
    })
  ) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  } else if (!validator.isLength(emailID, { min: 1, max: MAX_NAME_LENGTH })) {
    error = true;
    errorMessages.push(
      `${fieldName} cannot be greater than ${MAX_NAME_LENGTH} characters`
    );
  }
  return { error, errorMessages };
};

export const validateName = (name, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof name !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (validator.isEmpty(name, { ignore_whitespace: true })) {
    error = true;
    errorMessages.push(`${fieldName} cannot be empty`);
  } else if (!validator.isAscii(name)) {
    error = true;
    errorMessages.push(`${fieldName} cannot contain special characters`);
  } else if (!validator.isLength(name, { min: 1, max: MAX_NAME_LENGTH })) {
    error = true;
    errorMessages.push(
      `${fieldName} cannot be greater than ${MAX_NAME_LENGTH} characters`
    );
  }
  return { error, errorMessages };
};

export const validateUserType = (userType, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof userType !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (!validator.isIn(userType, roleKeys)) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  }
  return { error, errorMessages };
};

export const validateReportsTo = (reportsTo, fieldName) => {
  let error = false;
  let errorMessages = [];
  let reportsToVal = '';

  if (reportsTo) {
    if (reportsTo.includes('U-')) {
      reportsToVal = '' + reportsTo.substring(2);
    } else {
      reportsToVal = '' + reportsTo;
    }
  } else {
    reportsToVal = '0';
  }

  if (typeof reportsToVal !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (
    !validator.isInt(reportsToVal, {
      min: 0,
      max: Number.MAX_SAFE_INTEGER,
      allow_leading_zeroes: false,
    })
  ) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  }
  return { error, errorMessages };
};

export const validateLocation = (location, fieldName) => {
  let error = false;
  let errorMessages = [];
  location = '' + location;
  if (typeof location !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (
    !validator.isInt(location, {
      min: 0,
      max: Number.MAX_SAFE_INTEGER,
      allow_leading_zeroes: false,
    })
  ) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  }
  return { error, errorMessages };
};

export const validateDesignation = (designation, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof designation !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is necessary`);
  } else if (validator.isEmpty(designation, { ignore_whitespace: true })) {
    error = true;
    errorMessages.push(`${fieldName} cannot be empty`);
  } else if (!validator.isAscii(designation)) {
    error = true;
    errorMessages.push(`${fieldName} cannot contain special characters`);
  } else if (
    !validator.isLength(designation, { min: 1, max: MAX_NAME_LENGTH })
  ) {
    error = true;
    errorMessages.push(
      `${fieldName} cannot be greater than ${MAX_NAME_LENGTH} characters`
    );
  }
  return { error, errorMessages };
};

export const validateEditedRow = (newData) => {
  let error = false;
  let errorMessages = [];
  const {
    firstName,
    lastName,
    emailID,
    userType,
    reportsTo,
    location,
    designation,
  } = newData;
  const { error: firstNameError, errorMessages: firstNameErrorMessages } =
    validateName(firstName, 'First name');
  const { error: lastNameError, errorMessages: lastNameErrorMessages } =
    validateName(lastName, 'Last name');
  const { error: emailIDError, errorMessages: emailIDErrorMessages } =
    validateEmail(emailID, 'Email ID');
  const { error: userTypeError, errorMessages: userTypeErrorMessages } =
    validateUserType(userType, 'Role');
  const { error: reportsToError, errorMessages: reportsToErrorMessages } =
    validateReportsTo(reportsTo, 'Reporting To');
  const { error: locationError, errorMessages: locationErrorMessages } =
    validateLocation(location, 'Location');
  const { error: designationError, errorMessages: designationErrorMessages } =
    validateDesignation(designation, 'Designation');
  error =
    firstNameError ||
    lastNameError ||
    emailIDError ||
    userTypeError ||
    reportsToError ||
    locationError ||
    designationError;
  errorMessages = [
    ...firstNameErrorMessages,
    ...lastNameErrorMessages,
    ...emailIDErrorMessages,
    ...userTypeErrorMessages,
    ...designationErrorMessages,
    ...reportsToErrorMessages,
    ...locationErrorMessages,
  ];
  return { error, errorMessages };
};
