export const isStringValid = (strToBeChecked) => {
  if (
    strToBeChecked !== null &&
    strToBeChecked !== undefined &&
    strToBeChecked !== ''
  ) {
    return true;
  }
  return false;
};
