import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const uppy = new Uppy({
  meta: { type: 'avatar ' },
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/*'],
    minNumberOfFiles: 1,
    maxFileSize: 2000000,
  },
  autoProceed: false,
  allowMultipleUploads: false,
  locale: {
    strings: {
      retryUpload: 'Retry',
      dropPasteImportFiles: 'Drop agency logo here or browsefiles',
      uploadXFiles: {
        0: 'Set As Agency Logo',
      },
      xFilesSelected: {
        0: 'Agency logo selected',
      },
    },
  },
});

uppy.use(XHRUpload, {
  endpoint: '/api/image/v1/setTenantLogo',
  formData: true,
  fieldName: 'media',
});

export function TenantImageUpload(props) {
  const { onSuccess } = props;
  useEffect(() => {
    uppy.reset();
    uppy.off('upload-success');
    uppy.on('upload-success', () => {
      onSuccess();
    });
  }, [onSuccess]);
  return (
    <div>
      <Dashboard
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        height={250}
        note="Recommended size 300 x 50 px upto 2MB"
      />
    </div>
  );
}

TenantImageUpload.props = {
  avatarURL: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
};
