import React, { useEffect } from 'react';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './../Dashboard';
import { Portfolio } from './../Portfolio';
import { Analytics } from './../Analytics';
import { Diem, View } from './../Diem';
import { UserContext } from './../UserContext';
import { Foresight } from './../Foresight';
import { Enrichment } from './../Enrichment';
import { Benchmark } from './../Benchmark';
import { MyListContextProvider } from '../CardView/MyListContextProvider';
import { BookOfBusiness } from '../Dashboard/BookOfBusiness';
import { Hanover } from '../Hanover/Hanover';

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    paddingBottom: theme.spacing(6),
  },
}));

const getFirstView = (user) => {
  let firstView;
  if (user.isOverallDiemEnabled()) {
    firstView = 'diem/overall';
  } else if (user.isScopedDiemEnabled()) {
    firstView = 'diem/team';
  } else if (user.isPersonalDiemEnabled()) {
    firstView = 'diem/personal';
  } else if (user.isOverallDashboardEnabled()) {
    firstView = 'book-of-business';
  } else if (user.isScopedOverallDashboardEnabled()) {
    firstView = 'team-book-of-business';
  } else if (user.isPersonalDashboardEnabled()) {
    firstView = 'my-business';
  } else if (user.isPortfolioEnabled()) {
    firstView = 'portfolio';
  } else if (user.isScopedAnalyticsEnabled()) {
    firstView = 'team-analytics';
  } else if (user.isPersonalAnalyticsEnabled()) {
    firstView = 'my-analytics';
  } else if (user.isAnalyticsEnabled()) {
    firstView = 'analytics';
  }
  return firstView;
};

export function Home() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isVizAccessAllowed = user.isVizAccessAllowed();
  let match = useRouteMatch();
  const firstView = getFirstView(user);

  useEffect(() => {
    const path = '/api/notification/v1/getAllNotifications';

    const checkFunc = async () => {
      const response = await fetch(path, {
        cache: 'no-store',
      });
      if (response.redirected) {
        window.location.href = response.url;
      }
    };
    if (isVizAccessAllowed) {
      checkFunc();
    }
  }, [isVizAccessAllowed]);

  return (
    <Switch>
      {isVizAccessAllowed && firstView && (
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/${firstView}`} />
        </Route>
      )}
      <Route path={match.path}>
        <MyListContextProvider>
          <Container className={classes.screenContainer} maxWidth="xl">
            {isVizAccessAllowed && (
              <>
                <Route path={`${match.path}/diem/overall`}>
                  <Diem type="overall" />
                </Route>
                <Route path={`${match.path}/diem/team`}>
                  <Diem type="team" />
                </Route>
                <Route path={`${match.path}/diem/personal`}>
                  <Diem type="personal" />
                </Route>
                <Route path={`${match.path}/view/overall`}>
                  <View type="diem/overall" />
                </Route>
                <Route path={`${match.path}/view/team`}>
                  <View type="diem/team" />
                </Route>
                <Route path={`${match.path}/view/personal`}>
                  <View type="diem/personal" />
                </Route>
                <Route path={`${match.path}/book-of-business`}>
                  <BookOfBusiness />
                </Route>
                <Route path={`${match.path}/team-book-of-business`}>
                  <Dashboard type="team-book-of-business" />
                </Route>
                <Route path={`${match.path}/portfolio`}>
                  <Portfolio />
                </Route>
                <Route path={`${match.path}/hanover`}>
                  <Hanover />
                </Route>
                <Route path={`${match.path}/analytics`}>
                  <Analytics type="analytics" />
                </Route>
                <Route path={`${match.path}/team-analytics`}>
                  <Analytics type="team-analytics" />
                </Route>
                <Route path={`${match.path}/my-analytics`}>
                  <Analytics type="my-analytics" />
                </Route>
                <Route path={`${match.path}/foresight`}>
                  <Foresight />
                </Route>
                <Route path={`${match.path}/enrichment`}>
                  <Enrichment />
                </Route>
                <Route path={`${match.path}/benchmark`}>
                  <Benchmark />
                </Route>
              </>
            )}
          </Container>
        </MyListContextProvider>
      </Route>
    </Switch>
  );
}
