import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTabs, StyledTab } from './../Styled';
import { TabPanel } from './TabPanel';
import { Requests } from './Requests';
import { SendRequest } from './SendRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    paddingTop: theme.spacing(4),
  },
}));

export function JiraTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="jira-support-tabs"
      >
        <StyledTab label="Create Ticket" />
        <StyledTab label="Ticket History" />
      </StyledTabs>
      <div className={classes.padding}></div>
      <TabPanel value={value} index={0}>
        <SendRequest />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <Requests />
        </div>
      </TabPanel>
    </div>
  );
}
