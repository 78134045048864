import CONTEXT from '@aureus/cov-context';
import { MyListCustomerProvider } from './MyListCustomerProvider';
import { ContextCustomerProvider } from './ContextCustomerProvider';
import { ListIcon, RenewIcon, RoundoutIcon, RetainIcon } from '../Header/Icons';
import { ContextCardDataProvider } from './ContextCardDataProvider';

const contextProperties = {
  generic: {
    Provider: ContextCustomerProvider,
    title: 'Top New Recommendations',
    titleSmall: 'Top New Recommendations',
    tooltipText:
      'These are the most important recommendations from all categories that need your attention. This list is dynamic and will update in real-time as you work.',
    subTitle: 'Top customers that need your attention',
    accentColor: '#8D72CA',
    propagate: false,
    selected: ['Sentimeter'],
    warningText: 'This process cannot be undone.',
    contextRemoveAPI: '/api/segmentation/v1/topNewRecommendation/addLocal',
    customerSegmentPath:
      '/api/segmentation/v1/topNewRecommendation/getAllCustomers',
    defaultSortType: 'Donna Suggested',
  },
  myList: {
    Provider: MyListCustomerProvider,
    title: 'My List',
    titleSmall: 'My List',
    icon: ListIcon,
    subTitle: 'Customers added by you',
    accentColor: '#98B2CA',
    propagate: false,
    selected: ['Sentimeter'],
    customerSegmentPath: '/api/segmentation/v1/myList/getAllCustomersWithData',
    defaultSortType: 'Donna Suggested',
  },
  [CONTEXT.RETAIN]: {
    Provider: ContextCardDataProvider,
    title: 'Retain Customer',
    titleSmall: 'Retain',
    icon: RetainIcon,
    tooltipText:
      'These are the clients that have the lowest probability of retaining, organized from highest premium value to lowest.',
    subTitle: 'Customers with low probability of retention',
    accentColor: '#D47A27',
    propagate: true,
    selected: ['Sentimeter'],
    warningText: 'This process cannot be undone.',
    contextRemoveAPI: '/api/segmentation/v1/retainCustomer/addLocal',
    customerSegmentPath: '/api/segmentation/v1/retainCustomer/getAllCustomers',
    defaultSortType: 'Donna Suggested',
  },
  [CONTEXT.ROUNDOUT]: {
    Provider: ContextCardDataProvider,
    title: 'Round Out Customers',
    titleSmall: 'Round Out',
    icon: RoundoutIcon,
    tooltipText:
      'These are your clients with the highest premium recommendations, organized by the highest SentiMeter Score to the lowest.',
    subTitle: 'New revenue opportunities from existing customers',
    accentColor: '#30AFC0',
    propagate: true,
    selected: ['Predictions'],
    warningText: 'This process cannot be undone.',
    contextRemoveAPI: '/api/segmentation/v1/roundoutCustomer/addLocal',
    customerSegmentPath:
      '/api/segmentation/v1/roundoutCustomer/getAllCustomers',
    defaultSortType: 'Donna Suggested',
  },
  [CONTEXT.RENEWAL]: {
    Provider: ContextCardDataProvider,
    title: 'Renew Customer',
    titleSmall: 'Renew',
    icon: RenewIcon,
    tooltipText:
      'This is a list of clients set to renew in the next 45 days organized from highest renewal probability to lowest.',
    subTitle: 'Renewal in next 45 days',
    accentColor: '#4862BB',
    propagate: true,
    selected: ['Relation'],
    warningText: 'This process cannot be undone.',
    contextRemoveAPI: '/api/segmentation/v1/renewCustomer/addLocal',
    customerSegmentPath: '/api/segmentation/v1/renewCustomer/getAllCustomers',
    defaultSortType: 'Donna Suggested',
  },
};

export { contextProperties };
