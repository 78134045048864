export default function getDuplicateUser(users, newUser) {
  const duplicateUsers = users.filter((user) => {
    return (
      (!user.userID || !newUser.userID || user.userID !== newUser.userID) &&
      user.emailID === newUser.emailID
    );
  });

  if (duplicateUsers.length > 1) {
    return {
      ...duplicateUsers.reduce((acc, val) => {
        acc = val.graviteeProfileCreated ? val : acc;
        return acc;
      }),
    };
  } else if (duplicateUsers.length === 1) {
    return { ...duplicateUsers[0] };
  } else {
    return {};
  }
}
