import React from 'react';
import { HanoverIcon } from '../Header/Icons';
import { Title } from '../Header';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { HanoverSkeleton } from './HanoverSkeleton';
import { HanoverError } from './HanoverError';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100px',
};

export function Hanover() {
  const hanoverDataPath = `/api/metrics/v1/hanoverAI`;
  const hanoverDetails = useViewDetails(hanoverDataPath);
  const { fetching, success, error, data } = hanoverDetails;

  return (
    <div>
      <Title icon={HanoverIcon} titleText={'Hanover AI'} />
      {fetching && <HanoverSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <HanoverError />}
    </div>
  );
}
