import { useState, useEffect } from 'react';

const path = '/api/user/donnaNoCacheUserDetails';

function usePartnerDetails(toRefresh, clearToRefresh) {
  const [userDetails, setUserDetails] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getUserDetails = async () => {
      setUserDetails({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(path);
        const responseObject = await response.json();
        const { error, errorMessage, data } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setUserDetails({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (error) {
        setUserDetails({ fetching: false, error: true, success: false });
      }
    };
    if (toRefresh) {
      clearToRefresh();
      getUserDetails();
    }
  }, [toRefresh, clearToRefresh]);
  return userDetails;
}

export { usePartnerDetails };
