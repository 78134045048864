import React, { useState } from 'react';
import { MyListContext } from './MyListContext';
import useAllMyListCustomers from '../Diem/useAllMyListCustomers';

export function MyListContextProvider(props) {
  const [load, setLoad] = useState(true);

  const reload = () => {
    setLoad(true);
  };

  const stopLoad = () => {
    setLoad(false);
  };

  const { fetching, success, error, data } = useAllMyListCustomers(
    'myList',
    load,
    stopLoad
  );

  let myListMap = {};
  if (success) {
    data.forEach((customer) => {
      const { Main: customerId } = customer;
      myListMap[customerId] = true;
    });
  }

  const isCustomerInMyList = (customerId) => {
    if (myListMap[customerId]) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MyListContext.Provider
      value={{ fetching, success, error, data, reload, isCustomerInMyList }}
    >
      {props.children}
    </MyListContext.Provider>
  );
}
