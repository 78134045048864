import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { CustomerCount } from './CustomerCount';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'sticky',
    zIndex: 1099,
    backgroundColor: '#EDF2F5',
    [theme.breakpoints.down('md')]: {
      top: 147,
    },
    [theme.breakpoints.up('md')]: {
      top: 80,
    },
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  iconGrid: {
    alignSelf: 'center',
    marginTop: 3,
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#4A4A4A',
    paddingTop: theme.spacing(1),
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
  searchLabel: {
    color: '#4A4A4A',
    opacity: '0.5',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
    top: '-10px',
  },
  search: {
    height: 35,
    minWidth: '300px',
    border: '1px solid #E5ECF0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    background: '#FFFFFF',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
  refreshGrid: {
    display: 'flex',
  },
  refreshParent: {
    background: '#FFFFFF',
    opacity: '0.5',
    borderRadius: '5px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
  },
  refresh: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: '#4A4A4A',
  },
  searchIcon: {
    color: '#195880',
  },
  right: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 0,
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '100%',
  },
}));

export function TitleHome(props) {
  const classes = useStyles();
  const { titleText, onSearch, searchValue, right, backPath } = props;
  const Icon = props.icon;
  const initialSearchValue = typeof searchValue === 'string' ? searchValue : '';
  const [search, setSearch] = useState(initialSearchValue);
  const handleSearchFieldChange = (event) => {
    setSearch(event.target.value);
  };
  const handleSearchConfirm = () => {
    onSearch(search);
  };
  const back = backPath ? backPath : '/home';

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Tooltip title='Back'>
          <Grid item className={classes.iconGrid}>
            <Link to={back}>
              {Icon && <Icon className={classes.icon}></Icon>}
            </Link>
          </Grid>
        </Tooltip>
        <Grid item>
          <Typography
            className={classes.titleText}
            variant='subtitle1'
            gutterBottom
          >
            {titleText}
          </Typography>
        </Grid>
        <Grid item>
          {searchValue === undefined && (
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant='outlined'
            >
              <InputLabel htmlFor='search' className={classes.searchLabel}>
                Search
              </InputLabel>
              <OutlinedInput
                id='search'
                type='text'
                value={search}
                onChange={handleSearchFieldChange}
                className={classes.search}
                InputLabelProps={{
                  shrink: false,
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearchConfirm();
                  }
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={handleSearchConfirm}>
                      <SearchIcon className={classes.searchIcon} />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item className={classes.right}>
          <CustomerCount />
          {right !== undefined && <>{right}</>}
        </Grid>
      </Grid>
      <Divider orientation='horizontal'></Divider>
    </div>
  );
}

TitleHome.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  right: PropTypes.element,
};
