import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { BillingAutomation } from './../BillingAutomation';

export function BillingAutomationSettings() {
  return (
    <Switch>
      <Route>
        <BillingAutomation guide={false} />
      </Route>
    </Switch>
  );
}