import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import BillingPaymentDetails from './BillingAndPayments';
import PlansDetails from './Plans';
import PlansDetailsSuccess from './PlansSuccess';
import PlansDetailsFailure from './PlansFailure';
import PaymentHistoryDetails from './PaymentHistory';
import ManageBilling from './ManageBilling';
import BillingHome from './BillingHome';
import CancelSubscription from './CancelSubscription';
import RenewSubscription from './RenewSubscription';

export function BillingAutomation() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path}>
        <BillingHome parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/failure`}>
        <PlansDetailsFailure parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/plans/failure`}>
        <PlansDetailsFailure parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/success`}>
        <PlansDetailsSuccess parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/plans/success`}>
        <PlansDetailsSuccess parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/plans`}>
        <PlansDetails parentPath={match.path} backPath="/home" />
      </Route>
      <Route path={`${match.path}/payments/:id`}>
        <BillingPaymentDetails parentPath={match.path} />
      </Route>
      <Route path={`${match.path}/paymentHistory`}>
        <PaymentHistoryDetails parentPath={match.path} />
      </Route>
      <Route path={`${match.path}/manage`}>
        <ManageBilling parentPath={match.path} />
      </Route>
      <Route path={`${match.path}/cancel`}>
        <CancelSubscription parentPath={match.path} />
      </Route>
      <Route path={`${match.path}/renew`}>
        <RenewSubscription parentPath={match.path} />
      </Route>
    </Switch>
  );
}
