import React, { useEffect, useContext } from 'react';
import { UserContext } from './../UserContext';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { gaEvent } from './gaEvent';

const setView = (pathname) => {
  let pathToSet = pathname;
  if (pathname.indexOf('/oneview/') >= 1) {
    const [partialPathnameWithoutCustomerId, customerData] =
      pathname.split('/oneview/');
    const [customerId, selectedView] = customerData.split('/');
    pathToSet = partialPathnameWithoutCustomerId + '/oneview';

    const category = 'OneView';
    const action = `From ${partialPathnameWithoutCustomerId}`;
    let label;
    selectedView
      ? (label = selectedView + '/' + customerId)
      : (label = customerId);
    gaEvent(category, action, label);
  }

  if (
    pathname.indexOf('/quickview/') >= 1 &&
    pathname.indexOf('/insights') === -1
  ) {
    const [partialPathnameWithoutCustomerId, customerId] =
      pathname.split('/quickview/');
    pathToSet = partialPathnameWithoutCustomerId + '/quickview';

    const category = 'QuickView';
    const action = `From ${partialPathnameWithoutCustomerId}`;
    const label = customerId;
    gaEvent(category, action, label);
  }
  ReactGA.set({ page: pathToSet });
  ReactGA.send({ hitType: "pageview", page: pathToSet, title: pathToSet });
};

const initReactGA = (trackingId, user) => {
  const debug = process.env.NODE_ENV !== 'production';
  const userId = `${user.getTenant()}-${user.getTenantLevelUserId()}`;
  ReactGA.initialize([
    {
      trackingId: trackingId,
      gaOptions: {
        testMode: debug,
        debug: debug,
        userId: userId,
        user_id: userId
      },
      gtagOptions: {
        userId: userId,
        user_id: userId
      }
    }
  ]);
  ReactGA.gtag('set', 'config', {
    userId: userId,
    user_id: userId
  });
  ReactGA.gtag('set', 'user_properties', {
    userId: userId,
    user_id: userId,
    user_id_alias: userId,
    tenant: user.getTenant(),
    tenant_level_user_id: user.getTenantLevelUserId(),
    top_role: user.getTopRole(),
  });
  setView(window.location.pathname);
};

export function GoogleAnalytics(props) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  useEffect(() => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (trackingId) {
      initReactGA(trackingId, user);
      history.listen((location) => {
        setView(location.pathname);
      });
    }
  }, [history, user]);
  return <>{props.children}</>;
}
