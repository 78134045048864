import React from 'react';
import PropTypes from 'prop-types';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';
import { TeamsIcon } from '../Header/Icons';
import { Title } from '../Header';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function TeamAnalytics(props) {
  const { type, subType } = props;
  const analyticsApiPath = `/api/metrics/v1/${type}/${subType}`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;

  return (
    <div>
      <Title icon={TeamsIcon} titleText={'Team'} />
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <AnalyticsError />}
    </div>
  );
}

TeamAnalytics.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};
