import React from 'react';
import { ThemeWrapper } from './components/ThemeWrapper';
import { BrowserRouter as Router } from 'react-router-dom';
import { Viewport } from './components/Viewport';

function App() {
  return (
    <ThemeWrapper>
      <Router>
        <Viewport />
      </Router>
    </ThemeWrapper>
  );
}

export default App;
