import React, { useState, useEffect } from 'react';
import { TableauFilterWidget } from '../FilterWidget/FilterWidget';
import useSaveFilters from './useSaveFilters';
import useGetFilters from './useGetFilters';
import { makeStyles } from '@material-ui/core/styles';
import { isDataFilterAllowedForRole } from '@aureus/donna-roles';
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ActionList from './ActionList';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { CustomizeDataAccessDialog } from './CustomizeDataAccessDialog';
import { Typography } from '@material-ui/core';

const FILTER_MODE_KEY = 'FILTER';
const AMS_MODE_KEY = 'AMS';

const useStyles = makeStyles((theme) => ({
  alignProgress: {
    textAlign: 'center',
  },
  manage: {
    color: '#4C94EC',
    cursor: 'pointer',
    fontWeight: '600',
    fontSize: '12px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
}));

export function UserPermissions(props) {
  const classes = useStyles();
  const { rowData } = props;
  const onlyOn = !rowData.userInitials;
  const [checked, setChecked] = useState(onlyOn);
  const [toSaveFilter, setToSaveFilters] = useState(false);
  const [toGetFilter, setToGetFilters] = useState(true);
  const [filtersInfo, setFiltersInfo] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [enabledSaveFilter, setEnabledSaveFilter] = useState(false);
  const [openGetFilterError, setOpenGetFilterError] = useState(true);
  const [openCustomizePopup, setOpenCustomizePopup] = useState(false);
  const [infoChange, setInfoChange] = useState(false);

  const toggleChecked = (tocheck) => {
    if (tocheck) {
      setMode(AMS_MODE_KEY);
      saveFilters();
    } else {
      setMode(FILTER_MODE_KEY);
      setOpenCustomizePopup(true);
    }
  };

  const onFilter = (filter) => {
    setFiltersInfo(filter);
    setEnabledSaveFilter(true);
    setInfoChange(true);
  };

  const clearToSaveFilter = () => {
    setToSaveFilters(false);
  };
  const clearToGetFilter = () => {
    setToGetFilters(false);
  };
  const handleClose = () => {
    setSnackbarOpen(false);
  };
  const handleCloseGetFilters = () => {
    setOpenGetFilterError(false);
  };
  const saveFilters = async () => {
    setSnackbarOpen(true);
    setToSaveFilters(true);
    setOpenCustomizePopup(false);
    setInfoChange(false);
  };

  let filterResponse = useGetFilters(
    rowData.userID,
    toGetFilter,
    clearToGetFilter,
    onlyOn
  );
  let filterSaveResponse = useSaveFilters(
    rowData.userID,
    toSaveFilter,
    clearToSaveFilter,
    filtersInfo,
    mode
  );

  useEffect(() => {
    if (filterResponse && filterResponse.data && filterResponse.data.data) {
      if (filterResponse.data.data.amsMode === FILTER_MODE_KEY) {
        setChecked(true);
      } else if (filterResponse.data.data.amsMode === AMS_MODE_KEY) {
        setChecked(false);
      }
      setMode(filterResponse.data.data.amsMode);
    }
  }, [filterResponse]);

  useEffect(() => {
    if (filterSaveResponse && filterSaveResponse.success) {
      setEnabledSaveFilter(false);
      setToGetFilters(true);
    }
  }, [filterSaveResponse]);

  return (
    <>
      {!isDataFilterAllowedForRole(rowData.userType) && (
        <Tooltip title="Custom data access is not available for this role">
          <div>
            <Switch checked={false} disabled={true} />
          </div>
        </Tooltip>
      )}

      {isDataFilterAllowedForRole(rowData.userType) && (
        <>
          {filterSaveResponse && filterSaveResponse.fetching && (
            <div className={classes.alignProgress}>
              <Skeleton height={50} />
            </div>
          )}
          {filterResponse &&
            filterResponse.success &&
            !filterSaveResponse.fetching && (
              <>
                <ActionList
                  checked={checked}
                  toggleChecked={toggleChecked}
                  saveFilters={saveFilters}
                  enabledSaveFilter={enabledSaveFilter}
                  onlyOn={onlyOn}
                  isDisable={
                    isDataFilterAllowedForRole(rowData.userType) &&
                    rowData.hasOwnProperty('userInitials')
                  }
                />
                {checked && (
                  <Button
                    onClick={() => {
                      setOpenCustomizePopup(true);
                      setMode(AMS_MODE_KEY);
                    }}
                  >
                    <Typography className={classes.manage}>
                      Manage Access
                    </Typography>
                  </Button>
                )}
              </>
            )}
          {filterResponse && filterResponse.fetching && (
            <div className={classes.alignProgress}>
              <Skeleton height={50} />
            </div>
          )}
          <CustomizeDataAccessDialog
            onClose={() => {
              setOpenCustomizePopup(false);
              setInfoChange(false);
            }}
            open={openCustomizePopup}
            saveFilters={saveFilters}
            setMode={setMode}
            FILTER_MODE_KEY={FILTER_MODE_KEY}
            AMS_MODE_KEY={AMS_MODE_KEY}
            mode={mode}
            infoChange={infoChange}
            onlyOn={onlyOn}
          >
            <TableauFilterWidget onFilter={onFilter} userId={rowData.userID} />
          </CustomizeDataAccessDialog>

          <Snackbar
            open={
              filterSaveResponse && snackbarOpen && filterSaveResponse.error
            }
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity="error">Could not save filters</Alert>
          </Snackbar>
          <Snackbar
            open={
              snackbarOpen && filterSaveResponse && filterSaveResponse.success
            }
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity="success">Filters saved</Alert>
          </Snackbar>
          <Snackbar
            open={filterResponse && openGetFilterError && filterResponse.error}
            autoHideDuration={5000}
            onClose={handleCloseGetFilters}
          >
            <Alert severity="error">Could not fetch AMS details</Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
UserPermissions.prototypes = {
  rowData: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
  }),
};
