import React from 'react';
import PropTypes from 'prop-types';
import { Route, useRouteMatch } from 'react-router-dom';
import { DashboardSkeleton } from './DashboardSkeleton';
import { DashboardError } from './DashboardError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { OneView } from './../OneView';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function Dashboard(props) {
  const { type } = props;
  const match = useRouteMatch();
  const dashboardApiPath = `/api/metrics/v1/${type}`;
  const dashboardDetails = useViewDetails(dashboardApiPath);
  const { fetching, success, error, data } = dashboardDetails;
  return (
    <>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      {fetching && <DashboardSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <DashboardError />}
    </>
  );
}

Dashboard.propTypes = {
  type: PropTypes.string.isRequired,
};
