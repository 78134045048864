import React from 'react';
import { useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { JiraTabs } from './JiraTabs';
import { UserContext } from './../UserContext';
import Avatar from '@material-ui/core/Avatar';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.headerContent}>
        <Avatar className={classes.avatar}>
          <ContactSupportIcon />
        </Avatar>
        <div>
          <Typography variant="h6">{children}</Typography>
          <Typography variant="subtitle1">How can we help you?</Typography>
        </div>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '100vw',
    minHeight: '100vh',
  },
  paper: {
    flexGrow: '1',
  },
  content: {
    maxHeight: '10000vh',
    paddingTop: theme.spacing(4),
  },
  floatLeftTop: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(4),
    zIndex: theme.zIndex.appBar,
    color: theme.palette.primary.contrastText,
  },
  minHeight: {
    minHeight: '80vh',
  },
}));

export function JiraDialog(props) {
  const { user } = useContext(UserContext);
  const firstName = user.getFirstName();
  const classes = useStyles();
  const { open, handleClose } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle onClose={handleClose}>{`Hi ${firstName}!`}</DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <div className={classes.minHeight}>
            <JiraTabs />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

JiraDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
