import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from './../UserContext';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import { StyledPaper } from './../Styled/StyledPaper';
import { CompleteProfileConfirmation } from './CompleteProfileConfirmation';
import { CompleteProfileSameRoleConfirmation } from './CompleteProfileSameRoleConfirmation';
import Typography from '@material-ui/core/Typography';
import { RoleSelection } from './RoleSelection';
import { ChangeUserTypeForm, UserDetailsForm } from './FormElements';
import { getLabelForRole } from '@aureus/donna-roles';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  formInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
  hidden: {
    display: 'none',
  },
  nestedL1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nestedL2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export function CompleteProfileForm(props) {
  const classes = useStyles();
  const {
    linkUser,
    completeProfile,
    locations,
    users,
    guide,
    allowReportsTo,
    loading,
  } = props;
  const { user } = useContext(UserContext);
  const name = user.getFullName();
  const email = user.getEmail();
  const currentRole = user.getTopRole();
  const hasCeo = users.find((user) => user.userType === 'ceo') !== undefined;
  let defaultRoleOption = {
    value: '',
    label: '',
  };
  const [roleValue, setRoleValue] = useState(defaultRoleOption.value);
  const noRoleSetMode = roleValue === defaultRoleOption.value;
  const sameRoleMode = roleValue === currentRole;
  const changeRoleMode = !noRoleSetMode && roleValue !== currentRole;
  const [linkConfirmationMessage, setLinkConfirmationMessage] = useState({
    visible: false,
    userData: {},
  });
  const [
    completeProfileSameRoleConfirmationMessage,
    setCompleteProfileSameRoleConfirmationMessage,
  ] = useState({
    visible: false,
    userData: {},
  });
  const {
    visible: linkConfirmationMessageVisible,
    userData: linkConfirmationUserData,
  } = linkConfirmationMessage;
  const {
    visible: completeProfileSameRoleMessageVisible,
    userData: completeProfileSameRoleUserData,
  } = completeProfileSameRoleConfirmationMessage;
  const showConfirmation = (userData) => {
    setLinkConfirmationMessage({ visible: true, userData });
  };
  const removeConfirmationMessage = () => {
    setLinkConfirmationMessage({ visible: false, userData: {} });
  };
  const showSameRoleConfirmation = (userData) => {
    setCompleteProfileSameRoleConfirmationMessage({
      visible: true,
      userData: {
        ...userData,
        userType: currentRole,
      },
    });
  };
  const removeSameRoleConfirmation = () => {
    setCompleteProfileSameRoleConfirmationMessage({
      visible: false,
      userData: {},
    });
  };
  const confirm = (userData) => {
    showConfirmation(userData);
  };
  const confirmDetails = (userData) => {
    showSameRoleConfirmation(userData);
  };
  const noRoleSelection = true;
  const submitLabel = guide ? 'Next' : 'Set Profile';
  return (
    <>
      {linkConfirmationMessageVisible && (
        <CompleteProfileConfirmation
          open={linkConfirmationMessageVisible}
          userData={linkConfirmationUserData}
          onClose={removeConfirmationMessage}
          onConfirm={linkUser}
          users={users}
          locations={locations}
          hideReportsTo={noRoleSelection}
          hideRole={noRoleSelection}
        />
      )}
      {completeProfileSameRoleMessageVisible && (
        <CompleteProfileSameRoleConfirmation
          open={completeProfileSameRoleMessageVisible}
          userData={completeProfileSameRoleUserData}
          onClose={removeSameRoleConfirmation}
          onConfirm={completeProfile}
          locations={locations}
        />
      )}
      <div
        className={clsx({
          [classes.hidden]: loading,
        })}
      >
        <Card component={StyledPaper}>
          <div className={classes.padding}>
            <CardHeader
              avatar={
                <Avatar aria-label="user" className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              }
              title={name}
              titleTypographyProps={{ variant: 'h5' }}
              subheader={email}
              subheaderTypographyProps={{ variant: 'subtitle1' }}
            />
            <CardContent>
              <div className={classes.nestedL1}>
                <RoleSelection
                  value={roleValue}
                  setValue={setRoleValue}
                  hasCeo={hasCeo}
                />
              </div>
              <Typography
                variant="subtitle2"
                className={clsx({
                  [classes.formInput]: true,
                  [classes.hidden]: noRoleSetMode,
                })}
              >{`Let us know a few details about your ${getLabelForRole(
                roleValue
              )} account`}</Typography>
              <div className={classes.nestedL2}>
                <ChangeUserTypeForm
                  confirm={confirm}
                  allowReportsTo={allowReportsTo}
                  locations={locations}
                  submitLabel={submitLabel}
                  users={users}
                  hidden={sameRoleMode || noRoleSetMode}
                />
                <UserDetailsForm
                  confirm={confirmDetails}
                  locations={locations}
                  submitLabel={submitLabel}
                  hidden={changeRoleMode || noRoleSetMode}
                />
              </div>
            </CardContent>
            {guide &&
              React.cloneElement(props.children, {
                changeRoleMode,
                noRoleSetMode,
              })}
          </div>
        </Card>
      </div>
    </>
  );
}

CompleteProfileForm.propTypes = {
  linkUser: PropTypes.func.isRequired,
  completeProfile: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
    })
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      locationID: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  guide: PropTypes.bool.isRequired,
  allowReportsTo: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
