import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';

function OneViewError(props) {
  const { customerId } = props;
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {'Cannot get OneView for Customer ID "' + customerId + '"'}
    </Alert>
  );
}

OneViewError.propTypes = {
  customerId: PropTypes.string.isRequired
};

export { OneViewError };
