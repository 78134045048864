import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NoCustomersIcon from '../../assets/NoCustomersIcon.svg';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { contextProperties } from './ContextProperties';

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    background: '#FFFFFF',
    height: '150px',
  },
  iconDiv: {
    width: '50px',
    height: '50px',
    margin: 'auto',
    backgroundColor: 'rgb(213, 223, 232)',
    display: 'flex',
    color: 'rgb(69, 105, 132)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginBottom: 5,
  },
  empty_icon: {
    fontSize: 22,
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4A4A4A',
  },
}));

const NoCustomers = (props) => {
  const classes = useStyles();
  const { context } = props;

  const list = contextProperties[context].title;

  return (
    <div className={classes.parentDiv}>
      <div className={classes.iconDiv}>
        <img src={NoCustomersIcon} alt="No Customers" />
      </div>
      <Typography align="center" className={classes.text}>
        There are no customers in ‘{list}’
      </Typography>
    </div>
  );
};

NoCustomers.propTypes = {
  list: PropTypes.string.isRequired,
};

export { NoCustomers };
