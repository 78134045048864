export const actionTypes = {
  SET_DETAILS: 'setDetails',
  ACTIVATE: 'activate',
  LINK: 'link',
  ENABLE: 'enable',
  DISABLE: 'disable',
  ACTIVATION_EMAIL: 'activationEmail',
  COMPLETE_PROFILE: 'completeProfile',
  UPDATE_DETAILS: 'updateDetails',
  NEW_USER: 'createNewUser',
  ADD_AND_ACTIVATE_USER: 'createAndActivateNewUser',
  UPDATE_AND_ACTIVATE_USER: 'updateAndActivateNewUser',
  SET_DETAILS_AND_ACTIVATE: 'setDetailsAndActivate',
};

export const actionTypeFailureMessages = {
  [actionTypes.SET_DETAILS]: 'Failed to update user details',
  [actionTypes.ACTIVATE]: 'Failed to activate user',
  [actionTypes.LINK]: 'Failed to set up user profile',
  [actionTypes.ENABLE]: 'Failed to enable user',
  [actionTypes.DISABLE]: 'Failed to disable user',
  [actionTypes.ACTIVATION_EMAIL]: 'Failed to send activation email',
  [actionTypes.COMPLETE_PROFILE]: 'Failed to update profile details',
  [actionTypes.UPDATE_DETAILS]: 'Failed to update user details',
  [actionTypes.ADD_AND_ACTIVATE_USER]: 'Failed to create/activate user',
  [actionTypes.UPDATE_AND_ACTIVATE_USER]: 'Failed to update/activate user',
  [actionTypes.SET_DETAILS_AND_ACTIVATE]: 'Failed to update/activate user',
};

export const actionTypeSuccessMessages = {
  [actionTypes.SET_DETAILS]: 'User updated.',
  [actionTypes.ACTIVATE]: 'User activated. An activation email has been sent.',
  [actionTypes.LINK]: 'User profile created.',
  [actionTypes.ENABLE]: 'User enabled.',
  [actionTypes.DISABLE]: 'User disabled.',
  [actionTypes.ACTIVATION_EMAIL]: 'An activation email has been sent.',
  [actionTypes.COMPLETE_PROFILE]: 'User profile updated.',
  [actionTypes.UPDATE_DETAILS]: 'User updated.',
  [actionTypes.NEW_USER]: 'User added.',
  [actionTypes.UPDATE_AND_ACTIVATE_USER]: 'User updated. An activation email has been sent.',
  [actionTypes.ADD_AND_ACTIVATE_USER]: 'User added. An activation email has been sent.',
  [actionTypes.SET_DETAILS_AND_ACTIVATE]: 'User updated. An activation email has been sent.',
};