import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  fab: {
    boxShadow: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function RoundedButton(props) {
  const classes = useStyles();
  const { label, size, onClick, isSubmit } = props;
  return (
    <>
      {!isSubmit && (
        <Fab
          className={classes.fab}
          variant="extended"
          color="primary"
          size={size}
          onClick={onClick}
        >
          {label}
        </Fab>
      )}
      {isSubmit && (
        <Fab
          className={classes.fab}
          variant="extended"
          color="primary"
          size={size}
          type="submit"
        >
          {label}
        </Fab>
      )}
    </>
  );
}

RoundedButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSubmit: PropTypes.bool,
};
