import React, { useCallback, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, debounce } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getAssignableRoleLabelMapping } from '@aureus/donna-roles';
import { getReportsToMapping, getLocationMapping } from './columns';
import {
  validateName,
  validateEmail,
  validateDesignation,
  validateUserType,
  validateReportsTo,
  validateLocation,
} from './validate';
import useValidateEmailID from './useValidateEmailID';

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 436,
    minHeight: 300,
  },
  helperText: {
    height: 59,
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  titleRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 0,
    background: '#CBD5DD',
    '& h2': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 'initial',
    color: '#4A4A4A',
  },
  saveChangesBtn: {
    background: '#2D6089',
    borderRadius: 4,
    '&:hover': {
      background: '#2D6089',
    },
  },
  disableBtn: {
    background: 'rgba(0, 0, 0, 0.26)',
  },
  saveLabel: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
  closeBtn: {
    background: '#d5dfe7',
    borderRadius: 4,
    '&:hover': {
      background: '#d5dfe7',
    },
  },
  closeBtnLabel: {
    color: '#2D6089',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
  textField: {
    height: 25,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  row: {
    display: 'flex',
  },
  fullWidthBox: {
    width: '100%',
  },
  select: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
  },
  selectRoot: {
    width: '100%',
  },
  required: {
    color: '#f44336',
  },
  errorMsg: {
    height: 19,
    color: '#f44336',
    marginTop: 0,
  },
  emailSuccessMsg: {
    color: 'green',
  },
  emailErrorMsg: {
    color: 'red',
  },
}));

const CEO_ROLE_KEY = 'ceo';

export function AddEditUserPopup(props) {
  const {
    open,
    onClose,
    users,
    locations,
    title,
    rowData,
    onRowAdd,
    onRowUpdate,
    isEdit,
  } = props;
  const classes = useStyles();
  const roles = getAssignableRoleLabelMapping();
  const reports = getReportsToMapping(users);
  const locationArr = getLocationMapping(locations);

  const emailValidationEndpoint = '/api/admin/v1/user/validateEmailID';

  const [disableButton, setDisableButton] = useState(true);

  const [locationVal, setLocation] = useState(
    rowData && rowData.location && isEdit ? rowData.location : ''
  );
  const [locationError, setLocationError] = useState(false);
  const [reportVal, setReport] = useState(
    rowData && rowData.reportsTo && isEdit ? 'U-' + rowData.reportsTo : 'U-0'
  );
  const [reportError, setReportError] = useState(false);
  const [roleVal, setRole] = useState(
    rowData && rowData.userType && isEdit ? rowData.userType : ''
  );
  const [roleError, setRoleError] = useState(false);
  const [firstName, setFirstName] = useState(
    rowData && rowData.firstName && isEdit ? rowData.firstName : ''
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState(
    rowData && rowData.lastName && isEdit ? rowData.lastName : ''
  );
  const [lastNameError, setLastNameError] = useState(false);
  const [emailID, setEmailID] = useState(
    rowData && rowData.emailID && isEdit ? rowData.emailID : ''
  );
  const [emailError, setEmailError] = useState(false);
  const [designation, setDesignation] = useState(
    rowData && rowData.designation && isEdit ? rowData.designation : ''
  );
  const [designationError, setDesignationError] = useState(false);
  const [emailToBeValidated, setEmailToBeValidated] = useState('');

  // used to display the validation tick 
  const [emailIDSuccess, setEmailIDSuccess] = useState(false);

  // flag to start/stop emailID validation
  const [emailValidation, setEmailValidation] = useState(false);

  const startEmailValidation = () => setEmailValidation(true);
  const stopEmailValidation = () => setEmailValidation(false);

  const validate = (value, validateField, setFieldError, setField, field) => {
    const validationObj = validateField(value, field);
    setFieldError(validationObj);
    setField(value);
  };

  const {
    fetching: emailValidationFetching,
    success: emailValidationSuccess,
    error: emailValidationError,
    data: emailValidationData,
  } = useValidateEmailID(
    emailValidationEndpoint,
    emailValidation,
    stopEmailValidation,
    emailToBeValidated
  );

  const debouncedValidation = useCallback(
    debounce(startEmailValidation, 3000),
    []
  );

  // emailValidation tick on opening update form
  useEffect(() => {
    if (isEdit && rowData && rowData.emailID) {
      setEmailIDSuccess(true);
    }
  }, []);

  useEffect(() => {
    if (emailValidationFetching) {
      setEmailIDSuccess(false);
      setEmailError({ error: false, errorMessages: [] });
    }
    if (emailValidationSuccess && emailValidationData) {
      const { code, message } = emailValidationData ? emailValidationData : {};
      if (code === 'success') {
        setEmailIDSuccess(true);
        setEmailError({ error: false, errorMessages: [] });
      }
      if (code === 'error') {
        setEmailIDSuccess(false);
        setEmailError({ error: true, errorMessages: [message] });
      }
    }
    if (emailValidationError) {
      setEmailIDSuccess(false);
      setEmailError({ error: true, errorMessages: ['Something went wrong! Please try again after sometime'] });
    }
  }, [
    emailValidationFetching,
    emailValidationSuccess,
    emailValidationError,
    emailValidationData,
  ]);

  const validateEmailID = (
    value,
    validateField,
    setFieldError,
    setField,
    field
  ) => {
    setDisableButton(true);
    const validationObj = validateField(value, field);
    setEmailIDSuccess(false);
    setFieldError(validationObj);
    setField(value);
    if (rowData && rowData.emailID && value === rowData.emailID) {
      // in case of updating details, the user reverts to existing value 
      setEmailIDSuccess(true);
    } else {
      // validate user emailID
      setEmailToBeValidated(value);
      if (value && !validationObj.error) {
        debouncedValidation();
      }
    }
  };

  const blankAllFields = () => {
    setFirstName('');
    setLastName('');
    setEmailID('');
    setRole('');
    setDesignation('');
    setReport('');
    setLocation('');
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setRoleError(false);
    setDesignationError(false);
    setReportError(false);
    setLocationError(false);
  };

  const validateForm = () => {
    if (firstName === '') {
      setFirstNameError({
        error: true,
        errorMessages: ['First Name is necessary'],
      });
    }
    if (lastName === '') {
      setLastNameError({
        error: true,
        errorMessages: ['Last Name is necessary'],
      });
    }
    if (emailID === '') {
      setEmailError({
        error: true,
        errorMessages: ['Email ID is necessary'],
      });
    }
    if (designation === '') {
      setDesignationError({
        error: true,
        errorMessages: ['Designation is necessary'],
      });
    }
    if (roleVal === '') {
      setRoleError({
        error: true,
        errorMessages: ['Role is necessary'],
      });
    }
    if (locationVal === '') {
      setLocationError({
        error: true,
        errorMessages: ['Location is necessary'],
      });
    }
  };

  const doesEmptyFieldExist = () => {
    if (
      !firstName ||
      !lastName ||
      !emailID ||
      !roleVal ||
      !designation ||
      !locationVal
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!emailID && emailIDSuccess) {
      // in case window is closed & opened and form state is not retained
      setEmailIDSuccess(false);
    } else if (
      firstName &&
      lastName &&
      roleVal &&
      designation &&
      locationVal &&
      emailID &&
      emailIDSuccess
    ) {
      // to enable Next button only if required fields are filled and emailID validated
      setEmailIDSuccess(true);
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    firstName,
    lastName,
    roleVal,
    designation,
    locationVal,
    emailID,
    emailIDSuccess,
  ]);

  const addUser = () => {
    const areFieldsEmpty = doesEmptyFieldExist();
    if (areFieldsEmpty) {
      // to validate form for any empty field in list of mandatory fields
      validateForm();
    } else {
      let obj = {
        firstName,
        lastName,
        emailID,
        userType: roleVal,
        designation,
        reportsTo: reportVal,
        location: locationVal,
      };
      let oldData = {};
      validate(
        firstName,
        validateName,
        setFirstNameError,
        setFirstName,
        'First Name'
      );
      validate(
        lastName,
        validateName,
        setLastNameError,
        setLastName,
        'Last Name'
      );
      validate(emailID, validateEmail, setEmailError, setEmailID, 'EmailID');
      validate(roleVal, validateUserType, setRoleError, setRole, 'Role');
      validate(
        designation,
        validateDesignation,
        setDesignationError,
        setDesignation,
        'Designation'
      );
      validate(
        reportVal,
        validateReportsTo,
        setReportError,
        setReport,
        'Report'
      );
      validate(
        locationVal,
        validateLocation,
        setLocationError,
        setLocation,
        'Location'
      );
      const isError =
        firstNameError.error ||
        lastNameError.error ||
        emailError.error ||
        designationError.error ||
        reportError.error ||
        locationError.error ||
        roleError.error;

      if (isEdit) {
        oldData = {
          firstName: rowData.firstName,
          lastName: rowData.lastName,
          designation: rowData.designation,
          emailID: rowData.emailID,
          reportsTo: rowData.reportsTo,
          location: rowData.location,
          userType: rowData.userType,
          graviteeProfileActivated: rowData.graviteeProfileActivated,
          graviteeProfileCreated: rowData.graviteeProfileCreated,
          graviteeProfileRegistrationCompleted:
            rowData.graviteeProfileRegistrationCompleted,
          graviteeProfileUserInviteSentCount:
            rowData.graviteeProfileUserInviteSentCount,
          hasBasicDetails: rowData.hasBasicDetails,
          isActivated: rowData.isActivated,
          userID: rowData.userID,
        };

        let reportToVal = reportVal;
        if (reportToVal.includes('U-')) {
          reportToVal = reportVal.substring(2);
        }

        obj = {
          firstName,
          lastName,
          emailID,
          userType: roleVal,
          designation,
          reportsTo: reportToVal,
          location: locationVal,
          graviteeProfileActivated: rowData.graviteeProfileActivated,
          graviteeProfileCreated: rowData.graviteeProfileCreated,
          graviteeProfileRegistrationCompleted:
            rowData.graviteeProfileRegistrationCompleted,
          graviteeProfileUserInviteSentCount:
            rowData.graviteeProfileUserInviteSentCount,
          hasBasicDetails: rowData.hasBasicDetails,
          isActivated: rowData.isActivated,
          userID: rowData.userID,
        };
        if (rowData.userInitials) {
          oldData.userInitials = rowData.userInitials;
          obj.userInitials = rowData.userInitials;
        }
      }
      if (!isError) {
        if (isEdit) {
          onRowUpdate(obj, oldData);
        } else {
          onRowAdd(obj);
          blankAllFields();
        }
      }
    }
  };

  const closePopup = () => {
    setDisableButton(true);
    setEmailIDSuccess(false);
    if (!rowData) {
      setFirstName('');
      setLastName('');
      setEmailID('');
      setRole('');
      setDesignation('');
      setReport('');
      setLocation('');
    }
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setRoleError(false);
    setDesignationError(false);
    setReportError(false);
    setLocationError(false);
    onClose();
  };
  const hasCeo = (users) => {
    return users.find((user) => user.userType === CEO_ROLE_KEY) !== undefined;
  };
  const disabled = roleVal === CEO_ROLE_KEY;
  return (
    <Dialog open={open}>
      <DialogTitle classes={{ root: classes.titleRoot, h6: classes.titleFlex }}>
        <Typography className={classes.dialogTitle}>{title}</Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          aria-label="close"
          onClick={closePopup}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <div>
          <div className={classes.row}>
            <div>
              <Typography>
                First Name <span className={classes.required}>*</span>
              </Typography>
              <TextField
                required
                variant="outlined"
                id="outlined-required"
                placeholder="Enter First Name"
                size="small"
                value={firstName}
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateName,
                    setFirstNameError,
                    setFirstName,
                    'First Name'
                  );
                }}
                className={classes.helperText}
                error={firstNameError.error}
                helperText={firstNameError.errorMessages}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Typography>
                Last Name <span className={classes.required}>*</span>
              </Typography>
              <TextField
                required
                variant="outlined"
                id="outlined-required"
                placeholder="Enter Last Name"
                size="small"
                value={lastName}
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateName,
                    setLastNameError,
                    setLastName,
                    'Last Name'
                  );
                }}
                className={classes.helperText}
                error={lastNameError.error}
                helperText={lastNameError.errorMessages}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidthBox}>
              <Typography>
                Email ID <span className={classes.required}>*</span>
              </Typography>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="outlined-required"
                placeholder="Enter EmailID"
                size="small"
                disabled={rowData ? rowData.isActivated && true : false}
                value={emailID}
                onChange={(e) => {
                  return validateEmailID(
                    e.target.value,
                    validateEmail,
                    setEmailError,
                    setEmailID,
                    'EmailID'
                  );
                }}
                className={classes.helperText}
                error={emailError.error}
                helperText={
                  emailIDSuccess ? (
                    // validated tick
                    <span style={{ color: 'gray' }}>&#x2714;</span>
                  ) : (
                    emailError.errorMessages
                  )
                }
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidthBox}>
              <Typography>
                Role <span className={classes.required}>*</span>
              </Typography>
              <Select
                id="demo-simple-select"
                value={roleVal}
                displayEmpty
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateUserType,
                    setRoleError,
                    setRole,
                    'Role'
                  );
                }}
                error={roleError.error}
                helperText={roleError.errorMessages}
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={disabled}
                classes={{ select: classes.select, root: classes.selectRoot }}
              >
                {Object.keys(roles)
                  .filter((key) => {
                    // TODO: Add role based filtering
                    if (key === CEO_ROLE_KEY && !rowData.userInitials) {
                      return false;
                    } else if (
                      key === CEO_ROLE_KEY &&
                      roleVal !== CEO_ROLE_KEY &&
                      rowData.userType !== CEO_ROLE_KEY
                    ) {
                      return !hasCeo(users);
                    } else {
                      return true;
                    }
                  })
                  .map((role) => {
                    return <MenuItem value={role}>{roles[role]}</MenuItem>;
                  })}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {roleError.error && roleError.errorMessages}
              </FormHelperText>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidthBox}>
              <Typography>
                Designation <span className={classes.required}>*</span>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-required"
                placeholder="Enter Designation"
                size="small"
                value={designation}
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateDesignation,
                    setDesignationError,
                    setDesignation,
                    'Designation'
                  );
                }}
                className={classes.helperText}
                error={designationError.error}
                helperText={designationError.errorMessages}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidthBox}>
              <Typography>Reporting To</Typography>
              <Select
                id="demo-simple-select"
                value={reportVal}
                displayEmpty
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateReportsTo,
                    setReportError,
                    setReport,
                    'Report'
                  );
                }}
                error={reportError.error}
                helperText={reportError.errorMessages}
                variant="outlined"
                size="small"
                fullWidth={true}
                classes={{ select: classes.select, root: classes.selectRoot }}
              >
                {Object.keys(reports).map((report) => {
                  const { userID } = rowData;
                  if (report !== `U-${userID}`) {
                    return (
                      <MenuItem value={report}>{reports[report]}</MenuItem>
                    );
                  }
                  return '';
                })}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {reportError.error && reportError.errorMessages}
              </FormHelperText>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidthBox}>
              <Typography>
                Location <span className={classes.required}>*</span>
              </Typography>
              <Select
                id="demo-simple-select"
                value={locationVal}
                displayEmpty
                onChange={(e) => {
                  return validate(
                    e.target.value,
                    validateLocation,
                    setLocationError,
                    setLocation,
                    'Location'
                  );
                }}
                error={locationError.error}
                helperText={locationError.errorMessages}
                variant="outlined"
                size="small"
                fullWidth={true}
                classes={{ select: classes.select, root: classes.selectRoot }}
              >
                {Object.keys(locationArr).map((location) => {
                  return (
                    <MenuItem value={location}>
                      {locationArr[location]}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {locationError.error && locationError.errorMessages}
              </FormHelperText>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          classes={{ root: classes.closeBtn, label: classes.closeBtnLabel }}
          onClick={closePopup}
        >
          close
        </Button>
        <Button
          classes={{
            root: classes.saveChangesBtn,
            label: classes.saveLabel,
            disabled: classes.disableBtn,
          }}
          onClick={() => {
            addUser();
          }}
          disabled={disableButton}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
