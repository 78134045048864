import axios from 'axios';
import { useEffect, useState } from 'react';

const getActivationDetailsEndPoint = '/api/v1/onboarding/getActivationDetails';

function useGetActivationDetails(isSetupUser) {
  const [activationDetails, setActivationDetails] = useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });

  const path = `${getActivationDetailsEndPoint}`;

  useEffect(() => {
    const getActivationDetails = async () => {
      setActivationDetails({
        fetching: true,
        success: false,
        error: false,
        data: undefined,
      });

      try {
        const { error, data } = await axios.get(path);
        const { error: responseError, data: responseData } = data;
        if (error || responseError) {
          setActivationDetails({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setActivationDetails({
            fetching: false,
            success: true,
            error: false,
            data: responseData,
          });
        }
      } catch (error) {
        setActivationDetails({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      }
    };
    if (isSetupUser) {
      getActivationDetails();
    }
  }, [path, isSetupUser]);

  return activationDetails;
}

export default useGetActivationDetails;
