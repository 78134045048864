import axios from 'axios';
import { quoteTypes } from './quoteTypes';

const path = '/api/quotes/v1/getQuoteList';

const getQuote = async (payload, type) => {
  let requestObject = {};
  let response = {};
  if (type === quoteTypes.ACTIVITY) {
    requestObject = {
      customerId: payload.customerID,
    };
  }
  if (type === quoteTypes.RELATIONSHIP) {
    requestObject = {
      customerId: payload['Customer ID'],
    };
  }
  try {
    const { status, data } = await axios.post(path, requestObject);
    if (status !== 200) {
      throw new Error('Error getting quote list');
    }
    const { error, errorMessage, data: quoteData } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    const responseObject = quoteData.find((quote) => {
      if (parseInt(quote.activityId) === payload.activityID) {
        return true;
      }
    });

    responseObject['quoteReferenceNumber'] =
      responseObject['partnerRequestReferenceId'];
    response = { error, errorMessage, data: responseObject };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default getQuote;
