import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import RecommendationTabs from './RecommendationTabs';
import RecommendationTable from './RecommendationTable';
import { useRuleBundles } from './useRuleBundles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { RecommendationChangeDialog } from './RecommendationChangeDialog';
import { useSaveBundleChanges } from './useSaveBundleChanges';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
  }, header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  saveButton: {
    color: '#4C94EC',
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: 14,
    backgroundColor: '#e1f0ff',
    lineHeight: '17px',
    padding: '7px 10px',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh'
  }
}));

export default function RecommendationSettings() {
  const classes = useStyles();
  const [fetchRuleBundles, setFetchRuleBundles] = useState(true); // This is to trigger the API call to fetch rule bundles
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Personal');
  const [changes, setChanges] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false); // This is to trigger the API call to save changes
  const onClose = () => {
    setConfirmOpen(false);
  };
  const onConfirm = () => {
    // Send API call to save changes and reload screen post that
    setSaveChanges(true);
  };
  const { fetching, error, success, data } = useRuleBundles(fetchRuleBundles, setFetchRuleBundles);
  let bundles, lastUpdate, hasPendingUpdate = false;
  if (success) {
    bundles = data.bundles;
    lastUpdate = data.lastUpdate;
    hasPendingUpdate = typeof lastUpdate.changeId === 'number' && lastUpdate.ruleRefreshPending === true;
  }
  const openSnackbar = () => {
    setChanges({}); // Reset changes
    setSnackbarOpen(true);
    setFetchRuleBundles(true); // Reload the screen
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const hasChanges = Object.keys(changes).length > 0;
  const { fetching: setting, error: settingError, success: settingSuccess, data: settingStatus } = useSaveBundleChanges(saveChanges, changes, setSaveChanges, openSnackbar);
  const saveDisabled = !hasChanges || setting || settingError;
  const hideTable = setting || settingError;
  return (
    <Box className={classes.container} component="div">
      <Box className={classes.header} component="div">
        <Typography>
          This settings allows to manage the recommendations. You can turn on or off the recommendations related to a specific policy type based on the business the agency operates.
        </Typography>
        {success && <Button variant="contained" className={classes.saveButton} disabled={saveDisabled} onClick={() => { setConfirmOpen(true) }}>Save Changes</Button>}
      </Box>
      {setting && <Box className={classes.loaderContainer}><CircularProgress /></Box>}
      {settingError && <Box className={classes.loaderContainer}><Alert severity="error"> <AlertTitle>Error</AlertTitle> {'Something Went Wrong. Could Not Change Recommendation Settings.'} </Alert></Box>}
      {settingSuccess && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbarOpen} autoHideDuration={8000} onClose={handleSnackbarClose}><Alert severity="success"> <AlertTitle>Success</AlertTitle> {'Recommendation Settings Changed Successfully. You Will Be Notified Once The Processing Is Completed.'} </Alert></Snackbar>}
      {fetching && <Box className={classes.loaderContainer}><CircularProgress /></Box>}
      {error && <Box className={classes.loaderContainer}><Alert severity="error"> <AlertTitle>Error</AlertTitle> {'Something Went Wrong'} </Alert></Box>}
      {(success && !hideTable) &&
        <>
          <RecommendationChangeDialog open={confirmOpen} onClose={onClose} onConfirm={onConfirm} changes={changes} rows={bundles} />
          {hasPendingUpdate && (
            <Box className={classes.container} component="div">
              <Alert severity="warning">
                <AlertTitle>Pending Changes</AlertTitle>
                {'Changes from your last setting update are currently in process. Changing the settings will overwrite your last changes.'}
              </Alert>
            </Box>
          )}
          <Box className={classes.container} component="div">
            <RecommendationTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <RecommendationTable rows={bundles} typeFilter={selectedTab} changes={changes} setChanges={setChanges} />
          </Box>
        </>
      }
    </Box>
  );
}