import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { MetricsIcon } from '../Header/Icons';
import { Title } from '../Header';
import { ViewSelector } from './ViewSelector';
import { TeamSelector } from './TeamSelector';
import { dashboardRedirectionMapping } from '../../Enums/dashboardWidgetMapping';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const agencyMetricsDimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '100px',
  vizWidth: '100%',
  vizHeight: '100px',
};

const PORTFOLIO_PATH = '/home/portfolio';
const ANALYTICS_PATH = '/home/analytics';
const SCOPED_ANALYTICS_PATH = '/home/team-analytics';
const PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
const ANALYTICS_CUSTOMER = '/customer/all';
const ANALYTICS_CUSTOMER_PATH = `${ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const SCOPED_ANALYTICS_CUSTOMER_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const PERSONAL_ANALYTICS_CUSTOMER_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;

export function Metrics(props) {
  const { type, subType } = props;
  const history = useHistory();
  const { user } = useContext(UserContext);

  const [typeToUse, setTypeToUse] = useState(type);
  const [teamMemberToUse, setTeamMemberToUse] = useState(-1);
  const showTypeSwitch = type === 'analytics';
  const showTeamSwitch = type === 'team-analytics';
  const [metricsLoaded, setMetricsLoaded] = useState(false);
  let left;
  let analyticsApiPath = `/api/metrics/v1/${typeToUse}/${subType}`;
  if (showTypeSwitch) {
    left = <ViewSelector value={typeToUse} setValue={setTypeToUse} />;
  }
  if (showTeamSwitch) {
    if (teamMemberToUse > -1) {
      analyticsApiPath = `/api/metrics/v1/${typeToUse}/${subType}/${teamMemberToUse}`;
    }
    left = (
      <TeamSelector
        value={teamMemberToUse}
        setValue={setTeamMemberToUse}
        page={'Metrics'}
      />
    );
  }
  const agencyAnalyticsApi = '/api/metrics/v1/analytics/overall-metrics';
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const agencyAnalyticsDetails = useViewDetails(
    agencyAnalyticsApi,
    metricsLoaded
  );
  const { fetching, success, error, data } = analyticsDetails;
  const { success: agencyAnalyticsSuccess, data: agencyAnalyticsData } =
    agencyAnalyticsDetails;
  const title = 'Metrics';
  const icon = MetricsIcon;

  useEffect(() => {
    if (fetching === true) {
      setMetricsLoaded(false);
    }
  }, [fetching]);

  let portfolioLink = user.getPortfolioPath();
  let customerAnalyticsLink = user.getCustomerAnalyticsPath();

  const mappingObject = {
    customerAnalyticsLink: customerAnalyticsLink,
    portfolioLink: portfolioLink,
  };

  const onSelect = async (marks, worksheetName, viz) => {
    const redirectTo =
      dashboardRedirectionMapping[`${typeToUse}-${worksheetName}`];
    if (redirectTo in mappingObject) {
      history.push(mappingObject[redirectTo]);
    }
  };

  return (
    <div>
      <Title icon={icon} titleText={title} left={left} />
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
          onFirstInteraction={() => {
            setMetricsLoaded(true);
          }}
          onSelect={onSelect}
        />
      )}
      {error && <AnalyticsError />}
      {metricsLoaded && agencyAnalyticsSuccess && !showTypeSwitch && (
        <TableauMarkSelectionView
          {...agencyAnalyticsData}
          {...agencyMetricsDimensions}
          adjustHeight={true}
          onSelect={onSelect}
        />
      )}
    </div>
  );
}

Metrics.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};
