import React from 'react';
import { useContext, useState } from 'react';
import { UserContext } from './../UserContext';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Tooltip from '@material-ui/core/Tooltip';
import { ImageRemoveConfirmationDialog } from './ImageRemoveConfirmationDialog';
import { ImageUploadDialog } from './ImageUploadDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  imageHolder: {
    flexShrink: 0,
    width: 200,
    height: 200,
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    borderWidth: 3,
    borderStyle: 'solid',
  },
  centerAligned: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  floatingPanel: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30%',
    top: '70%',
    backgroundColor: theme.palette.action.disabled,
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  initials: {
    color: theme.palette.primary.contrastText,
  },
  avatarContainer: {
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export function ProfileImage(props) {
  const classes = useStyles();
  const [uploadOpen, setUploadOpen] = useState(false);
  const openUpload = () => {
    setUploadOpen(true);
  };
  const closeUpload = () => {
    setUploadOpen(false);
  };
  const [removeOpen, setRemoveOpen] = useState(false);
  const openRemoveConfirmation = () => {
    setRemoveOpen(true);
  };
  const closeRemoveConfirmation = () => {
    setRemoveOpen(false);
  };
  const { user } = useContext(UserContext);
  const initials = user.getInitials();
  const { userDetails, removeAvatar, setToRefresh } = props;
  const { avatarURL } = userDetails;
  const buttons = (
    <div className={classes.centerAligned}>
      <>
        <ImageUploadDialog
          open={uploadOpen}
          onClose={closeUpload}
          onSuccess={setToRefresh}
          avatarURL={avatarURL}
        />
        <Tooltip title="Set Profile Picture">
          <IconButton
            aria-label="upload"
            className={classes.button}
            onClick={openUpload}
          >
            <PhotoCameraIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </>
      {avatarURL && (
        <>
          <ImageRemoveConfirmationDialog
            open={removeOpen}
            onClose={closeRemoveConfirmation}
            onConfirm={removeAvatar}
            avatarURL={avatarURL}
          />
          <Tooltip title="Remove Profile Picture">
            <IconButton
              aria-label="delete"
              className={classes.button}
              onClick={openRemoveConfirmation}
            >
              <RemoveCircleIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} className={classes.centerAligned}>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.imageHolder} src={avatarURL}>
            <Typography className={classes.initials} variant="h2">
              {initials}
            </Typography>
          </Avatar>
          <div className={classes.floatingPanel}>{buttons}</div>
        </div>
      </Grid>
    </Grid>
  );
}

ProfileImage.props = {
  userDetails: PropTypes.shape({
    avatarURL: PropTypes.string,
  }),
  removeAvatar: PropTypes.func.isRequired,
  setToRefresh: PropTypes.func.isRequired,
};
