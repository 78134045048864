import React from 'react';
import { ReactComponent as TutorialsAndVideos } from '../../assets/icons/TutorialsAndVideos.svg';
import { ReactComponent as Home } from '../../assets/icons/Home.svg';
import { ReactComponent as Portfolio } from '../../assets/icons/Portfolio.svg';
import { ReactComponent as Analytics } from '../../assets/icons/Analytics.svg';
import { ReactComponent as Benchmarking } from '../../assets/icons/Benchmarking.svg';
import { ReactComponent as DataEnrichment } from '../../assets/icons/DataEnrichment.svg';
import { ReactComponent as Foresight } from '../../assets/icons/Foresight.svg';
import { ReactComponent as Settings } from '../../assets/icons/Settings.svg';
import { ReactComponent as Metrics } from '../../assets/icons/Metrics.svg';
import { ReactComponent as Trends } from '../../assets/icons/Trends.svg';
import { ReactComponent as Insights } from '../../assets/icons/Insights.svg';
import { ReactComponent as Customers } from '../../assets/icons/Customers.svg';
import { ReactComponent as Teams } from '../../assets/icons/Teams.svg';
import { ReactComponent as Carriers } from '../../assets/icons/Carriers.svg';
import { ReactComponent as ContactSupport } from '../../assets/icons/ContactSupport.svg';
import { ReactComponent as MyProfile } from '../../assets/icons/MyProfile.svg';
import { ReactComponent as BillingsAndPayments } from '../../assets/icons/BillingsAndPayments.svg';
import { ReactComponent as ChangePassword } from '../../assets/icons/ChangePassword.svg';
import { ReactComponent as Logout } from '../../assets/icons/Logout.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as UserManagement } from '../../assets/icons/UserManagement.svg';
import { ReactComponent as Book } from '../../assets/icons/Book.svg';
import { ReactComponent as DonnaSetting } from '../../assets/icons/DonnaSetting.svg';
import { ReactComponent as Configuration } from '../../assets/icons/Configuration.svg';
import { ReactComponent as UploadImage } from '../../assets/icons/UploadImage.svg';
import { ReactComponent as LockImage } from '../../assets/icons/Lock.svg';
import { ReactComponent as Location } from '../../assets/icons/Location.svg';
import { ReactComponent as DefaultView } from '../../assets/icons/Square.svg';
import { ReactComponent as List } from '../../assets/icons/List.svg';
import { ReactComponent as Roundout } from '../../assets/icons/Bulb.svg';
import { ReactComponent as Renew } from '../../assets/icons/Stars.svg';
import { ReactComponent as Retain } from '../../assets/icons/Frown.svg';
import { ReactComponent as MyViews } from '../../assets/icons/InvertedTree.svg';
import HanoverPng from '../../assets/icons/Hanover.png';
import { ReactComponent as NeedHelp } from '../../assets/icons/NeedHelp.svg';
import donnagpt from '../../assets/icons/donnagpt.png';

const TutorialsAndVideosIcon = () => {
  return <TutorialsAndVideos />;
};

const ContactSupportIcon = () => {
  return <ContactSupport />;
};

const DashboardIcon = () => {
  return <Dashboard />;
};

const CustomersIcon = () => {
  return <Customers />;
};

const TeamsIcon = () => {
  return <Teams />;
};

const CarriersIcon = () => {
  return <Carriers />;
};

const HomeIcon = () => {
  return <Home />;
};

const PortfolioIcon = () => {
  return <Portfolio />;
};

const AnalyticsIcon = () => {
  return <Analytics />;
};

const BenchmarkingIcon = () => {
  return <Benchmarking />;
};

const DataEnrichmentIcon = () => {
  return <DataEnrichment />;
};

const ForesightIcon = () => {
  return <Foresight />;
};

const MetricsIcon = () => {
  return <Metrics />;
};

const TrendsIcon = () => {
  return <Trends />;
};

const InsightsIcon = () => {
  return <Insights />;
};

const SettingsIcon = () => {
  return <Settings />;
};

const MyProfileIcon = () => {
  return <MyProfile />;
};

const BillingsAndPaymentsIcon = () => {
  return <BillingsAndPayments />;
};

const ChangePasswordIcon = () => {
  return <ChangePassword />;
};

const LogoutIcon = () => {
  return <Logout />;
};

const UserManagementIcon = () => {
  return <UserManagement />;
};

const BookIcon = () => {
  return <Book />;
};

const DonnaSettingIcon = () => {
  return <DonnaSetting />;
};

const ConfigurationIcon = () => {
  return <Configuration />;
};

const UploadImageIcon = () => {
  return <UploadImage />;
};

const LockImageIcon = () => {
  return <LockImage />;
};

const LocationImageIcon = () => {
  return <Location />;
};

const DefaultViewIcon = () => {
  return <DefaultView />;
};

const ListIcon = () => {
  return <List />;
};

const RoundoutIcon = () => {
  return <Roundout />;
};

const RenewIcon = () => {
  return <Renew />;
};

const RetainIcon = () => {
  return <Retain />;
};

const MyViewsIcon = () => {
  return <MyViews />;
};

const HanoverIcon = () => {
  return <img src={HanoverPng} />;
};

const NeedHelpIcon = () => {
  return <NeedHelp />;
};

const DonnaGPTIcon = () => {
  return <img src={donnagpt} />;
};

export {
  TutorialsAndVideosIcon,
  HomeIcon,
  PortfolioIcon,
  AnalyticsIcon,
  BenchmarkingIcon,
  DataEnrichmentIcon,
  ForesightIcon,
  SettingsIcon,
  MetricsIcon,
  TrendsIcon,
  InsightsIcon,
  DashboardIcon,
  CustomersIcon,
  TeamsIcon,
  CarriersIcon,
  ContactSupportIcon,
  MyProfileIcon,
  BillingsAndPaymentsIcon,
  ChangePasswordIcon,
  LogoutIcon,
  UserManagementIcon,
  BookIcon,
  DonnaSettingIcon,
  ConfigurationIcon,
  UploadImageIcon,
  LockImageIcon,
  LocationImageIcon,
  DefaultViewIcon,
  ListIcon,
  RenewIcon,
  RoundoutIcon,
  RetainIcon,
  MyViewsIcon,
  HanoverIcon,
  NeedHelpIcon,
  DonnaGPTIcon,
};
