import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

export function JiraButton(props) {
  const { onClick } = props;
  return (
    <Button variant="outlined" color="primary" onClick={onClick}>
      Contact Support
    </Button>
  );
}

JiraButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
