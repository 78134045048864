import React, { useContext } from 'react';
import { useCustomerCount } from './useCustomerCount';
import { UserContext } from '../UserContext';
import { CustomerCountView } from './CustomerCountView';

export function CustomerCount(props) {
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();
  const hasQuota = tenantApi.hasCovQuota();
  const customerCount = useCustomerCount(hasQuota);
  const count = customerCount.success && customerCount.data;
  const { view } = props;
  if (!hasQuota) {
    return (null);
  }
  return (
    <CustomerCountView
      view={view}
      count={count}
      customerCount={customerCount}
    />
  );
}
