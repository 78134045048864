import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  inline: {
  },
  changeIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  newIcon: {
    marginRight: theme.spacing(1),
  },
  old: {
    textDecoration: 'line-through',
  },
}));

export function CellDiff(props) {
  const classes = useStyles();
  const { editMode, lastValue, currentValue } = props;
  if (editMode && lastValue !== currentValue) {
    let icon;
    if (lastValue === '') {
      icon = <AddIcon className={classes.newIcon} />;
    } else if (currentValue === '') {
      icon = <></>;
    } else {
      icon = <ArrowForwardIcon className={classes.changeIcon} />;
    }
    return (
      <div className={classes.root}>
        <div className={classes.old}>{lastValue}</div>
        {icon}
        <Typography
          variant="body2"
          className={classes.inline}
          color="textPrimary"
        >
          {currentValue}
        </Typography>
      </div>
    );
  } else {
    return <>{currentValue}</>;
  }
}

CellDiff.propTypes = {
  editMode: PropTypes.bool,
  lastValue: PropTypes.any,
  currentValue: PropTypes.any,
};
