import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import JiraCustomerRequest from '@aureus/jira-service-desk-forms/lib/components/RequestCreation/JiraCustomerRequest';

const API_PATH = '/api/jira/v1/sendRequest';

const categories = JSON.parse(process.env.REACT_APP_JIRA_CATEGORIES);

const onSuccess = () => {
};

const onOk = () => {
};

export function SendRequest() {
  const { user } = useContext(UserContext);
  const email = user.getEmail();
  const tenantApi = user.getTenantApi();
  let agencyName;
  if (tenantApi) {
    agencyName = tenantApi.getAgencyName();
  }
  return (
    <JiraCustomerRequest
      endpoint={API_PATH}
      categories={categories}
      onSuccess={onSuccess}
      handleRedirect={onOk}
      customer={{
        email: email,
        agency: agencyName,
      }}
    />
  );
}
