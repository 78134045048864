import React, { useEffect, useContext } from 'react';
import { hotjar } from 'react-hotjar';
import { UserContext } from './../UserContext';

const setUserAttributes = (user) => {
  const userId = `${user.getTenant()}-${user.getTenantLevelUserId()}`;
  window.hj('identify', userId, {
    tenant: user.getTenant(),
    firstName: user.getFirstName(),
    lastName: user.getLastName(),
    email: user.getEmail(),
  });
};
export function Hotjar(props) {
  const { user } = useContext(UserContext);
  useEffect(() => {
    const hotjarId = parseInt(process.env.REACT_APP_HOTJAR_ID);
    const hotjarSnippetVersion = parseInt(
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
    if (hotjarId && hotjarSnippetVersion) {
      hotjar.initialize(hotjarId, hotjarSnippetVersion);
      setUserAttributes(user);
    }
  }, [user]);
  return <>{props.children}</>;
}
