import React from 'react';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { ForesightSkeleton } from './ForesightSkeleton';
import { ForesightError } from './ForesightError';
import { Title } from '../Header';
import { ForesightIcon } from '../Header/Icons.js';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const PATH = `/api/metrics/v1/foresight`;

export function Foresight() {
  const viewDetails = useViewDetails(PATH);
  const { fetching, success, error, data } = viewDetails;
  return (
    <div>
      {fetching && <ForesightSkeleton />}
      {success && (
        <>
          <Title icon={ForesightIcon} titleText="Foresight" />
          <TableauMarkSelectionView
            {...data}
            {...dimensions}
            adjustHeight={true}
          />
        </>
      )}
      {error && <ForesightError />}
    </div>
  );
}
