import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { format, parseISO } from 'date-fns';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ConfirmationModal from './ConfirmationModal';
import { useTenantDetails } from './useTenantDetails';
import BackButton from '../BillingAutomation/BackButton';
import { SetHomeButton } from '../Diem/SetHomeButton';
import { ResetHomeButton } from '../Diem/ResetHomeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    zIndex: 1099,
    backgroundColor: '#EDF2F5',
    [theme.breakpoints.down('md')]: {
      top: 147,
    },
    [theme.breakpoints.up('md')]: {
      top: 80,
    },
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  iconGrid: {
    paddingRight: theme.spacing(1),
    marginBottom: 0,
    fontSize: 20,
  },
  homeButton: {
    margin: theme.spacing(1.25),
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#4A4A4A',
    marginBottom: 0,
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
  alignCenterText: {
    alignItems: 'center',
  },
  divider: {
    height: 30,
    margin: `0 ${theme.spacing(1.25)}px`,
  },
  marginBottom: {
    marginBottom: theme.spacing(1.25),
  },
  updateButton: {
    background: '#19588036',
    color: '#195880',
    textTransform: 'inherit',
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
  },
  lastUpdated: {
    fontSize: 14,
    fontWeight: 500,
    color: '#4A4A4A',
  },
}));

export function Title(props) {
  const classes = useStyles();
  const {
    titleText,
    count,
    subtitleText,
    left,
    updateButton,
    right,
    hideDivider,
    hasBackButton,
    parentPath,
    homeButton,
    homeContext,
  } = props;
  const Icon = props.icon;
  const [open, setConfrimationDialog] = useState(false);
  const tenantDetails = useTenantDetails();
  const { success, data } = tenantDetails;
  let updateDate;
  let dayName;
  if (success && data.dataEnrichmentUpdatedDate) {
    const splitDate = data.dataEnrichmentUpdatedDate.split('/');
    const stringDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
    updateDate = format(parseISO(stringDate), 'dd MMMM yyyy');
    dayName = format(parseISO(stringDate), 'EEEE');
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {hasBackButton && (
          <Grid item className={classes.iconGrid}>
            <BackButton parentPath={parentPath} />
          </Grid>
        )}
        <Grid className={classes.marginBottom} item sm container>
          <Grid
            container
            classes={{
              root: classes.alignCenterText,
            }}
            alignContent="center"
          >
            {Icon && (
              <Grid item className={classes.iconGrid}>
                <Icon className={classes.icon}></Icon>
              </Grid>
            )}
            <Grid>
              <Typography
                className={classes.titleText}
                variant="subtitle1"
                gutterBottom
              >
                {titleText}
              </Typography>
            </Grid>
            <Grid>
              {count && (
                <Divider
                  orientation="vertical"
                  className={classes.divider}
                ></Divider>
              )}
            </Grid>
            <Grid>{count && <div className={classes.count}>{count}</div>}</Grid>
            {left !== undefined && (
              <>
                <Divider
                  orientation="vertical"
                  className={classes.divider}
                ></Divider>
                {left}
              </>
            )}
          </Grid>
          {subtitleText && (
            <Typography className={classes.subtitleText} variant="body2">
              {subtitleText}
            </Typography>
          )}
        </Grid>
        {homeButton && (
          <div className={classes.homeButton}>
            <SetHomeButton context={homeContext} />
            <ResetHomeButton context={homeContext} />
          </div>
        )}
        {right !== undefined && <div>{right}</div>}
        {updateButton && success && updateDate && (
          <Grid item className={classes.iconGrid}>
            <Typography className={classes.lastUpdated}>
              Last updated on {dayName}, {updateDate}
            </Typography>
          </Grid>
        )}
        {updateButton && (
          <Grid item className={classes.iconGrid}>
            <Button
              className={classes.updateButton}
              startIcon={<AutorenewIcon />}
              onClick={() => setConfrimationDialog(true)}
            >
              Request for a data refresh
            </Button>
            <ConfirmationModal open={open} setOpen={setConfrimationDialog} />
          </Grid>
        )}
      </Grid>
      {!hideDivider && <Divider orientation="horizontal"></Divider>}
    </div>
  );
}

Title.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  left: PropTypes.element,
  right: PropTypes.element,
};
