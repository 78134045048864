import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { CustomerCardLayout } from './CustomerCardLayout';
import { MyListContext } from '../CardView/MyListContext';
import { CustomerSearch } from './CustomerSearch';
import base64url from 'base64url';
import { gaEvent } from '../GoogleAnalytics/gaEvent';
import { LastRefreshDate } from '../Header/LastRefreshDate';
import { DefaultView } from './DefaultView';
import { TitleHome } from '../Header';
import { DefaultViewIcon } from '../Header/Icons';
import CONTEXT from '@aureus/cov-context';

export function View(props) {
  const { type } = props;
  const match = useRouteMatch();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let hasBackButton = false;
  const query = useQuery();
  const backPath = query.get('backPath');
  if (backPath !== null && backPath !== undefined) {
    hasBackButton = true;
  }
  const history = useHistory();
  const right = <LastRefreshDate />;
  const goToViewAll = (context) => {
    history.push(
      `${match.url}/viewAll/${context}?backPath=${match.url}/default`
    );
  };
  const onSearch = (searchTerm) => {
    const searchTermPathParam = base64url(encodeURIComponent(searchTerm));
    history.push(`${match.url}/search/input/${searchTermPathParam}`);
  };

  const gaTracking = (customerId, context, cardIndex) => {
    const category = 'CustomerCardSelect';
    const action = 'From ' + context;
    const label = customerId;
    const value = cardIndex;
    gaEvent(category, action, label, value);
  };
  return (
    <>
      <Switch>
        <Route path={`${match.url}/viewAll/Retain`}>
          <CustomerCardLayout
            hasBackButton={hasBackButton}
            backPath={backPath}
            icon={true}
            quick={true}
            type={type}
            updateButton={true}
            contextWithoutFilter={false}
            path={`${match.url}/viewAll`}
            context={CONTEXT.RETAIN}
          />
        </Route>
        <Route path={`${match.url}/viewAll/Renew`}>
          <CustomerCardLayout
            hasBackButton={hasBackButton}
            backPath={backPath}
            icon={true}
            quick={true}
            type={type}
            updateButton={true}
            contextWithoutFilter={false}
            path={`${match.url}/viewAll`}
            context={CONTEXT.RENEWAL}
          />
        </Route>
        <Route path={`${match.url}/viewAll/RoundOut`}>
          <>
            <CustomerCardLayout
              hasBackButton={hasBackButton}
              backPath={backPath}
              icon={true}
              quick={true}
              type={type}
              updateButton={true}
              contextWithoutFilter={false}
              path={`${match.url}/viewAll`}
              context={CONTEXT.ROUNDOUT}
            />
          </>
        </Route>
        <Route path={`${match.url}/viewAll/myList`}>
          <>
            <CustomerCardLayout
              hasBackButton={hasBackButton}
              backPath={backPath}
              icon={true}
              quick={true}
              type={type}
              updateButton={true}
              contextWithoutFilter={false}
              path={`${match.url}/viewAll`}
              context="myList"
            />
          </>
        </Route>

        <Route path={`${match.url}/default`}>
          <TitleHome
            icon={DefaultViewIcon}
            titleText="Default View"
            right={right}
            onSearch={onSearch}
          />
          <DefaultView
            type={type}
            path={`${match.url}/default`}
            goToViewAll={goToViewAll}
          />
        </Route>
        <Route path={`${match.url}/search/input/:searchInput`}>
          <CustomerSearch
            type={`${type}/search`}
            basePath={match.url}
            backPath={`${match.url}/default`}
          />
        </Route>
        <Route exact path={`${match.url}/search/input/`}>
          <Redirect
            to={{
              pathname: `${match.url}/search`,
            }}
          />
        </Route>
        <Route path={`${match.url}/search`}>
          <CustomerSearch
            type={`${type}/search`}
            basePath={match.url}
            backPath={`${match.url}/default`}
          />
        </Route>
      </Switch>
    </>
  );
}

View.contextType = MyListContext;

View.propTypes = {
  type: PropTypes.string.isRequired,
};
