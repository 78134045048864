import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';
import { RecommendationChangeTable } from './RecommendationTable';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
  }, header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButton: {
    color: '#4C94EC',
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: 14,
    backgroundColor: '#e1f0ff',
    lineHeight: '17px',
    padding: '7px 10px',
  },
  confirmButton: {
    color: '#ffffff',
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: 14,
    backgroundColor: '#2D6089',
    lineHeight: '17px',
    padding: '7px 10px',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh'
  },
  title: {
    backgroundColor: '#CBD5DD',
  },
  dialogText: {
    color: '#4A4A4A'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export function RecommendationChangeDialog(props) {
  const classes = useStyles();
  const { open, onClose, onConfirm, changes, rows } = props;
  const confirm = () => {
    onConfirm();
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.title}>
        Save Changes
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.dialogText}>The following changes will be saved and the process is expected to be complete in next couple of days. You will receive a notification email once the process is complete.</DialogContentText>
        <Box><RecommendationChangeTable rows={rows} changes={changes} /></Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" className={classes.cancelButton}>
          Cancel
        </Button>
        <Button onClick={confirm} variant="contained" className={classes.confirmButton}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
