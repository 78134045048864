import React from 'react';
import axios from 'axios';

const GET_FILTERS_PATH = '/api/metrics/v1/getFilters/';

const useGetFilters = (userID, toGetFilter, clearToGetFilter, onlyOn) => {
  const [filtersResponse, setFiltersResponse] = React.useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });

  React.useEffect(() => {
    const getFilters = async () => {
      setFiltersResponse({
        fetching: true,
        success: false,
        error: false,
      });
      const { data: details } = await axios.get(`${GET_FILTERS_PATH}${userID}`);

      const { data, error } = details;
      if (error) {
        setFiltersResponse({
          fetching: false,
          success: false,
          error: error,
        });
      } else {
        if (onlyOn && data.amsMode === 'AMS') {
          setFiltersResponse({
            fetching: false,
            success: true,
            error: false,
            data: {
              data: {
                amsMode: 'FILTER',
                filterValue: '{ "filters": [] }',
              },
            },
          });
        } else {
          setFiltersResponse({
            fetching: false,
            success: true,
            error: error,
            data: details,
          });
        }
      }
    };
    if (toGetFilter) {
      getFilters();
      clearToGetFilter();
    }
  }, [userID, toGetFilter, clearToGetFilter, onlyOn]);
  return filtersResponse;
};
export default useGetFilters;
