import React, { useContext, useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { yellow, green } from '@material-ui/core/colors';
import tarmika from '../../assets/Tarmika.svg';
import agencyZoom from '../../assets/AgencyZoomIcon.svg';
import emailByDonna from '../../assets/EmailByDonna.svg';
import insuredMine from '../../assets/InsuredMine.svg';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dialogType } from '@aureus/outbound-channels/dist/config/dialogConfig';
import OutboundChannelManager from './OutboundChannelManager';
import { providerKey } from '@aureus/outbound-channels/dist/Enums/providerConfig';
import OutboundChannelListManager from './OutboundChannelListManager';
import { useGetQuoteEnabled } from './useGetQuoteEnabled';
import { useSetQuoteEnabled } from './useSetQuoteEnabled';
import {
  isAgencyZoomAllowed,
  isQuoteAllowed,
} from '../../utils/donnaPlansUtil';
import { useGetSessionDetails } from '../UserContextProvider/useGetSessionDetails';
import { User } from '../UserContextProvider/User';
import useGetActivationDetails from './useGetActivationDetails';
import { useTenantDetails } from '../Header/useTenantDetails';
import { Skeleton } from '@material-ui/lab';
import { useSetAgencyZoomEnabled } from './useSetAgencyZoomEnabled';
import { useGetAgencyZoomEnabled } from './useGetAgencyZoomEnabled';
import { useSetInsuredMineEnabled } from './useSetInsuredMineEnabled';
import { useGetInsuredMineEnabled } from './useGetInsuredMineEnabled';
import { ConfigurationIcon, LockImageIcon } from '../Header/Icons';
import clsx from 'clsx';
import { UserContext } from '../UserContext';

const useStyles = makeStyles((theme) => ({
  head: {
    lineHeight: '14px',
    padding: '16px 30px 20px 20px',
    fontSize: 12,
    fontWeight: '600',
    color: '#8DA9BF',
  },
  body: {
    height: 31,
    padding: '15px 20px',
  },
  partnerImg: {
    width: 180,
    objectFit: 'contain',
  },
  tarmikaImg: {
    width: 155,
    objectFit: 'contain',
  },
  statusMainDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  statusdiv: {
    width: 15,
    height: 15,
    left: 192,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    marginRight: 5,
  },
  activeStatus: {
    background: '#1FE000',
  },
  inactiveStatus: {
    background: 'red',
  },
  bodyText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: '#4A4A4A',
    fontWeight: 500,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  configureButton: {
    width: 170,
    height: 40,
    textTransform: 'none',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: theme.typography.fontFamily,
    background: '#E1F0FF',
    color: '#195880',
  },
  blurredElement: {
    filter: 'blur(5px)',
  },
  tableContainer: {
    boxShadow: 'unset',
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    '& h2': {
      height: 50,
    },
  },
  contentRoot: {
    minHeight: 100,
  },
  enableSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  switchButton: {
    marginLeft: 'auto',
  },
  lockDiv: {
    backgroundColor: 'rgb(45,96,137, 0.2)',
    borderRadius: '50%',
    userSelect: 'none',
  },
  textOverlay: {
    position: 'absolute',
    top: 2,
    filter: 'none',
  },

  //

  root: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '30%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordian: {
    padding: '12px 24px',
    paddingBottom: '5px',
    marginBottom: theme.spacing(1),
    '&.Mui-disabled': {
      background: '#fff',
      color: 'black',
    },
  },
  accordianHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
    whiteSpace: 'no-wrap',
  },
  manageButton: {
    width: 170,
    height: 40,
    textTransform: 'none',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: theme.typography.fontFamily,
    background: '#E1F0FF',
    color: '#195880',
  },
  tableRow: {
    background: '#fff',
  },
}));

const rows = ['tarmika', 'agencyZoom', 'insuredMine'];

const IntegrationTableRow = (props) => {
  const classes = useStyles();
  const { row } = props;

  const addAccountApiPath = '/api/outbound/v1/addAccount';
  const getAccountsApiPath = '/api/outbound/v1/getAccounts';
  const createChannelApiPath = '/api/outbound/v1/createChannel';
  const getChannelsApiPath = '/api/outbound/v1/getChannels';
  const updateChannelApiPath = '/api/outbound/v1/editChannel';
  const sendToChannelsApiPath = '/api/outbound/v1/sendToChannels';
  const getLeadCreationDetailsPath = '/api/outbound/v1/getLeadCreationDetails';
  const getTreatmentDetailsPath = '/api/outbound/v1/getTreatmentDetails';

  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const userRole = user.getTopRole();
  const userID = user.getTenantLevelUserId();
  let tenantPlanType;

  const [channelChangeCount, setChannelChangeCount] = useState(0);

  let isSetupUser;

  const incrementChannelChangeCount = () => {
    setChannelChangeCount(channelChangeCount + 1);
  };

  // tarmika

  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [tarmikaPayload, setTarmikaPayload] = useState({});
  const [saveTarmikaEnableState, setSaveTarmikaEnableState] = useState(false);
  const [getTarmikaEnableState, setGetTarmikaEnableState] = useState(true);
  useSetQuoteEnabled(
    saveTarmikaEnableState,
    tarmikaPayload,
    setGetTarmikaEnableState
  );
  const quoteEnabled = useGetQuoteEnabled(getTarmikaEnableState);
  const { fetching, success, data } = quoteEnabled;
  const [tarmikaStatus, setTarmikaStatus] = useState(false);
  const [tarmikaEnableChecked, setTarmikaEnableChecked] = useState(false);

  const [agencyZoomPayload, setAgencyZoomPayload] = useState({});
  const [saveAgencyZoomEnableState, setSaveAgencyZoomEnableState] =
    useState(false);
  const [getAgencyZoomEnableState, setGetAgencyZoomEnableState] =
    useState(true);
  useSetAgencyZoomEnabled(
    saveAgencyZoomEnableState,
    agencyZoomPayload,
    setGetAgencyZoomEnableState
  );
  const agencyZoomEnabled = useGetAgencyZoomEnabled(getAgencyZoomEnableState);
  const {
    fetching: agencyZoomFetching,
    success: agencyZoomSuccess,
    data: agencyZoomData,
  } = agencyZoomEnabled;
  const [agencyZoomStatus, setAgencyZoomStatus] = useState(false);
  const [agencyZoomEnableChecked, setAgencyZoomEnableChecked] = useState(false);

  const [insuredMinePayload, setInsuredMinePayload] = useState({});
  const [saveInsuredMineEnableState, setSaveInsuredMineEnableState] =
    useState(false);
  const [getInsuredMineEnableState, setGetInsuredMineEnableState] =
    useState(true);
  useSetInsuredMineEnabled(
    saveInsuredMineEnableState,
    insuredMinePayload,
    setGetInsuredMineEnableState
  );
  const insuredMineEnabled = useGetInsuredMineEnabled(
    getInsuredMineEnableState
  );
  const {
    fetching: insuredMineFetching,
    success: insuredMineSuccess,
    data: insuredMineData,
  } = insuredMineEnabled;
  const [insuredMineStatus, setInsuredMineStatus] = useState(false);
  const [insuredMineEnableChecked, setInsuredMineEnableChecked] =
    useState(false);

  const handleTarmikaChecked = () => {
    const payloadObj = {
      quoteEnabled: tarmikaEnableChecked,
    };
    setTarmikaPayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleTarmikaClose = () => {
    setOpenConfiguration(false);
    setTarmikaEnableChecked(data && data.quoteEnabled && data.quoteEnabled);
  };

  const handleAgencyZoomChecked = () => {
    const payloadObj = {
      agencyZoomEnabled: agencyZoomEnableChecked,
    };
    setAgencyZoomPayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleAgencyZoomClose = () => {
    setOpenConfiguration(false);
    setAgencyZoomEnableChecked(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
  };

  const handleInsuredMineChecked = () => {
    const payloadObj = {
      insuredMineEnabled: insuredMineEnableChecked,
    };
    setInsuredMinePayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleInsuredMineClose = () => {
    setOpenConfiguration(false);
    setInsuredMineEnableChecked(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
  };

  useEffect(() => {
    setSaveTarmikaEnableState(true);
  }, [tarmikaPayload]);
  useEffect(() => {
    setTarmikaEnableChecked(data && data.quoteEnabled && data.quoteEnabled);
    setTarmikaStatus(data && data.quoteEnabled && data.quoteEnabled);
    setGetTarmikaEnableState(false);
  }, [data]);

  useEffect(() => {
    setSaveAgencyZoomEnableState(true);
  }, [agencyZoomPayload]);
  useEffect(() => {
    setAgencyZoomEnableChecked(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
    setAgencyZoomStatus(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
    setGetAgencyZoomEnableState(false);
  }, [agencyZoomData]);

  useEffect(() => {
    setSaveInsuredMineEnableState(true);
  }, [insuredMinePayload]);
  useEffect(() => {
    setInsuredMineEnableChecked(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
    setInsuredMineStatus(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
    setGetInsuredMineEnableState(false);
  }, [insuredMineData]);

  const { data: tenantDetails } = useTenantDetails();

  if (user.success) {
    isSetupUser = user.isSetupUser();
  }

  const { data: activationData } = useGetActivationDetails(isSetupUser);

  let agencyZoomAllowed;
  let tarmikaAllowed;

  const [triggerChannelFetch, setTriggerChannelFetch] = useState(false);

  try {
    if (isSetupUser && activationData) {
      const { agencySetupData } = activationData;
      const { plan_type: planType } = agencySetupData ? agencySetupData : {};
      if (planType === undefined) {
        throw new Error();
      }
      tenantPlanType = planType;
      agencyZoomAllowed = isAgencyZoomAllowed(planType);
      tarmikaAllowed = isQuoteAllowed(planType);
    } else if (!isSetupUser) {
      const tenantApi = user.getTenantApi();
      tenantPlanType = tenantApi.getPlanType();
      agencyZoomAllowed = isAgencyZoomAllowed(tenantPlanType);
      tarmikaAllowed = isQuoteAllowed(tenantPlanType);
    }
  } catch (e) {
    agencyZoomAllowed = false;
    tarmikaAllowed = false;
  }

  const featureLockConfig = {
    lockTitle: 'Upgrade plan to avail this feature',
    lockWidget:
      tenantPlanType && (tenantPlanType === 'solo' || tenantPlanType === 'free')
        ? true
        : false,
    redirectURL: '/home/settings/billing-details/plans',
  };

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {agencyZoomAllowed && (
        <Accordion
          className={classes.accordian}
          expanded={expanded === providerKey.AGENCYZOOM}
          onChange={handleChange(providerKey.AGENCYZOOM)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordianHeader}
          >
            <Typography style={{ width: 250 }}>
              <img src={agencyZoom} alt="img" className={classes.partnerImg} />
            </Typography>
            <Typography
              style={{
                width: 250,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {agencyZoomAllowed && agencyZoomFetching && (
                <Skeleton variant="rectangular" width={170} height={30} />
              )}
              {agencyZoomAllowed && agencyZoomSuccess && (
                <>
                  <span
                    style={{
                      color: '#1FE000',
                      marginTop: 10,
                      lineHeight: 0,
                    }}
                  >
                    &#11044;
                  </span>
                  &nbsp;
                  <span style={{ fontSize: 16 }}>Available</span>
                </>
              )}
            </Typography>
            <Typography style={{ width: '100%', marginLeft: 20 }}>
              Increase sales, boost retention and analyze agency & producer
              performance
            </Typography>
            <Typography
              className={classes.buttons}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            >
              {agencyZoomAllowed && agencyZoomFetching && (
                <Skeleton variant="rectangular" width={170} height={50} />
              )}
              {/* <Button
                    variant="contained"
                    className={classes.manageButton}
                    color="tertiary"
                    disableElevation
                    onClick={() => {
                      // handleOpen(redirectTo);
                    }}
                  >
                    Manage Accounts
                  </Button> */}
              {agencyZoomAllowed && agencyZoomSuccess && (
                <>
                  <OutboundChannelManager
                    tenant={tenant}
                    userID={userID}
                    providerNameInSettings={providerKey.AGENCYZOOM}
                    dialogKey={dialogType.CREATE_CHANNEL_INTEGRATION}
                    addAccountApiPath={addAccountApiPath}
                    getAccountsApiPath={getAccountsApiPath}
                    createChannelApiPath={createChannelApiPath}
                    getChannelsApiPath={getChannelsApiPath}
                    updateChannelApiPath={updateChannelApiPath}
                    sendToChannelsApiPath={sendToChannelsApiPath}
                    getLeadCreationDetailsPath={getLeadCreationDetailsPath}
                    getTreatmentDetailsPath={getTreatmentDetailsPath}
                    setTriggerChannelFetch={setTriggerChannelFetch}
                    featureLockConfig={featureLockConfig}
                    incrementChannelChangeCount={incrementChannelChangeCount}
                  />
                </>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              key={channelChangeCount}
              style={{ width: '100%', padding: 0, margin: 0 }}
            >
              <OutboundChannelListManager
                tenant={tenant}
                userID={userRole !== 'ceo' ? userID : ''}
                providerName={providerKey.AGENCYZOOM}
                addAccountApiPath={addAccountApiPath}
                getAccountsApiPath={getAccountsApiPath}
                createChannelApiPath={createChannelApiPath}
                getChannelsApiPath={getChannelsApiPath}
                updateChannelApiPath={updateChannelApiPath}
                sendToChannelsApiPath={sendToChannelsApiPath}
                getLeadCreationDetailsPath={getLeadCreationDetailsPath}
                getTreatmentDetailsPath={getTreatmentDetailsPath}
                triggerChannelFetch={triggerChannelFetch}
                featureLockConfig={featureLockConfig}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        className={classes.accordian}
        expanded={expanded === providerKey.EMAILBYDONNA}
        onChange={handleChange(providerKey.EMAILBYDONNA)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordianHeader}
        >
          <Typography style={{ width: 250 }}>
            <img src={emailByDonna} alt="img" className={classes.partnerImg} />
          </Typography>
          <Typography
            style={{
              width: 250,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                color: '#1FE000',
                marginTop: 10,
                lineHeight: 0,
              }}
            >
              &#11044;
            </span>
            &nbsp;
            <span style={{ fontSize: 16 }}>Available</span>
          </Typography>
          <Typography style={{ width: '100%', marginLeft: 20 }}>
            DONNA actionable insights directly to your inbox.
          </Typography>
          <Typography
            className={classes.buttons}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          >
            {/* <Button
              variant="contained"
              className={classes.manageButton}
              color="tertiary"
              disableElevation
              onClick={() => {
                // handleOpen(redirectTo);
              }}
            >
              Manage Accounts
            </Button> */}
            <OutboundChannelManager
              tenant={tenant}
              userID={userID}
              providerNameInSettings={providerKey.EMAILBYDONNA}
              dialogKey={dialogType.CREATE_CHANNEL_INTEGRATION}
              addAccountApiPath={addAccountApiPath}
              getAccountsApiPath={getAccountsApiPath}
              createChannelApiPath={createChannelApiPath}
              getChannelsApiPath={getChannelsApiPath}
              updateChannelApiPath={updateChannelApiPath}
              sendToChannelsApiPath={sendToChannelsApiPath}
              getLeadCreationDetailsPath={getLeadCreationDetailsPath}
              getTreatmentDetailsPath={getTreatmentDetailsPath}
              setTriggerChannelFetch={setTriggerChannelFetch}
              featureLockConfig={featureLockConfig}
              incrementChannelChangeCount={incrementChannelChangeCount}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div key={channelChangeCount} style={{ width: '100%', padding: 0, margin: 0 }}>
            <OutboundChannelListManager
              tenant={tenant}
              userID={userRole !== 'ceo' ? userID : ''}
              providerName={providerKey.EMAILBYDONNA}
              addAccountApiPath={addAccountApiPath}
              getAccountsApiPath={getAccountsApiPath}
              createChannelApiPath={createChannelApiPath}
              getChannelsApiPath={getChannelsApiPath}
              updateChannelApiPath={updateChannelApiPath}
              sendToChannelsApiPath={sendToChannelsApiPath}
              getLeadCreationDetailsPath={getLeadCreationDetailsPath}
              getTreatmentDetailsPath={getTreatmentDetailsPath}
              triggerChannelFetch={triggerChannelFetch}
              featureLockConfig={featureLockConfig}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      {tarmikaAllowed && (
        <Table sx={{ minWidth: 650 }}>
          <TableBody sx={{ background: 'white' }}>
            <TableRow
              key={'tarmika'}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                  background: '#fff',
                },
              }}
              classes={{ root: classes.tableRow }}
              className={{ [classes.lockDiv]: !tarmikaAllowed }}
            >
              <TableCell
                classes={{ body: classes.body }}
                style={{ width: 150 }}
              >
                <img src={tarmika} alt="img" className={classes.partnerImg} />
              </TableCell>
              <TableCell
                classes={{ body: classes.body }}
                style={{ width: 108 }}
              >
                {tarmikaAllowed && fetching && (
                  <Skeleton variant="rectangular" width={170} height={17} />
                )}
                {tarmikaAllowed && success && (
                  <div className={classes.statusMainDiv}>
                    <div
                      className={clsx(classes.statusdiv, {
                        [classes.activeStatus]: tarmikaStatus,
                        [classes.inactiveStatus]: !tarmikaStatus,
                      })}
                    ></div>
                    <Typography
                      className={clsx(classes.bodyText, classes.capitalizeText)}
                    >
                      {tarmikaStatus && 'available'}
                      {!tarmikaStatus && 'unavailable'}
                    </Typography>
                  </div>
                )}
              </TableCell>
              <TableCell
                classes={{ body: classes.body }}
                style={{ width: 787 }}
              >
                {tarmikaAllowed && (
                  <Typography className={classes.bodyText}>
                    A single-entry solution designed to streamline the quoting
                    process
                  </Typography>
                )}
                {!tarmikaAllowed && (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <LockImageIcon />
                    <Typography
                      className={classes.bodyText}
                      style={{ marginLeft: 10, alignSelf: 'center' }}
                    >
                      Upgrade to higher plan to avail this feature
                    </Typography>
                  </div>
                )}
              </TableCell>
              <TableCell classes={{ body: classes.body }} align="right">
                <Button
                  variant="contained"
                  className={clsx(classes.configureButton, {
                    [classes.blurredElement]: !tarmikaAllowed,
                  })}
                  disabled={!tarmikaAllowed}
                  classes={{
                    startIcon: classes.startIcon,
                  }}
                  startIcon={<ConfigurationIcon />}
                  onClick={() => {
                    setOpenConfiguration(true);
                  }}
                >
                  Configure
                </Button>
              </TableCell>
              <Dialog
                open={openConfiguration}
                onClose={() => setOpenConfiguration(false)}
                maxWidth="xs"
                fullWidth={true}
              >
                <DialogTitle classes={{ root: classes.titleRoot }}>
                  <img src={tarmika} alt="img" height={50} />
                </DialogTitle>
                <Divider />
                <DialogContent classes={{ root: classes.contentRoot }}>
                  <div className={classes.enableSwitch}>
                    <Typography>Enabled</Typography>
                    {success && (
                      <Switch
                        checked={tarmikaEnableChecked}
                        onChange={() => {
                          if (!tarmikaEnableChecked) {
                            setTarmikaEnableChecked(true);
                          } else {
                            setTarmikaEnableChecked(false);
                          }
                        }}
                        classes={{
                          root: classes.switchButton,
                        }}
                      />
                    )}
                  </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button autoFocus onClick={() => handleTarmikaClose()}>
                    Close
                  </Button>
                  <Button autoFocus onClick={() => handleTarmikaChecked()}>
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default function IntegrationTable(props) {
  const classes = useStyles();

  return <IntegrationTableRow />;
}
