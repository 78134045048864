import React from 'react';
import TrialExpiry from '@aureus/stripe-billing-automation/lib/components/TrialExpiry/TrialExpiry';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { JiraSupportButtonWithDialog } from '../Jira';

const PlanDetails = () => {
  const { user } = useContext(UserContext);
  const isBillingAllowed = user.isBillingAllowed();
  const tenantName = user.getTenant();
  const application = 'donna';
  const endpointGetSubscriptionDetails =
    '/api/stripe/v1/getSubscriptionDetails';

  return (
    <>
      {isBillingAllowed && (
        <TrialExpiry
          endpointGetSubscriptionDetails={endpointGetSubscriptionDetails}
          application={application}
          tenant={tenantName}
          JiraSupportButtonWithDialog={JiraSupportButtonWithDialog}
        />
      )}
    </>
  );
};

export default PlanDetails;
