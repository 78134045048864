import React from 'react';
import PropTypes from 'prop-types';
import { Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { OneView } from './../OneView';
import { QuickViewModal } from './../QuickView';
import {
  dataGridScopes,
  DataGridDataProvider,
  dataGridViewTypes,
  ClientSideGrid,
} from '@aureus/donna-data-grid';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import defaultContext from '../../Enums/defaultContext';
import managerConfig from '../OneView/ManagerConfig.json';
import useGetData from '../DataGridData/useGetData';

const SELECTION_PROMPT = 'Select an insight category';
const getDataEndpoint = '/api/tableau/v1/view';
const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';
const allowListRemoval = false;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardsContainer: {
    flexGrow: 1,
  },
  title: {
    fontWeight: 600,
  },
  subTitle: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(8),
  },
}));

const getTitle = (customers) => {
  let title = SELECTION_PROMPT;
  let subTitle = '';
  if (Array.isArray(customers) && customers.length > 0) {
    try {
      const customer = customers[0];
      const { Main, Household } = customer;
      const { customerDetails } = Household[Main];
      const { displayMessage, displayMessageDescription } = customerDetails;
      title = displayMessage;
      subTitle = displayMessageDescription;
    } catch (error) {
      title = '';
      subTitle = '';
    }
  }
  return { title, subTitle };
};

const InsightCustomers = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { customers, onSelect, insightViewName } = props;
  const { title, subTitle } = getTitle(customers);
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();

  let hasCustomers = false;
  if (Array.isArray(customers)) {
    hasCustomers = true;
  }
  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle2" className={classes.subTitle}>
        {subTitle}
      </Typography>
      <div className={classes.cardsContainer}>
        {hasCustomers && (
          <Route
            path={`${match.url}/${defaultContext}/oneview/:customerId/:selectedView?`}
          >
            <OneView quick={true} />
          </Route>
        )}
        {hasCustomers && (
          <Route
            path={`${match.url}/${defaultContext}/quickview/:customerId/:context?`}
          >
            <QuickViewModal
              backPath={match.url}
              quick={true}
              modalTitle={'Insights'}
              customers={customers}
              prefixContext={true}
            />
          </Route>
        )}
        {hasCustomers && (
          <DataGridDataProvider
            scope={dataGridScopes.INSIGHTS}
            getDataEndpoint={getDataEndpoint}
            contextData={customers}
            initialView={dataGridViewTypes.CARD_VIEW}
            useGetData={useGetData}
          >
            <ClientSideGrid
              scope={dataGridScopes.INSIGHTS}
              insertUserViewEndpoint={insertUserViewEndpoint}
              getUserViewNamesEndpoint={getUserViewNamesEndpoint}
              getUserViewEndpoint={getUserViewEndpoint}
              deleteUserViewEndpoint={deleteUserViewEndpoint}
              onSelect={onSelect}
              MyListContext={MyListContext}
              useOverallTrace={useOverallTrace}
              gaTracking={gaTracking}
              tenant={tenant}
              allowListRemoval={allowListRemoval}
              managerConfig={managerConfig}
              insightViewName={insightViewName}
              isInsight={true}
            />
          </DataGridDataProvider>
        )}
      </div>
    </div>
  );
};

InsightCustomers.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export { InsightCustomers };
