import CONTEXT from '@aureus/cov-context';

export const PROPERTIES = {
  HOME_SCREEN: 'HOME_SCREEN',
};

export const VALUES = {
  [PROPERTIES.HOME_SCREEN]: {
    DEFAULT: 'DEFAULT',
    RETAIN: 'RETAIN',
    RENEW: 'RENEW',
    MY_LIST: 'MY_LIST',
    ROUNDOUT: 'ROUNDOUT',
  },
};

export const isValueValid = (property, value) => {
  return PROPERTIES[property] && VALUES[property] && VALUES[property][value];
};

export const MAPPING = {
  [PROPERTIES.HOME_SCREEN]: {
    CONTEXT: {
      RETAIN: CONTEXT.RETAIN,
      RENEW: CONTEXT.RENEWAL,
      ROUNDOUT: CONTEXT.ROUNDOUT,
      MY_LIST: 'myList',
    },
  },
};

export const getContextFromHomeScreenValue = (homeScreen) => {
  const contextMapping = MAPPING[PROPERTIES.HOME_SCREEN].CONTEXT;
  const value = VALUES[PROPERTIES.HOME_SCREEN][homeScreen];
  return contextMapping[value];
};

export const getHomeScreenValueForContext = (context) => {
  const { CONTEXT } = MAPPING[PROPERTIES.HOME_SCREEN];
  const key = Object.keys(CONTEXT).find((key) => CONTEXT[key] === context);
  if (key) {
    return VALUES[PROPERTIES.HOME_SCREEN][key];
  }
};
