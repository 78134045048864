export const setUserDisable = async ({ userID }) => {
  const path = `/api/admin/v1/user/disable/${userID}`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const userDetails = {};
  const response = await fetch(path, {
    method: 'POST',
    headers,
    body: JSON.stringify(userDetails),
    cache: 'no-store',
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const data = await response.json();
  const status = response.status;
  return { status, data };
};
