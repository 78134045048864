import axios from 'axios';
import { useEffect, useState } from 'react';

const path = '/api/userConfig/v1/getUserConfig';

function useUserConfig(load, stopLoad) {
  const [userConfig, setUserConfig] = useState((prevUserConfig) => {
    let data;
    if (prevUserConfig && prevUserConfig.data && prevUserConfig.success) {
      data = prevUserConfig.data;
    }
    return {
      fetching: false,
      success: false,
      error: false,
      data: data,
    };
  });
  if (load === undefined) {
    load = true;
  }
  useEffect(() => {
    const getUserConfig = async () => {
      setUserConfig({
        fetching: true,
        success: false,
        error: false,
        data: userConfig.data,
      });
      const { status, data } = await axios.get(
        `${path}?timestamp=${new Date().getTime()}`
      );
      if (status !== 200 || (data && data.error)) {
        setUserConfig({
          fetching: false,
          success: false,
          error: true,
          data: userConfig.data,
        });
      } else {
        setUserConfig({
          fetching: false,
          success: true,
          error: false,
          data: data.data,
        });
      }
    };
    if (load) {
      if (typeof stopLoad === 'function') {
        stopLoad();
      }
      getUserConfig();
    }
  }, [stopLoad, load, userConfig.data]);
  return userConfig;
}

export default useUserConfig;
