import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  tabs: {
    textTransform: 'capitalize',
    textAlign: 'center',
    fontSize: 14,
    height: 20,
    minHeight: 20,
    padding: '0 7px',
    color: '#4A4A4A',
    fontWeight: '500',
    opacity: 1,
    minWidth: 'fit-content',
    width: 'fit-content',
    marginRight: 64,
  },
  active: {
    background: '#2D6089',
  },
  activeTab: {
    fontWeight: 'bold',
    color: '#2D6089',
  },
  scroller: {
    height: 27,
    minHeight: 27,
    borderBottom: '1px solid #D1DEE6',
  },
}));

export default function RecommendationTabs(props) {
  const { selectedTab, setSelectedTab } = props;
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box>
      <Tabs
        classes={{
          indicator: classes.active,
          root: classes.scroller,
        }}
        value={selectedTab}
        onChange={handleChange}
        aria-label="donna app recommendation settings tab"
      >
        <Tab
          label="Personal Lines"
          value={'Personal'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'Personal',
          })}
        />
        <Tab
          label="Commercial Lines"
          value={'Commercial'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'Commercial',
          })}
        />
        <Tab
          label="Others"
          value={'Others'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'Others',
          })}
        />
      </Tabs>
    </Box>
  );
}
