import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';

function QuickViewError(props) {
  const { customerId } = props;
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {'Cannot get QuickView for Customer ID "' + customerId + '"'}
    </Alert>
  );
}

QuickViewError.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export { QuickViewError };
