import { useState, useEffect } from 'react';

function useViewDetails(path, parameters) {
  const [viewDetails, setViewDetails] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getViewDetails = async () => {
      setViewDetails({
        fetching: true,
        error: false,
        success: false,
        data: undefined,
      });
      try {
        const response = await fetch(path, {
          cache: 'no-store',
        });
        if (response.redirected) {
          window.location.href = response.url;
        }
        const responseObject = await response.json();
        const { error, errorMessage, data } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setViewDetails({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (error) {
        setViewDetails({
          fetching: false,
          error: true,
          success: false,
          data: undefined,
        });
      }
    };
    getViewDetails();
  }, [path, parameters]);
  return viewDetails;
}

export { useViewDetails };
