import { useState, useEffect } from 'react';

const path = '/api/user/setupUserProfile';

function useSetupUserProfile() {
  const [userProfile, setUserProfile] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
    redirectTo: undefined,
  });
  useEffect(() => {
    const getUserProfile = async () => {
      setUserProfile({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(path);
        if (response.redirected) {
          window.location.href = response.url;
        }
        const { error, errorMessage, data } = await response.json();
        if (error) {
          throw new Error(errorMessage);
        }
        setUserProfile({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (error) {
        setUserProfile({ fetching: false, error: true, success: false });
      }
    };
    getUserProfile();
  }, []);
  return userProfile;
}

export { useSetupUserProfile };
