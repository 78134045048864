import { createContext } from 'react';

const defaultMyListContext = {
  fetching: false,
  error: false,
  success: false,
  data: undefined,
  reload: () => {},
  isCustomerInMyList: () => {},
};

const MyListContext = createContext(defaultMyListContext);

export { MyListContext };
