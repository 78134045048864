import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tabs: {
    textTransform: 'capitalize',
    textAlign: 'center',
    fontSize: 18,
    height: 20,
    minHeight: 20,
    padding: '0 7px',
    color: '#4A4A4A',
    fontWeight: '500',
    opacity: 1,
    minWidth: 'fit-content',
    width: 'fit-content',
    marginRight: 64,
  },
  active: {
    background: '#2D6089',
  },
  activeTab: {
    fontWeight: 'bold',
    color: '#2D6089',
  },
  scroller: {
    height: 27,
    minHeight: 27,
    borderBottom: '1px solid #D1DEE6',
  },
}));

export default function SettingTabs(props) {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const locationArr = location.pathname.split('/');
  const selectedTab = locationArr[locationArr.length - 1];
  const history = useHistory();
  const handleChange = (event, newValue) => {
    history.push(`${match.url}/${newValue}`);
  };
  return (
    <Box>
      <Tabs
        classes={{
          indicator: classes.active,
          root: classes.scroller,
        }}
        value={selectedTab}
        onChange={handleChange}
        aria-label="donna app settings tab"
      >
        <Tab
          label="Integrations"
          value={'integration'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'integration',
          })}
        />
        <Tab
          label="Advanced"
          value={'advance'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'advance',
          })}
        />
        <Tab
          label="Recommendation Settings"
          value={'recommendation'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'recommendation',
          })}
        />
        <Tab
          label="Notification Settings"
          value={'notification'}
          className={clsx(classes.tabs, {
            [classes.activeTab]: selectedTab === 'notification',
          })}
        />
      </Tabs>
    </Box>
  );
}
