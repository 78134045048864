import React, { useContext } from 'react';
import { Onboarding, AgencyVision } from '@aureus/donna-onboarding';
import Integration from '../DonnaSetting/Integration';
import { UserManagement } from '../UserManagement';
import useGetPlansMapping from '../UserContextProvider/useGetPlansMapping';
import { setPlanMapping } from '../../utils/donnaPlansUtil';
import { setPlanMapping as setStripePlanMapping } from '@aureus/stripe-billing-automation/lib/utils/donnaPlansUtil';
import { UserContext } from '../UserContext';

export function Setup() {
  const { user } = useContext(UserContext);
  const isAgencyVisionSetup = user.isAgencyVisionSetup();
  const basePath = '/agency-setup';
  const getStageProgressEndpoint = '/api/v1/onboarding/getStageProgress';
  const getActivationDetailsEndPoint =
    '/api/v1/onboarding/getActivationDetails';
  const setActivationDetailsEndPoint =
    '/api/v1/onboarding/saveActivationDetails';
  const getBillingDetailsEndPoint = '/api/v1/onboarding/getBillingInfo';
  const addBillingInfoEndpoint = '/api/v1/onboarding/addBillingInfo';
  const deletePaymentMethodEndpoint = '/api/v1/onboarding/deletePaymentMethod';
  const checkConnectionEndpoint = '/api/v1/onboarding/checkConnection';
  const storeCredentialsEndPoint = '/api/v1/onboarding/storeCredentials';
  const updateBillingInfoEndpoint = '/api/v1/onboarding/updateBillingInfo';
  const updateIntegrationsDetailsEndpoint =
    '/api/v1/onboarding/updateIntegrationDetails';
  const updateUserManagementDetailsEndpoint =
    '/api/v1/onboarding//updateUserManagementDetails';
  const updateDataProcessingDetailsEndpoint =
    '/api/v1/onboarding/updateDataProcessingDetails';
  const getDataProcessingDetails =
    '/api/v1/onboarding/getDataProcessingDetails';
  const getAllDetailsEndpoint = '/api/v1/onboarding/getAllDetails';
  const getAgencyCredsEndpoint = '/api/v1/onboarding/getAgencyCreds';
  const validateCouponEndPoint = '/api/v1/onboarding/validateCoupon';
  const getManualSetupDetailsEndPoint =
    '/api/v1/onboarding/getManualSetupDetails';
  const getAllPlansEndpoint = '/api/v1/onboarding/getAllPlans';
  const getPayNowDetailsEndpoint = '/api/v1/onboarding/getPayNowDetails';
  const addPayNowDetailsEndpoint = '/api/v1/onboarding/addPayNowDetails';
  const getTaxDetailsEndpoint = '/api/v1/onboarding/getTaxDetails';

  const { success: planMappingSuccess, data: plansMapping } =
    useGetPlansMapping();
  setPlanMapping(plansMapping);
  setStripePlanMapping(plansMapping);

  return (
    <>
      {planMappingSuccess && (
        <>
          {!isAgencyVisionSetup && (
            <Onboarding
              basePath={basePath}
              getStageProgressEndpoint={getStageProgressEndpoint}
              getActivationDetailsEndPoint={getActivationDetailsEndPoint}
              setActivationDetailsEndPoint={setActivationDetailsEndPoint}
              getBillingDetailsEndPoint={getBillingDetailsEndPoint}
              addBillingInfoEndpoint={addBillingInfoEndpoint}
              deletePaymentMethodEndpoint={deletePaymentMethodEndpoint}
              checkConnectionEndpoint={checkConnectionEndpoint}
              storeCredentialsEndPoint={storeCredentialsEndPoint}
              integrationWrapper={Integration}
              userManagementWrapper={UserManagement}
              updateBillingInfoEndpoint={updateBillingInfoEndpoint}
              updateIntegrationsDetailsEndpoint={
                updateIntegrationsDetailsEndpoint
              }
              updateUserManagementDetailsEndpoint={
                updateUserManagementDetailsEndpoint
              }
              getDataProcessingDetails={getDataProcessingDetails}
              updateDataProcessingDetailsEndpoint={
                updateDataProcessingDetailsEndpoint
              }
              getAllDetailsEndpoint={getAllDetailsEndpoint}
              getAgencyCredsEndpoint={getAgencyCredsEndpoint}
              validateCouponEndPoint={validateCouponEndPoint}
              getManualSetupDetailsEndPoint={getManualSetupDetailsEndPoint}
              getAllPlansEndpoint={getAllPlansEndpoint}
              getPayNowDetailsEndpoint={getPayNowDetailsEndpoint}
              addPayNowDetailsEndpoint={addPayNowDetailsEndpoint}
              getTaxDetailsEndpoint={getTaxDetailsEndpoint}
              isAgencyVisionSetup={isAgencyVisionSetup}
            />
          )}
          {isAgencyVisionSetup && (
            <AgencyVision
              basePath={basePath}
              getStageProgressEndpoint={getStageProgressEndpoint}
              getActivationDetailsEndPoint={getActivationDetailsEndPoint}
              setActivationDetailsEndPoint={setActivationDetailsEndPoint}
              getAllPlansEndpoint={getAllPlansEndpoint}
              checkConnectionEndpoint={checkConnectionEndpoint}
              storeCredentialsEndPoint={storeCredentialsEndPoint}
              getManualSetupDetailsEndPoint={getManualSetupDetailsEndPoint}
              getAllDetailsEndpoint={getAllDetailsEndpoint}
              getAgencyCredsEndpoint={getAgencyCredsEndpoint}
              validateCouponEndPoint={validateCouponEndPoint}
              isAgencyVisionSetup={isAgencyVisionSetup}
            />
          )}
        </>
      )}
    </>
  );
}
