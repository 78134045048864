import React from 'react';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import JiraCustomerRequests from '@aureus/jira-service-desk-forms/lib/components/RequestHistory/JiraCustomerRequests';

const API_PATH = '/api/jira/v1/getRequests';
const REOPEN_PATH = '/api/jira/v1/reOpen';

export function Requests() {
  const { user } = useContext(UserContext);
  const email = user.getEmail();
  return (
    <JiraCustomerRequests
      endpoint={API_PATH}
      reopenEndpoint={REOPEN_PATH}
      customerEmail={email}
    />
  );
}
