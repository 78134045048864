import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { AppLogoAgencySetup } from './AppLogoAgencySetup';
import { NeedHelpIcon } from './Icons';
import { AgencySetupUserInfo } from './AgencySetupUserInfo';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  appBar: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.down('md')]: {
      height: 147,
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
    },
    boxShadow: '0px 0px 10px rgba(45, 96, 137, 0.5)',
  },
  gridContent: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  needHelp: {
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 90,
  },
}));

export function HeaderAgencySetup() {
  const classes = useStyles();
  const gridPoints = {
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.gridRoot}>
            <Grid container spacing={2} justify="space-between">
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
                spacing={1}
                justify="flex-start"
                align="center"
                container
              >
                <AppLogoAgencySetup />
              </Grid>
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
                className={classes.gridContent}
              >
                <a
                  href="https://aureusanalytics.atlassian.net/servicedesk/customer/portal/6"
                  target="_blank"
                >
                  <div className={classes.needHelp}>
                    Need Help <NeedHelpIcon />
                  </div>
                </a>
                <AgencySetupUserInfo />
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
