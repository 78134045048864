import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { getLabelForRole } from '@aureus/donna-roles';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    padding: '9px 20px',
    background: '#CBD5DD',
  },
  saveChangesBtn: {
    background: '#2D6089',
    borderRadius: 4,
    '&:hover': {
      background: '#2D6089',
    },
  },
  saveLabel: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
  closeBtn: {
    background: '#d5dfe7',
    borderRadius: 4,
    '&:hover': {
      background: '#d5dfe7',
    },
  },
  closeBtnLabel: {
    color: '#2D6089',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
}));

export function DisableUserConfirmationDialog(props) {
  const { open, onClose, onConfirm, userData } = props;
  let firstName, lastName, emailID, userType, userInitials;
  if (userData) {
    ({ firstName, lastName, emailID, userType, userInitials } = userData);
  }
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle classes={{ root: classes.titleRoot }}>
        Suspend User
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{`${
          !!userInitials ? userInitials : 'User'
        } - ${firstName} ${lastName} (${getLabelForRole(
          userType
        )}) account with email id ${emailID} will be suspended. This user will be prevented from logging in into the app again.`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          classes={{ root: classes.closeBtn, label: classes.closeBtnLabel }}
          onClick={onClose}
          color="primary"
        >
          Close
        </Button>
        <Button
          classes={{ root: classes.saveChangesBtn, label: classes.saveLabel }}
          onClick={confirm}
          color="primary"
        >
          Suspend
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisableUserConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userInitials: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    reportsTo: PropTypes.number.isRequired,
    location: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
  }),
};
