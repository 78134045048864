import React from 'react';
import { PropTypes } from 'prop-types';
import {
  dataGridViewTypes,
  dataGridScopes,
  ServerSideGrid,
} from '@aureus/donna-data-grid';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import CONTEXT from '@aureus/cov-context';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import managerConfig from '../OneView/ManagerConfig.json';
import { isTableauDownloadAllowedForPlan } from '../UserContextProvider/plans';

const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';
const validateViewNameEndpoint = '/api/view/v1/validateViewName';
const updateViewNameEndpoint = '/api/view/v1/updateViewName';

const CardsWithFilter = (props) => {
  const { context, onSelect, reload, initialSearch } = props;
  let initialView = '';
  if (
    context === CONTEXT.RETAIN ||
    context === CONTEXT.RENEWAL ||
    context === CONTEXT.ROUNDOUT ||
    initialSearch
  ) {
    initialView = dataGridViewTypes.CARD_VIEW;
  }
  if (context === dataGridScopes.SEARCH) {
    initialView = dataGridViewTypes.TABLE_VIEW;
  }

  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const allowDownload = isTableauDownloadAllowedForPlan(planType);

  return (
    <>
      <ServerSideGrid
        scope={context}
        insertUserViewEndpoint={insertUserViewEndpoint}
        getUserViewNamesEndpoint={getUserViewNamesEndpoint}
        getUserViewEndpoint={getUserViewEndpoint}
        deleteUserViewEndpoint={deleteUserViewEndpoint}
        onSelect={onSelect}
        initialSearch={initialSearch}
        MyListContext={MyListContext}
        useOverallTrace={useOverallTrace}
        gaTracking={gaTracking}
        tenant={tenant}
        managerConfig={managerConfig}
        allowDownload={allowDownload}
        context={context}
        reload={reload}
        initialView={initialView}
        validateViewNameEndpoint={validateViewNameEndpoint}
        updateViewNameEndpoint={updateViewNameEndpoint}
      />
    </>
  );
};

CardsWithFilter.propTypes = {
  showFilter: PropTypes.bool,
  showSort: PropTypes.bool,
  reload: PropTypes.func,
  defaultSortType: PropTypes.string,
  initialSearch: PropTypes.string,
};

CardsWithFilter.defaultProps = {
  showFilter: true,
  showSort: true,
  initialSearch: undefined,
};

export default CardsWithFilter;
