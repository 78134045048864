import { useState } from 'react';
import axios from 'axios';

export const CreateRequest = async (fields, endpoint, onSuccess) => {
  let requests = {
    fetching: false,
    success: false,
    error: false,
    refID: '',
  };
  let formData = new FormData();
  formData.append('summary', fields.summary);
  formData.append('description', fields.description);
  formData.append('requestType', fields.category);

  await axios
    .post(endpoint, formData)
    .then((response) => {
      if (!response.data.error) {
        requests = {
          ...requests,
          fetching: false,
          success: true,
          error: false,
          refID: response.data.issueKey,
        };
        onSuccess(response.data.issueKey);
      } else {
        requests = {
          ...requests,
          fetching: false,
          success: false,
          error: true,
        };
      }
    })
    .catch((error) => {
      requests = { ...requests, fetching: false, success: false, error: true };
    });

  return requests;
};

// Top level component
const useRequestForUpdate = () => {
  const [requests, setRequestObj] = useState({
    fetching: false,
    success: false,
    error: false,
    refID: '',
    data: [],
  });

  const onSubmit = async (fields, endpoint, onSuccess) => {
    setRequestObj({
      fetching: true,
      success: false,
      error: false,
    });
    let responseObj = await CreateRequest(fields, endpoint, onSuccess);
    setRequestObj({ ...responseObj });
  };
  return { onSubmit, requests };
};

export default useRequestForUpdate;
