import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Switch, FormControl, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@material-ui/core';
import { yellow, green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2)
  },
  head: {
    lineHeight: '14px',
    padding: '16px 30px 20px 20px',
    fontSize: 12,
    fontWeight: '600',
    color: '#8DA9BF',
  },
  body: {
    height: 31,
    padding: '15px 20px',
  },
  partnerImg: {
    width: 128,
    objectFit: 'contain',
  },
  statusMainDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  statusdiv: {
    width: 15,
    height: 15,
    left: 192,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    marginRight: 5,
  },
  activeStatus: {
    background: green[500],
  },
  inactiveStatus: {
    background: yellow[500],
  },
  bodyText: {
    fontSize: 12,
    color: '#4A4A4A',
    fontWeight: 500,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  configureButton: {
    color: '#4C94EC',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: 14,
    backgroundColor: '#e1f0ff',
    lineHeight: '17px',
    padding: '7px 10px',
  },
  tableContainer: {
    boxShadow: 'unset',
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    '& h2': {
      height: 50,
    },
  },
  contentRoot: {
    minHeight: 100,
  },
  enableSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  switchButton: {
    marginLeft: 'auto',
  },
  switchCell: {
    paddingRight: 30
  },
  formControlLabel: {
    width: 180,
    fontWeight: 500,
    color: '#4A4A4A',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    fontSize: theme.typography.fontSize,
  },
  switch_track: {
    backgroundColor: '#C9C9C9',
  },
  switch_base: {
    color: '#A4A4A4',
    '&.Mui-disabled': {
      color: '#A4A4A4',
    },
    '&.Mui-checked': {
      color: '#4C94EC',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4C94EC',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#4C94EC',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4C94EC',
    },
  },
  readOnlyTableContainer: {
    boxShadow: 'unset',
    border: '1 px solid #e0e0e0'
  }
}));

const RecommendationRow = ({ row, changes, onChange }) => {
  const classes = useStyles();
  const { bundleName, bundleStatement, type, enabled, bundleId } = row;
  const current = changes[bundleId] === undefined ? enabled : changes[bundleId];
  return <TableRow key={bundleId}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >
    <TableCell>{bundleStatement}</TableCell>
    <TableCell align="right" className={classes.switchCell}>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={current}
                onChange={() => { onChange(bundleId, !current) }}
                name=""
                size=""
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
              />
            }
            label=""
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    </TableCell>
  </TableRow>;
};

export function RecommendationChangeTable({ rows, changes }) {
  const classes = useStyles();
  const filteredRows = rows.filter(row => changes[row.bundleId] !== undefined);
  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.readOnlyTableContainer}
        classes={{ root: classes.tableContainer }}
      >
        <Table sx={{ minWidth: 350 }} size="small" aria-label="recommendation setting table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ head: classes.head }}>Recommendation Setting</TableCell>
              <TableCell classes={{ head: classes.head }} align="right">
                Enabled/Disabled
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row) => {
              return <TableRow key={row.bundleId}>
                <TableCell>{row.bundleStatement}</TableCell>
                <TableCell align="right">{changes[row.bundleId] ? '✅ Enabled' : '❌ Disabled'}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default function RecommendationTable({ rows, typeFilter, changes, setChanges }) {
  const classes = useStyles();
  const filteredRows = rows.filter(row => row.type.indexOf(typeFilter) > -1);
  const original = {};
  filteredRows.forEach(row => {
    original[row.bundleId] = row.enabled;
  });
  const onChange = (bundleId, enabled) => {
    if (original[bundleId] === enabled) {
      const { [bundleId]: _, ...rest } = changes;
      setChanges(rest);
    } else {
      setChanges({ ...changes, [bundleId]: enabled });
    }
  };
  return (
    <Box className={classes.container}>
      <TableContainer
        component={Paper}
        classes={{ root: classes.tableContainer }}
      >
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="recommendation setting table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ head: classes.head }}>Recommendation Setting</TableCell>
              <TableCell classes={{ head: classes.head }} align="right">
                Enable/Disable
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row) => {
              return <RecommendationRow row={row} onChange={onChange} changes={changes} />;
            })}
          </TableBody>
        </Table>

      </TableContainer>
    </Box>
  )
}