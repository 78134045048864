import React from 'react';
import { JiraFab } from './JiraFab';
import { JiraMenuItem } from './JiraMenuItem';
import { JiraButton } from './JiraButton';
import { WithDialog } from './WithDialog';
import { JiraDrawerItem } from './JiraDrawerItem';
import { JiraButtonDonnaSupport } from './JiraButtonDonnaSupport';

export function JiraFabWithDialog(props) {
  return <WithDialog OpenWith={JiraFab} {...props} />;
}

export function JiraMenuItemWithDialog(props) {
  return <WithDialog OpenWith={JiraMenuItem} {...props} />;
}

export function JiraMenuWithButton(props) {
  return <WithDialog OpenWith={JiraButton} {...props} />;
}

export function JiraDrawerItemWithDialog(props) {
  return <WithDialog OpenWith={JiraDrawerItem} {...props} />;
}

export function JiraSupportButtonWithDialog(props) {
  return <WithDialog OpenWith={JiraButtonDonnaSupport} {...props} />;
}
