import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BlockIcon from '@material-ui/icons/Block';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import IconButton from '@material-ui/core/IconButton';
import { AddEditUserPopup } from './AddEditUserPopup';

const useStyles = makeStyles((theme) => ({
  round: {
    borderRadius: '50%',
    border: '2px solid #195880',
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
  },
  moreIcon: {
    width: 16,
    color: '#195880',
  },
  popoverRoot: {
    padding: 10,
    fontSize: 14,
    fontFamily: 'Barlow',
  },
  options: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  optionIcon: {
    fontSize: 15,
    marginRight: 6,
  },
}));

export function ActionButtons(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpen] = useState(false);
  const {
    rowData,
    onUserActivation,
    onUserDisable,
    onUserEnable,
    onSendActivationEmail,
    onRowUpdate,
    users,
    locations,
  } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const update = (newData, oldData) => {
    onRowUpdate(newData, oldData, setOpen);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton color="primary" aria-label="upload picture" component="span">
        <div className={classes.round} onClick={handleClick}>
          <MoreHorizIcon className={classes.moreIcon} />
        </div>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.popoverRoot,
        }}
      >
        <div
          onClick={() => {
            setOpen(true);
          }}
          className={classes.options}
          style={{ marginTop: 0 }}
        >
          <EditIcon className={classes.optionIcon} />
          Edit
        </div>
        {rowData.hasBasicDetails && !rowData.graviteeProfileCreated && (
          <div
            onClick={(e) => {
              onUserActivation(e, rowData);
            }}
            className={classes.options}
          >
            <VerifiedUserIcon className={classes.optionIcon} />
            Activate User
          </div>
        )}
        {rowData.graviteeProfileActivated && (
          <div
            onClick={(e) => {
              onSendActivationEmail(e, rowData);
            }}
            className={classes.options}
          >
            <MailOutlineIcon className={classes.optionIcon} />
            Resend Activation Mail
          </div>
        )}
        {rowData.graviteeProfileActivated && (
          <div
            onClick={(e) => {
              onUserDisable(e, rowData);
            }}
            className={classes.options}
          >
            <BlockIcon className={classes.optionIcon} />
            Suspend User
          </div>
        )}
        {rowData.graviteeProfileCreated && !rowData.graviteeProfileActivated && (
          <div
            onClick={(e) => {
              onUserEnable(e, rowData);
            }}
            className={classes.options}
          >
            <ExitToAppIcon className={classes.optionIcon} />
            Resume Access
          </div>
        )}
        <AddEditUserPopup
          title="Update User"
          onClose={() => {
            setOpen(false);
          }}
          open={openPopup}
          users={users}
          locations={locations}
          rowData={rowData}
          onRowUpdate={update}
          isEdit={true}
        />
      </Popover>
    </>
  );
}
