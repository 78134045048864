import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import gettingStartedLogo from './../../assets/gettingStarted.png';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PaymentIcon from '@material-ui/icons/Payment';
import helpVideos from './../../assets/helpVideos.png';
import { JiraMenuWithButton } from './../Jira';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(6),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
  },
  imageLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(4),
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    fontSize: 64,
    color: theme.palette.secondary.main,
  },
  subscriptionWarning: {
    maxWidth: theme.breakpoints.values.sm,
    textAlign: 'center',
  },
  feature: {
    textTransform: 'capitalize',
  },
}));

const ImageLink = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { image, actionLabel, linkPath, ButtonComponent, ImageComponent } =
    props;
  const onClick = () => {
    history.push(linkPath);
  };
  const hasButton = ButtonComponent !== undefined;
  const hasImage = ImageComponent !== undefined;
  return (
    <div className={classes.imageLink}>
      {hasImage && ImageComponent}
      {!hasImage && (
        <img className={classes.logo} src={image} alt={actionLabel} />
      )}
      {hasButton && ButtonComponent}
      {!hasButton && (
        <Button variant="outlined" color="primary" onClick={onClick}>
          {actionLabel}
        </Button>
      )}
    </div>
  );
};

const HELP_VIDEOS = '/home/settings/help';
const BILLING_PAYMENTS_PATH = '/home/settings/billing-details';

export function FeatureUsedSplash(props) {
  const classes = useStyles();
  const { isBillingAllowed, feature, count, timePeriod } = props;
  return (
    <Container>
      <div className={classes.centered}>
        {feature ? (
          <>
            <Typography variant="h4">
              {feature} cannot be used more than {count} times in the current{' '}
              {timePeriod}
            </Typography>
          </>
        ) : (
          <Typography variant="h4">
            This feature cannot be used anymore in the current month
          </Typography>
        )}
        {isBillingAllowed && (
          <>
            <Typography
              variant="subtitle2"
              className={classes.subscriptionWarning}
            >
              Upgrade your plan to get unlimited access
            </Typography>
          </>
        )}
        {!isBillingAllowed && (
          <Typography variant="h6">
            Here are some things you can do...
          </Typography>
        )}
      </div>
      <Grid container spacing={3} justify="center">
        {isBillingAllowed && (
          <Grid item xs={3}>
            <ImageLink
              ImageComponent={
                <Avatar className={classes.avatar}>
                  <PaymentIcon className={classes.icon} />
                </Avatar>
              }
              image={gettingStartedLogo}
              linkPath={BILLING_PAYMENTS_PATH}
              actionLabel="Manage Subscription"
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <ImageLink
            ImageComponent={
              <Avatar className={classes.avatar}>
                <ContactSupportIcon className={classes.icon} />
              </Avatar>
            }
            actionLabel="Contact Support"
            ButtonComponent={<JiraMenuWithButton />}
          />
        </Grid>
        <Grid item xs={3}>
          <ImageLink
            image={helpVideos}
            linkPath={HELP_VIDEOS}
            actionLabel="Watch Tutorials & Help Videos"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FeatureUsedSplash.propTypes = {
  isBillingAllowed: PropTypes.bool.isRequired,
  feature: PropTypes.string,
};
