import React from 'react';

const defaultUserContext = {
  fetching: false,
  error: false,
  success: false,
  data: undefined,
};

export const UserContext = React.createContext(defaultUserContext);
