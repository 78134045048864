import { createContext } from 'react';

const defaultUserConfigContext = {
  fetching: false,
  error: false,
  success: false,
  data: undefined,
  setAndReload: () => {},
  reload: () => {},
  getProperty: () => {},
};

const UserConfigContext = createContext(defaultUserConfigContext);

export { UserConfigContext };
