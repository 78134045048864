import { useState, useEffect } from 'react';

const path = '/api/user/donnaTenantDetails';

function useTenantDetails() {
  const [tenantDetails, setTenantDetails] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getTenantDetails = async () => {
      setTenantDetails({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(path);
        const responseObject = await response.json();
        const { error, errorMessage, data } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setTenantDetails({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (error) {
        setTenantDetails({ fetching: false, error: true, success: false });
      }
    };
    getTenantDetails();
  }, []);
  return tenantDetails;
}

export { useTenantDetails };
