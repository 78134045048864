import React from 'react';
import Notifications from '@aureus/notifications-module/dist/components/Notifications/Notifications.js';

const getAllNotificationsEndpoint = '/api/notification/v1/getAllNotifications';
const markNotificationViewedEndpoint =
'/api/notification/v1/markNotificationViewed';
const dismissAllNotificationsEndpoint =
  '/api/notification/v1/dismissAllNotifications';
const getNotificationMarkupEndpoint =
  '/api/notification/v1/getNotificationMarkup';

function NotificationsList() {
  return (
    <Notifications
        getAllNotificationsEndpoint={getAllNotificationsEndpoint}
        markNotificationViewedEndpoint={markNotificationViewedEndpoint}
        dismissAllNotificationsEndpoint={dismissAllNotificationsEndpoint}
        getNotificationMarkupEndpoint={getNotificationMarkupEndpoint}
      />
  );
}

export default NotificationsList;
