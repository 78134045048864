import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { JiraFabWithDialog } from './../Jira';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(6),
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  appBar: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('width'),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightMedium,
  },
  centerContent: {
    flexGrow: 1,
  },
  rightContent: {
    minWidth: 140,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftContent: {
    minWidth: 140,
  },
}));

export function Header(props) {
  const classes = useStyles();
  const history = useHistory();
  const { title, actions, hideBackButton, leftContent, centerContent } = props;
  const hasLeftContent = leftContent !== undefined;
  const hasCenterContent = centerContent !== undefined;
  const hasActions = actions !== undefined;
  const back = () => {
    history.push('/home');
  };
  const showBackButton = hideBackButton !== true && !hasLeftContent;
  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.gridRoot}>
            <div className={classes.leftContent}>
              {showBackButton && (
                <IconButton color="primary" onClick={back} aria-label="back">
                  <ArrowBackIcon />
                </IconButton>
              )}
              {hasLeftContent && leftContent}
            </div>
            {!hasCenterContent && (
              <div className={classes.title}>
                <Typography variant="h5">{title}</Typography>
              </div>
            )}
            {hasCenterContent && (
              <div className={classes.centerContent}>{centerContent}</div>
            )}
            <div className={classes.rightContent}>{hasActions && actions}</div>
          </div>
        </Toolbar>
      </AppBar>
      <JiraFabWithDialog />
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  leftContent: PropTypes.element,
  centerContent: PropTypes.element,
  actions: PropTypes.element,
  hideBackButton: PropTypes.bool,
};
