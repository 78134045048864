const plans = {
  FREE: {
    key: 'free',
    userActivation: false,
    userEnabling: false,
    userDisabling: false,
    enabled: true,
    tableauDownload: false,
    teamAnalytics: false,
    covQuota: true,
    defaultCovQuota: 15,
    partnerApiAccess: false,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    isSubscriptionAllowed: false,
  },
  TRIAL: {
    key: 'trial',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    isSubscriptionAllowed: false,
  },
  ADVANCED_TRIAL: {
    key: 'advanced_trial',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: false,
  },
  ADVANCED_PREMIUM_TRIAL: {
    key: 'advanced_premium_trial',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: false,
  },
  ENTERPRISE_TRIAL: {
    key: 'enterprise_trial',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: false,
  },
  BASIC: {
    key: 'basic',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: false,
    enrichmentDashboard: false,
    benchmark: false,
    isSubscriptionAllowed: true,
  },
  ADVANCED: {
    key: 'advanced',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: true,
  },
  ADVANCED_PREMIUM: {
    key: 'advanced_premium',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: true,
  },
  ENTERPRISE: {
    key: 'enterprise',
    userActivation: true,
    userEnabling: true,
    userDisabling: true,
    enabled: true,
    tableauDownload: true,
    teamAnalytics: true,
    covQuota: false,
    defaultCovQuota: 15,
    partnerApiAccess: true,
    forecast: true,
    enrichmentDashboard: true,
    benchmark: true,
    isSubscriptionAllowed: true,
  },
};

const getEnabledPlansAsList = () => {
  const plansList = [];
  Object.keys(plans).forEach((planInternalKey) => {
    const planDetails = plans[planInternalKey];
    const { enabled } = planDetails;
    if (enabled) {
      plansList.push(planDetails);
    }
  });
  return plansList;
};

const getPlanDetails = (planKey) => {
  return getEnabledPlansAsList().find(
    (planDetails) => planDetails.key === planKey
  );
};

const isUserActivationAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userActivation;
};

const isUserEnablingAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userEnabling;
};

const isUserDisablingAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userDisabling;
};

const isTableauDownloadAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.tableauDownload;
};

const isTeamAnalyticsAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.teamAnalytics;
};

const isCovQuotaForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.covQuota;
};

const getDefaultCovQuota = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails.defaultCovQuota;
};

const isPartnerApiAccessAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.partnerApiAccess;
};

const isForecastAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.forecast;
};

const isEnrichmentDashboardAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.enrichmentDashboard;
};

const isBenchmarkAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.benchmark;
};

const isSubscriptionAllowed = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isSubscriptionAllowed;
};

module.exports = {
  getPlanDetails,
  isUserActivationAllowedForPlan,
  isUserEnablingAllowedForPlan,
  isUserDisablingAllowedForPlan,
  isTableauDownloadAllowedForPlan,
  isCovQuotaForPlan,
  getDefaultCovQuota,
  isPartnerApiAccessAllowedForPlan,
  isTeamAnalyticsAllowedForPlan,
  isForecastAllowedForPlan,
  isEnrichmentDashboardAllowedForPlan,
  isBenchmarkAllowedForPlan,
  isSubscriptionAllowed,
};
