import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { UploadImageIcon } from '../Header/Icons';
import { ImageUploadDialog } from './ImageUploadDialog';
import { usePartnerDetails } from '../usePartnerDetails';
import { actionTypes, useSettingAction } from './useSettingAction';
import { ImageRemoveConfirmationDialog } from './ImageRemoveConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 7,
  },
  uploadButton: {
    backgroundColor: '#d1dee6',
    boxShadow: 'unset',
    padding: '6px 10px',
  },
  disableButton: {
    boxShadow: 'unset',
    padding: '6px 10px',
  },
  removeButton: {
    marginLeft: 20,
  },
  uploadButtonText: {
    textTransform: 'capitalize',
    color: blue[900],
    lineHeight: '17px',
  },
  disableButtonText: {
    textTransform: 'capitalize',
    lineHeight: '17px',
  },
  generalBox: {
    background: '#FFFFFF',
    borderRadius: 5,
    padding: 20,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '20px',
    marginBottom: 7,
  },
  agencyTitle: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: 10,
  },
  uploadDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  imgDiv: {
    background: '#FFFFFF',
    border: '1px solid #D1DEE6',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 300,
    height: 50,
    marginRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  imageHolder: {
    flexShrink: 0,
    width: 200,
    height: 200,
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    borderWidth: 3,
    borderStyle: 'solid',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: 17,
    cursor: 'pointer',
  },
  logoDiv: {
    position: 'relative',
  },
  logo: {
    maxWidth: 300,
  },
  content: {
    paddingLeft: 10,
    paadingRight: 10,
    marginTop: 16,
  },
}));

export default function AdvancedSettings(props) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [toRefresh, setToRefresh] = useState(true);
  const [removeOpen, setRemoveOpen] = useState(false);
  const openRemoveConfirmation = () => {
    setRemoveOpen(true);
  };
  const closeRemoveConfirmation = () => {
    setRemoveOpen(false);
  };
  const clearToRefresh = () => {
    setToRefresh(false);
  };
  const setRefresh = () => {
    setToRefresh(true);
  };
  const { fetching, success, data } = usePartnerDetails(
    toRefresh,
    clearToRefresh
  );
  let agencyObj = {};
  success &&
    data.map((obj, index) => {
      if (obj.key === 'agencyLogo') {
        agencyObj = obj;
      }
    });
  useEffect(() => {
    if (agencyObj) {
      setImageUrl(agencyObj.value);
    }
    return;
  }, [agencyObj]);
  const [action, setAction] = useState({
    type: undefined,
    payload: undefined,
  });
  const {
    fetching: actionFetching,
    error: actionError,
    success: actionSuccess,
  } = useSettingAction(action, setRefresh);
  useEffect(() => {
    if (actionSuccess) {
      setRefresh();
    }
  }, [actionSuccess]);
  const removeAvatar = () => {
    setAction({
      type: actionTypes.REMOVE_AVATAR,
      payload: {},
    });
  };
  const { setOpenConfiguration } = props;

  const closeUpload = () => {
    setUploadOpen(false);
  };
  return (
    <Box className={classes.content}>
      <ImageUploadDialog
        open={uploadOpen}
        onClose={closeUpload}
        onSuccess={setRefresh}
        avatarURL={imageUrl}
      />
      <Typography className={classes.heading}>General</Typography>
      <Box className={classes.generalBox}>
        <Typography className={classes.agencyTitle}>Add agency logo</Typography>
        <Box className={classes.uploadDiv}>
          <Box className={classes.imgDiv}>
            <Box className={classes.logoDiv}>
              {fetching && (
                <Skeleton variant="rectangular" width={300} height={50} />
              )}

              {imageUrl && (
                <img
                  className={classes.logo}
                  src={imageUrl}
                  height="50"
                  alt="img"
                />
              )}
            </Box>
          </Box>
          <label htmlFor="contained-button-file">
            {fetching && (
              <Skeleton variant="rectangular" width={122} height={29} />
            )}
            {success && (
              <Button
                variant="contained"
                component="span"
                startIcon={<UploadImageIcon />}
                className={classes.uploadButton}
                onClick={() => {
                  setUploadOpen(true);
                }}
              >
                <Typography
                  variant="button"
                  className={classes.uploadButtonText}
                >
                  {!imageUrl && success && 'Upload Image'}
                  {imageUrl && success && 'Change Image'}
                </Typography>
              </Button>
            )}
            {actionError && (
              <Button
                variant="contained"
                component="span"
                className={classes.disableButton}
                disabled
              >
                <Typography
                  variant="button"
                  className={classes.disableButtonText}
                >
                  Cannot set image
                </Typography>
              </Button>
            )}
            {imageUrl && (
              <Button
                variant="contained"
                component="span"
                className={clsx(classes.uploadButton, classes.removeButton)}
                onClick={() => {
                  openRemoveConfirmation();
                }}
              >
                <Typography
                  variant="button"
                  className={classes.uploadButtonText}
                >
                  Remove Image
                </Typography>
              </Button>
            )}
          </label>
        </Box>
        <Typography variant="caption">
          Recommended size 300 x 50 px upto 2MB
        </Typography>
      </Box>
      <ImageRemoveConfirmationDialog
        open={removeOpen}
        onClose={closeRemoveConfirmation}
        onConfirm={removeAvatar}
        avatarURL={imageUrl}
      />
    </Box>
  );
}
