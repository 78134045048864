import { useEffect, useState } from 'react';
import axios from 'axios';
import { dataGridScopes } from '@aureus/donna-data-grid';
import getCardDatafromTableData from './getCardDataFromTableData';
import getScopeBasedFilter from '../../utils/scopeBasedFilters';

const dataViews = {
  [dataGridScopes.RETAIN]: [{ scope: 'search', view: 'retention' }],
  [dataGridScopes.RENEW]: [{ scope: 'search', view: 'renewal' }],
  [dataGridScopes.ROUND_OUT]: [{ scope: 'search', view: 'roundout' }],
  [dataGridScopes.MY_LIST]: [{ scope: 'search', view: 'mylist' }],
};

const useGetTableauData = (
  scope,
  getTableauDataEndpoint,
  fetchCardData,
  stopDataFetch,
  setCustomers,
  setCustomerCount
) => {
  const [tableauData, setTableauData] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });

  const dataScope = dataViews[scope][0].scope;
  const dataView = dataViews[scope][0].view;

  const path = `${getTableauDataEndpoint}/${dataScope}/${dataView}`;
  const pageNumber = 1;
  const pageSize = 100;

   let filters = {};
   if (scope === dataGridScopes.ROUND_OUT || scope === dataGridScopes.RETAIN) {
     filters = getScopeBasedFilter(scope);
   }

   const filterModel = filters;

  const reqObject = {
    selectColumns: [],
    pagination: {
      pageNumber,
      pageSize,
    },
    filter: filterModel,
    sort: [],
  };

  const headers = { 'Content-Type': 'application/json; charset=utf-8' };

  useEffect(() => {
    const dataRequest = async () => {
      setTableauData({
        fetching: true,
        success: false,
        error: false,
        data: undefined,
      });

      try {
        stopDataFetch();
        const { status, data } = await axios.post(path, reqObject, {
          headers,
        });
        if (status !== 200 || data.error === true) {
          throw new Error();
        }

        const { rows, lastRow } = data;
        const latestCardData = getCardDatafromTableData(rows);
        if (latestCardData.length === 0) {
          setCustomers([]);
          setCustomerCount(0);
        } else {
          latestCardData.map((customer, index) => {
            customer.originalIndex = index;
            return customer;
          });
          setCustomers(latestCardData);
          setCustomerCount(lastRow);
        }
        setTableauData({
          fetching: false,
          success: true,
          error: false,
          data: latestCardData,
        });
      } catch (error) {
        setTableauData({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      }
    };

    if (fetchCardData) {
      dataRequest();
    }
  }, [scope, headers, getTableauDataEndpoint, path, reqObject]);
  return tableauData;
};

export default useGetTableauData;
