require('dotenv').config();

const legends = [
  {
    key: 'ams_support',
    legend: '^^',
    title:
      'If you have more than 1 AMS system, the pricing may vary. Please call us for more information',
    order: 5,
  },
  {
    key: 'data_enrichment',
    legend: '****',
    title: 'Hit rates may vary based on several parameters',
    order: 3,
  },
  {
    key: 'Kotter Group',
    legend: '^',
    title: 'Coming Soon',
    order: 4,
  },
  {
    key: 'Agency Zoom',
    legend: '',
    title: 'Coming Soon',
    order: 4,
  },
  {
    key: 'Lightspeed',
    legend: '^',
    title: 'Coming Soon',
    order: 4,
  },
  {
    key: 'API Based Access',
    legend: '^',
    title: 'Coming Soon',
    order: 4,
  },
];

const icons = [
  {
    key: 'SentiMeter™',
    icon: 'SentimentSatisfiedOutlined',
  },
  {
    key: 'Real-Time Prediction',
    icon: 'VisibilityOutlined',
  },
  {
    key: 'Trends & Metrics',
    icon: 'Timeline',
  },
  {
    key: 'Data Download',
    icon: 'FileDownloadOutlined',
  },
  {
    key: 'Team Analytics',
    icon: 'QueryStatsOutlined',
  },
  {
    key: 'Insights',
    icon: 'InsertChartOutlinedTwoTone',
  },
  {
    key: 'Foresight',
    icon: 'PersonSearch',
  },
  {
    key: 'Carrier Analytics',
    icon: 'VerifiedUserOutlined',
  },
  {
    key: 'Industry Benchmarking',
    icon: 'CompareOutlined',
  },
  {
    key: 'data_enrichment',
    icon: 'StorageRounded',
  },
];

const planLegends = [
  {
    key: '1',
    plan_type: 'free',
    legend: '*',
    legendText: 'User type limited to CEO/ Principle Role',
    order: 0,
  },
  {
    key: 'Real-Time Prediction',
    plan_type: 'free',
    legend: '**',
    legendText:
      'A maximum of 15 customer views with recommendations can be accessed per month',
    order: 1,
  },
  {
    key: 'Starter',
    plan_type: 'professional',
    legend: '***',
    legendText: 'Additional cost for Premium',
    order: 2,
  },
];

let planMapping;

const setPlanMapping = (data) => {
  planMapping = data;
};

const getEnabledPlansAsList = () => {
  const plansList = [];
  if (planMapping) {
    Object.keys(planMapping).forEach((planInternalKey) => {
      const planDetails = planMapping[planInternalKey];
      const { enabled } = planDetails;
      if (enabled) {
        plansList.push(planDetails);
      }
    });
  }
  return plansList;
};

const getPlanDetails = (planKey) => {
  return getEnabledPlansAsList().find(
    (planDetails) => planDetails.key === planKey
  );
};

const isUserActivationAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userActivation;
};

const isUserEnablingAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userEnabling;
};

const isUserDisablingAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.userDisabling;
};

const isTrendsAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.trends;
};

const isMetricsAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.metrics;
};

const isTableauDownloadAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.tableauDownload;
};

const isTeamAnalyticsAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.teamAnalytics;
};

const isInsightsAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.insights;
};

const isCarrierAnalyticsAllowedForPlans = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.carrierAnalytics;
};

const isCovQuotaForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.covQuota;
};

const getDefaultCovQuota = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails.defaultCovQuota;
};

const isPartnerApiAccessAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.partnerApiAccess;
};

const isForecastAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.forecast;
};

const isEnrichmentDashboardAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.enrichmentDashboard;
};

const isBenchmarkAllowedForPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.benchmark;
};

const isSubscriptionAllowed = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isSubscriptionAllowed;
};

const getIsCustomPlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isCustomPlan;
};

const shouldShowButtons = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.showPlanButtons;
};

const getBasePlan = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails.basePlan;
};

const getWeight = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails.weight;
};

const compareWeight = (planKey, toComparePlanKey) => {
  const planWeight = getWeight(planKey);
  const toComparePlanWeight = getWeight(toComparePlanKey);
  if (planWeight > toComparePlanWeight) {
    return 1;
  } else if (planWeight === toComparePlanWeight) {
    return 0;
  } else {
    return -1;
  }
};

const sortByWeight = (planKeys) => {
  return planKeys.sort(compareWeight);
};

const isDataEnrichmentSwitchRequired = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.dataEnrichmentSwitch;
};

const isBillingCycleDropdownRequired = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.billingCycleDropdown;
};

const isCurrentPlan = (currentPlan, plan) => {
  return currentPlan === plan;
};

const getDisableEnrichmentSwitch = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.disableEnrichmentSwitch;
};

const getHasMultipleBillingCycles = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.hasMultipleBillingCycles;
};

const getOrder = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails.order;
};

const compareOrder = (planKey, toComparePlanKey) => {
  const planOrder = getOrder(planKey);
  const toComparePlanOrder = getOrder(toComparePlanKey);
  if (planOrder > toComparePlanOrder) {
    return 1;
  } else if (planOrder === toComparePlanOrder) {
    return 0;
  } else {
    return -1;
  }
};

const sortByOrder = (planKeys) => {
  return planKeys.sort(compareOrder);
};

const getShowCard = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.showCard;
};

const getConditionalShowCard = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.conditionalShowCard;
};

const isQuoteAllowed = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isQuoteAllowed;
};

const getIsPaidPlan = (plan) => {
  const planDetail = planMapping[plan];
  return planDetail && planDetail.isPaidPlan;
};

const getIcon = (key) => {
  return icons.find((iconDetails) => iconDetails.key === key);
};

const getLegend = (key) => {
  return legends.find((legendDetails) => legendDetails.key === key);
};

const getPlanlegend = (key, planType) => {
  return planLegends.find(
    (planLegendDetails) =>
      planLegendDetails.key === key && planLegendDetails.plan_type === planType
  );
};

const isAgencyZoomAllowed = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isAgencyZoomAllowed;
};

const isPolicyEnrichmentAllowed = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.isPolicyEnrichmentAllowed;
};

const hideDisabled = (planKey) => {
  const planDetails = getPlanDetails(planKey);
  return planDetails && planDetails.hideDisabled;
};

module.exports = {
  getIsCustomPlan,
  shouldShowButtons,
  getBasePlan,
  getWeight,
  compareWeight,
  sortByWeight,
  isDataEnrichmentSwitchRequired,
  isBillingCycleDropdownRequired,
  isCurrentPlan,
  getDisableEnrichmentSwitch,
  getHasMultipleBillingCycles,
  getOrder,
  compareOrder,
  sortByOrder,
  getShowCard,
  getConditionalShowCard,
  getPlanDetails,
  isUserActivationAllowedForPlan,
  isUserEnablingAllowedForPlan,
  isUserDisablingAllowedForPlan,
  isTrendsAllowedForPlan,
  isMetricsAllowedForPlan,
  isTableauDownloadAllowedForPlan,
  isCovQuotaForPlan,
  getDefaultCovQuota,
  isPartnerApiAccessAllowedForPlan,
  isTeamAnalyticsAllowedForPlan,
  isInsightsAllowedForPlan,
  isCarrierAnalyticsAllowedForPlans,
  isForecastAllowedForPlan,
  isEnrichmentDashboardAllowedForPlan,
  isBenchmarkAllowedForPlan,
  isSubscriptionAllowed,
  isQuoteAllowed,
  getIsPaidPlan,
  getIcon,
  getLegend,
  getPlanlegend,
  isAgencyZoomAllowed,
  isPolicyEnrichmentAllowed,
  hideDisabled,
  setPlanMapping,
};
