import React, { useState } from 'react';
import { useParams, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { CustomerCardView } from './CustomerCardView';
import PropTypes from 'prop-types';
import { QuickViewModal } from './../QuickView';
import { OneView } from './../OneView';
import { contextProperties } from './ContextProperties';

const CustomerCardLayout = (props) => {
  const { type, context: inputContext, maxCustomers, path } = props;
  const [customers, setCustomers] = useState([]);
  let { context } = useParams();
  if (inputContext) {
    context = inputContext;
  }

  const currentContextProperty = contextProperties[context];
  const Provider = currentContextProperty.Provider;

  const history = useHistory();
  const match = useRouteMatch();

  let url = match.url !== '/' ? `${match.url}` : `${path}/${context}`;

  const onSelect = (customerId, customersArr) => {
    if (customerId) {
      setCustomers(customersArr);
      history.push(`${url}/quickview/${customerId}/${context}`);
    }
  };

  return (
    <>
      <Route path={`${url}/oneview/:customerId/:selectedView?`}>
        <OneView quick={true} />
      </Route>
      <Route path={`${url}/quickview/:customerId/:context?`}>
        <QuickViewModal
          backPath={path}
          quick={true}
          customers={customers}
          prefixContext={true}
        />
      </Route>
      <Provider
        key={context}
        context={context}
        type={type}
        maxCustomers={maxCustomers}
      >
        <CustomerCardView onSelect={onSelect} {...props} />
      </Provider>
    </>
  );
};

CustomerCardLayout.propTypes = {
  type: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired,
  context: PropTypes.string,
  maxCustomers: PropTypes.number,
  icon: PropTypes.any,
  path: PropTypes.string,
};

export { CustomerCardLayout };
