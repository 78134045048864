import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SproutVideos } from './../Sprout';

export function HelpVideoSettings() {
  return (
    <Switch>
      <Route>
        <SproutVideos guide={false} />
      </Route>
    </Switch>
  );
}