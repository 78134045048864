import ReactGA from 'react-ga4';

const gaEvent = (category, action, label, value) => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.event({
      category: category,
      action: action,
      event_action: action,
      label: label,
      value: value,
    });
  }
};

export { gaEvent };
