import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';

const basePath = '/api/cov/v1/quota';

function useCustomerCount(hasQuota) {
  const [covCount, setCovCount] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  const location = useLocation();
  const pathname = location.pathname.toLocaleLowerCase();
  let customerLocation = '';
  if (pathname.includes('quickview') || pathname.includes('oneview')) {
    customerLocation = location.pathname;
  } else {
    customerLocation = location.pathname;
  }
  useEffect(() => {
    const getCovCount = async () => {
      const URI = `${basePath}`;
      setCovCount({
        fetching: true,
        error: false,
        success: false,
        data: undefined,
      });
      try {
        if (hasQuota) {
          const response = await fetch(URI, {
            cache: 'no-store',
          });
          const responseObject = await response.json();
          const { error, errorMessage } = responseObject;
          if (error) {
            throw new Error(errorMessage);
          }
          setCovCount({
            fetching: false,
            error: false,
            success: true,
            data: responseObject,
          });
        }
      } catch (error) {
        setCovCount({
          fetching: false,
          error: true,
          success: false,
          data: undefined,
        });
      }
    };
    getCovCount();
  }, [customerLocation]);
  return covCount;
}

export { useCustomerCount };
