import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IntegrationTable from './NewIntegrationTable';

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 7,
  },
  content: {
    paddingLeft: 10,
    paadingRight: 10,
    marginTop: 16,
  },
}));

export default function Integration(props) {
  const classes = useStyles();
  const { setOpenConfiguration } = props;
  return (
    <Box className={classes.content}>
      <Typography className={classes.tableHeading}>Our Partners</Typography>
      <IntegrationTable setOpenConfiguration={setOpenConfiguration} />
    </Box>
  );
}
