import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import { ContextCustomerProvider } from './ContextCustomerProvider';
import base64url from 'base64url';
import { TitleHome } from '../Header';
import { SearchResults } from './SearchResults';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const CustomerSearch = (props) => {
  const { type, basePath, backPath } = props;
  const history = useHistory();
  const { searchInput } = useParams();
  let search;
  try {
    search = decodeURIComponent(base64url.decode(searchInput));
  } catch (e) {
    search = '';
  }
  const onSearch = (value) => {
    const searchTermPathParam = base64url(encodeURIComponent(value));
    history.push(`${basePath}/search/input/${searchTermPathParam}`);
  };

  return (
    <div>
      <TitleHome
        icon={ArrowBackIcon}
        onSearch={onSearch}
        searchValue={search}
        titleText={'Search Results'}
        backPath={backPath}
      />

      <div>
        <ContextCustomerProvider context="generic" type={type} search={search}>
          <SearchResults basePath backPath search={search} />
        </ContextCustomerProvider>
      </div>
    </div>
  );
};

CustomerSearch.propTypes = {
  basePath: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  backPath: PropTypes.string,
};

export { CustomerSearch };
