const options = {
  maxRows: 0,
};

const getSheet = (viz, worksheetName) => {
  let sheet;
  const sheetType = viz.getWorkbook().getActiveSheet().getSheetType();
  if (sheetType === 'dashboard') {
    if (worksheetName) {
      sheet = viz
        .getWorkbook()
        .getActiveSheet()
        .getWorksheets()
        .get(worksheetName);
    } else {
      // Use first worksheet if worksheet name is not supplied
      sheet = viz.getWorkbook().getActiveSheet().getWorksheets()[0];
    }
  } else if (sheetType === 'worksheet') {
    sheet = viz.getWorkbook().getActiveSheet();
  } else if (sheetType === 'story') {
    sheet = viz.getWorkbook().getActiveSheet();
  }
  return sheet;
};

const getRowsFromDataTable = (dataTable) => {
  const columns = dataTable.getColumns();
  const columnNames = columns.map((column) => column.getFieldName());
  const data = dataTable.getData();
  const rows = data.map((row) => {
    return row.reduce((accumulator, currentValue, currentIndex) => {
      const key = columnNames[currentIndex];
      accumulator[key] = currentValue.formattedValue;
      return accumulator;
    }, {});
  });
  return rows;
};

const getSheetSummaryData = async (sheet) => {
  const dataTable = await sheet.getSummaryDataAsync(options);
  const summaryData = getRowsFromDataTable(dataTable);
  return summaryData;
};

export const getUnderlyingSummaryData = async (viz, worksheetName) => {
  const sheet = getSheet(viz, worksheetName);
  const summary = getSheetSummaryData(sheet);
  return summary;
};
