import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import SproutVideoList from '@aureus/sprout-videos/lib/components/video-list/video-list-wrapper';

const VIDEO_LIST_PATH = '/api/sprout/v1/getSproutVideos';
const hiddenPlaylistsString = process.env.REACT_APP_ROLE_WISE_HIDDEN_PLAYLISTS;
const hiddenPlaylists = hiddenPlaylistsString
  ? JSON.parse(hiddenPlaylistsString)
  : {};

export function SproutVideos(props) {
  const { guide } = props;
  const { user } = useContext(UserContext);
  const role = user.getTopRole();
  const hidden = hiddenPlaylists[role];
  return (
    <div>
      <Container>
        <SproutVideoList endpoint={VIDEO_LIST_PATH} hiddenPlaylist={hidden} />
      </Container>
    </div>
  );
}

SproutVideos.props = {
  guide: PropTypes.bool.isRequired,
};
