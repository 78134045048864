import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-barlow';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#223945',
      light: 'rgb(78, 96, 106)',
      dark: 'rgb(23, 39, 48)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff8533',
      light: 'rgb(255, 157, 91)',
      dark: 'rgb(178, 93, 35)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      level2: '#f5f5f5',
      level1: '#fff',
    },
  },
  typography: {
    fontWeightBold: 600,
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
