import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.primary.main,
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
}));

export function JiraMenuItem(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <MenuItem className={classes.menuItem} onClick={onClick}>
      <ListItemIcon className={classes.listIcon}>
        <ContactSupportIcon fontSize="small" color="primary" />
      </ListItemIcon>
      Contact Support
    </MenuItem>
  );
}

JiraMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
};
