import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ResetHomePopup } from './ResetHomePopup';
import {
  UserConfigContext,
  PROPERTIES,
  getContextFromHomeScreenValue,
} from './../UserConfig';

const useStyles = makeStyles((theme) => ({
  iconGrid: {
    alignSelf: 'center',
    textAlign: 'end',
    paddingRight: 16,
  },
  updateButton: {
    backgroundColor: '#cddff3',
    color: '#4C94EC',
    textTransform: 'inherit',
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
  },
}));

export const ResetHomeButton = (props) => {
  const { context } = props;
  const { getProperty } = useContext(UserConfigContext);
  const homeScreen = getProperty(PROPERTIES.HOME_SCREEN);
  const homeScreenContext = getContextFromHomeScreenValue(homeScreen);
  const visible = context === homeScreenContext;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      {visible && (
        <>
          <ResetHomePopup
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          />
          <Grid item className={classes.iconGrid}>
            <Button
              className={classes.updateButton}
              onClick={() => setOpen(true)}
            >
              Reset The View
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

ResetHomeButton.propTypes = {
  context: PropTypes.string.isRequired,
};
