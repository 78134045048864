import React from 'react';
import { TabLink } from './../TabRouterUtil/TabLink';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 999999,
    '& .MuiTab-wrapper': {
      color: theme.palette.primary.contrastText,
    },
  },
  wrapper: {
    color: theme.palette.primary.dark,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle1.fontSize,
    letterSpacing: theme.typography.subtitle1.letterSpacing,
    lineHeight: theme.typography.subtitle1.lineHeight,
  },
  wrapperLarge: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: theme.typography.h6.letterSpacing,
    lineHeight: theme.typography.h6.lineHeight,
  },
}));

export function StyledTabLink({ large, ...props }) {
  const classes = useStyles();
  return (
    <TabLink
      {...props}
      classes={{
        selected: classes.selected,
        wrapper: large ? classes.wrapperLarge : classes.wrapper,
      }}
    />
  );
}

export function StyledTab({ large, ...props }) {
  const classes = useStyles();
  return (
    <Tab
      {...props}
      classes={{
        selected: classes.selected,
        wrapper: large ? classes.wrapperLarge : classes.wrapper,
      }}
    />
  );
}
