import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import customerCountImg from '../../assets/customerCount.svg';
import CovCustomerCount from '../../assets/CovCustomerCount.svg';
import { CustomerCountSkeleton } from './TitleSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
  },
  root1: {
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    marginRight: 15,
    padding: '0px 3px',
    borderRadius: 3,
    fontSize: 10,
    height: 17,
  },
  customerImg: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  customerCountImg: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  customerText: {
    fontWeight: 500,
    fontSize: 12,
    color: '#4A4A4A',
  },
  countDiv: {
    fontSize: 12,
    textAlign: 'center',
    color: '#F47F34',
    backgroundColor: '#eedbce',
    padding: '3.2px 10px',
    borderRadius: 5,
    marginLeft: 10,
    '& span': {
      fontWeight: 700,
    },
  },
}));

export function CustomerCountView(props) {
  const classes = useStyles();
  const { view, customerCount, count } = props;

  return (
    <>
      {view !== 'oneview' && (
        <div className={classes.root}>
          <img src={customerCountImg} className={classes.customerImg} />
          <Typography variant="caption">Customer Profile Views:</Typography>
          {customerCount.success && (
            <div className={classes.countDiv}>
              <Typography variant="caption">
                {count && count.used}/{count && count.quota}
              </Typography>
            </div>
          )}
          {customerCount.fetching && <CustomerCountSkeleton />}
        </div>
      )}
      {view === 'oneview' && (
        <div className={classes.root1}>
          <img src={CovCustomerCount} className={classes.customerCountImg} />
          <Typography variant="caption">Customer Profile Views:</Typography>
          {customerCount.success && (
            <div>
              <Typography variant="caption">
                {count && count.used}/{count && count.quota}
              </Typography>
            </div>
          )}
          {customerCount.fetching && <CustomerCountSkeleton />}
        </div>
      )}
    </>
  );
}
