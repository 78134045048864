import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { ProfileCard } from './ProfileCard';
import { useUserDetailsWithLookup } from './../useUserDetailsWithLookup';
import { MyProfileSkeleton } from './MyProfileSkeleton';
import { MyProfileError } from './MyProfileError';
import {
  actionTypes,
  actionTypeFailureMessages,
  useProfileAction,
} from './useProfileAction';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Title } from '../Header/Title';
import { MyProfileIcon } from '../Header/Icons';

export function MyProfile(props) {
  const { guide } = props;
  const [toRefresh, setToRefresh] = useState(true);
  const clearToRefresh = () => {
    setToRefresh(false);
  };
  const setRefresh = () => {
    setToRefresh(true);
  };
  const { fetching, error, success, data } = useUserDetailsWithLookup(
    toRefresh,
    clearToRefresh
  );
  const [action, setAction] = useState({
    type: undefined,
    payload: undefined,
  });
  const {
    fetching: actionFetching,
    error: actionError,
    success: actionSuccess,
  } = useProfileAction(action, setRefresh);
  useEffect(() => {
    if (actionSuccess) {
      setRefresh();
    }
  }, [actionSuccess]);
  const removeAvatar = () => {
    setAction({
      type: actionTypes.REMOVE_AVATAR,
      payload: {},
    });
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (actionError) {
      setSnackbarOpen(actionError);
    }
  }, [actionError]);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error">{actionTypeFailureMessages[action.type]}</Alert>
      </Snackbar>

      <Title icon={MyProfileIcon} titleText="My Profile" />
      <Container>
        {(fetching || actionFetching) && <MyProfileSkeleton />}
        {error && <MyProfileError />}
        {success && !actionFetching && (
          <ProfileCard
            info={data}
            removeAvatar={removeAvatar}
            setToRefresh={setRefresh}
          />
        )}
      </Container>
    </div>
  );
}

MyProfile.props = {
  guide: PropTypes.bool.isRequired,
};
