import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import CardsWithFilter from './CardsWithFilter';
import { OneView } from './../OneView';
import { QuickViewModal } from './../QuickView';
import { dataGridScopes } from '@aureus/donna-data-grid';

const SearchResults = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const { context, basePath, search } = props;

  const onSelect = (customerId, customersArr, context) => {
    if (customerId) {
      setCustomers(customersArr);
      history.push(`${match.url}/quickview/${customerId}/${context}`);
    }
  };
  return (
    <>
      <Route path={`${match.url}/oneview/:customerId/:selectedView?`}>
        <OneView />
      </Route>
      <Route path={`${match.url}/quickview/:customerId/:context?`}>
        <QuickViewModal
          customers={customers}
          modalTitle="Search"
          backPath={match.url}
          context={context}
          prefixContext={false}
        />
      </Route>
      <Route path={`${basePath}/oneview/:customerId/:selectedView?`}>
        <OneView />
      </Route>
      <Route path={`${basePath}/quickview/:customerId/:context?`}>
        <QuickViewModal
          customers={customers}
          backPath={match.url}
          prefixContext={false}
        />
      </Route>
      <CardsWithFilter
        showFilter={false}
        context={dataGridScopes.SEARCH}
        onSelect={onSelect}
        initialSearch={search}
      />
    </>
  );
};

SearchResults.propTypes = {
  fetching: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  context: PropTypes.string,
  onSelect: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  search: PropTypes.string,
};

export { SearchResults };
