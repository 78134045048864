import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Content } from './../Content';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#EDF2F5',
    overflow: 'hidden',
  },
}));

export function Viewport() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Content />
    </div>
  );
}
