import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 30,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#fff',
    fontWeight: 500,
    fontSize: 14,
  },
  menuItem: {
    fontWeight: 500,
    fontSize: 14,
    marginRight: 24,
    '&.Mui-selected': {
      background: '#DEE4E8',
      borderRadius: 3,
      marginLeft: 4,
      marginRight: 4,
    },
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  check: {
    position: 'absolute',
    right: 6,
  },
}));

const ViewSelector = (props) => {
  const classes = useStyles();
  const { value, setValue } = props;
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const check = (
    <div className={classes.check}>
      <CheckIcon color="primary" />
    </div>
  );
  return (
    <>
      <Typography variant="subtitle1" className={classes.label}>
        Select View
      </Typography>
      <FormControl
        className={classes.formControl}
        variant="outlined"
        color="primary"
      >
        <Select
          labelId="view-selector-label"
          id="view-selector"
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.select,
          }}
          color="primary"
          IconComponent={ExpandMoreIcon}
          renderValue={() => {
            if (value) {
              if (value === 'analytics') {
                return 'My Agency';
              } else {
                return 'My Business';
              }
            }
          }}
        >
          <MenuItem value={'analytics'} classes={{ root: classes.menuItem }}>
            My Agency {value === 'analytics' && check}
          </MenuItem>
          <MenuItem value={'my-analytics'} classes={{ root: classes.menuItem }}>
            My Business {value === 'my-analytics' && check}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

ViewSelector.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export { ViewSelector };
