import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '../Header';
import { CarriersIcon } from '../Header/Icons.js';
import { dataGridScopes, ServerSideGrid } from '@aureus/donna-data-grid';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { QuickViewModal } from '../QuickView';
import { OneView } from '../OneView';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { InfobarSkeleton } from './InfobarSkeleton';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import managerConfig from '../OneView/ManagerConfig.json';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(4),
  },
}));

const dimensions = {
  vizWidth: '100%',
  vizHeight: '100px',
};

const getDataEndpoint = '/api/tableau/v1/view';
const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';
const validateViewNameEndpoint = '/api/view/v1/validateViewName';
const updateViewNameEndpoint = '/api/view/v1/updateViewName';

export function CarrierAnalytics(props) {
  const { type } = props;
  let match = useRouteMatch();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const classes = useStyles();
  const analyticsApiPath = `/api/metrics/v1/${type}/carrier`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  return (
    <div>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView quick={true} />
      </Route>
      <Route path={`${match.url}/quickview/:customerId`}>
        <QuickViewModal backPath={match.url} quick={true} />
      </Route>
      <Title icon={CarriersIcon} titleText="Carriers" />
      {fetching && <InfobarSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      <Container>
        <div className={classes.topMargin}>
          <ServerSideGrid
            scope={dataGridScopes.CARRIER_ANALYTICS}
            insertUserViewEndpoint={insertUserViewEndpoint}
            getUserViewNamesEndpoint={getUserViewNamesEndpoint}
            getUserViewEndpoint={getUserViewEndpoint}
            deleteUserViewEndpoint={deleteUserViewEndpoint}
            MyListContext={MyListContext}
            useOverallTrace={useOverallTrace}
            gaTracking={gaTracking}
            tenant={tenant}
            managerConfig={managerConfig}
            validateViewNameEndpoint={validateViewNameEndpoint}
            updateViewNameEndpoint={updateViewNameEndpoint}
          />
        </div>
      </Container>
    </div>
  );
}

CarrierAnalytics.propTypes = {
  type: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
};
