import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { BenchmarkType } from './BenchmarkType';
import { Title } from '../Header';
import { BenchmarkingIcon } from '../Header/Icons.js';

export function Benchmark(props) {
  let match = useRouteMatch();
  const mainType = 'benchmark';
  const type = 'customer';
  return (
    <div>
      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/customer`} />
        </Route>
        <Route path={match.url}>
          <div>
            <Title icon={BenchmarkingIcon} titleText="Benchmarking" />
            <Route path={`${match.path}/:mainType`}>
              <BenchmarkType mainType={mainType} type={type} />
            </Route>
          </div>
        </Route>
      </Switch>
    </div>
  );
}
