import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

const basePath = '/api/cov/v1/quota';

function OneViewQuota(props) {
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();
  const hasQuota = tenantApi.hasCovQuota();
  const [covCount, setCovCount] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const getCovCount = async () => {
      const URI = `${basePath}`;
      setCovCount({ fetching: true, error: false, success: false });
      try {
        if (hasQuota) {
          const response = await fetch(URI, {
            cache: 'no-store',
          });
          const responseObject = await response.json();
          const { error, errorMessage } = responseObject;
          if (error) {
            throw new Error(errorMessage);
          }
          setCovCount({
            fetching: false,
            error: false,
            success: true,
            data: responseObject,
          });
        }
      } catch (error) {
        setCovCount({ fetching: false, error: true, success: false });
      }
    };
    getCovCount();
  }, [hasQuota]);
  return (
    <>
      {covCount && covCount.data ? (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={() => {
            handleClose();
          }}
        >
          <Alert onClose={handleClose} severity="warning">
            You have {covCount.data.quota - covCount.data.used} out of{' '}
            {covCount.data.quota} One View impressions left for this month
          </Alert>
        </Snackbar>
      ) : (
        <div></div>
      )}
    </>
  );
}

OneViewQuota.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export { OneViewQuota };
