import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { JiraDialog } from './JiraDialog';

export function WithDialog(props) {
  const { OpenWith, beforeOpen } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    if (typeof beforeOpen === 'function') {
      beforeOpen();
    }
    setOpen(true);
  };
  return (
    <>
      <JiraDialog open={open} handleClose={handleClose} />
      <OpenWith onClick={handleOpen} />
    </>
  );
}

WithDialog.propTypes = {
  OpenWith: PropTypes.func.isRequired,
  beforeOpen: PropTypes.func,
};
