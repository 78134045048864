import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  key: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function UserDetail(props) {
  const classes = useStyles();
  const { detailKey, detailValue } = props;
  return (
    <div className={classes.gridRoot}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        direction="row"
        justify="space-around"
      >
        <Grid item sm={4} xs={12}>
          <Typography
            color="primary"
            variant="subtitle1"
            className={classes.key}
          >
            {detailKey}
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography
            color="primary"
            variant="subtitle1"
            className={classes.value}
          >
            {detailValue}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

UserDetail.propTypes = {
  detailKey: PropTypes.string.isRequired,
  detailValue: PropTypes.string.isRequired,
};
