import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { DrawerMenu } from './../DrawerMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export function MenuButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openMenu = () => {
    setOpen(true);
  };
  const closeMenu = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <IconButton aria-label="toggle menu" onClick={openMenu}>
        <MenuIcon fontSize="large" />
      </IconButton>
      <DrawerMenu open={open} onClose={closeMenu} />
    </div>
  );
}
