import { useEffect, useState } from 'react';
import axios from 'axios';
import getTableDatafromCustomerData from './getTableDatafromCustomerData';
import { contextDataScopes } from '@aureus/donna-data-grid';

function useGetData(scope, contextData, getDataEndpoint, perspective, view) {
  const [gridData, setGridData] = useState({
    fetching: false,
    success: false,
    error: false,
    tableData: undefined,
  });

  const path = `${getDataEndpoint}/${scope}/${perspective}`;

  useEffect(() => {
    const dataRequest = async () => {
      setGridData({
        fetching: true,
        success: false,
        error: false,
        tableData: undefined,
      });

      try {
        if (contextData && contextDataScopes.includes(scope)) {
          setGridData({
            fetching: false,
            success: true,
            error: false,
            tableData: getTableDatafromCustomerData(contextData),
          });
        } else {
          const { error, data } = await axios.get(path);
          if (error) {
            setGridData({
              fetching: false,
              success: false,
              error: true,
              tableData: undefined,
            });
          } else if (data.error) {
            setGridData({
              fetching: false,
              success: false,
              error: true,
              tableData: undefined,
            });
          } else {
            setGridData({
              fetching: false,
              success: true,
              error: false,
              tableData: data.data,
            });
          }
        }
      } catch (error) {
        setGridData({
          fetching: false,
          success: false,
          error: true,
          tableData: undefined,
        });
      }
    };
    dataRequest();
  }, [path, scope, contextData]);
  return gridData;
}

export default useGetData;
