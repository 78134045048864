import React from 'react';
import { CardRowWrapper } from './CardRowWrapper';
import CONTEXT from '@aureus/cov-context';
import { Title } from '../Header/Title';
import { DefaultViewIcon } from '../Header/Icons';
import { ContextCardDataProvider } from './ContextCardDataProvider';

export const DefaultView = (props) => {
  const { type, path, goToViewAll, title } = props;
  return (
    <>
      {title && <Title titleText={title} icon={DefaultViewIcon} />}
      <ContextCardDataProvider context={CONTEXT.ROUNDOUT}>
        <CardRowWrapper path={path} goToViewAll={goToViewAll} />
      </ContextCardDataProvider>
      <ContextCardDataProvider context={CONTEXT.RETAIN}>
        <CardRowWrapper path={path} goToViewAll={goToViewAll} />
      </ContextCardDataProvider>
      <ContextCardDataProvider context={CONTEXT.RENEWAL}>
        <CardRowWrapper path={path} goToViewAll={goToViewAll} />
      </ContextCardDataProvider>
    </>
  );
};
