import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import UpdateIcon from '@material-ui/icons/Update';
import { parseISO, formatDistanceToNow } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import { useTenantDetails } from './useTenantDetails';
import TitleSkeleton from './TitleSkeleton';

const useStyles = makeStyles((theme) => ({
  refreshDateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  },
  chip: {
    background: '#FFFFFF',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const LastRefreshDate = () => {
  const classes = useStyles();
  const tenantDetails = useTenantDetails();
  const { success, fetching, error, data } = tenantDetails;
  let refreshDate;
  let refreshDateInWords;
  if (success && data.lastRefreshDate) {
    const splitDate = data.lastRefreshDate.split('/');
    const stringDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
    refreshDate = format(parseISO(stringDate), 'MM/dd/yyyy');

    refreshDateInWords = formatDistanceToNow(new Date(refreshDate), {
      addSuffix: true,
    });
  }

  return (
    <div className={classes.refreshDateWrapper}>
      <div className={classes.tooltip}>
        {success && refreshDate && !error && (
          <LightTooltip
            title={
              'Your Agency data was last synced with DONNA on ' + refreshDate
            }
            arrow
          >
            <Chip
              className={classes.chip}
              label={refreshDateInWords}
              icon={<UpdateIcon color="primary" />}
            />
          </LightTooltip>
        )}
        {fetching && <TitleSkeleton />}
      </div>
    </div>
  );
};

export { LastRefreshDate };
