import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useStylesForTable = makeStyles((theme) => ({
  root: {
    borderRadius: 45,
    overflow: 'hidden',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));

export function StyledPaper(props) {
  const classes = useStyles();
  return (
    <Paper square className={classes.root}>
      {props.children}
    </Paper>
  );
}

export function StyledPaperForTable(props) {
  const classes = useStylesForTable();
  return (
    <Paper square className={classes.root}>
      {props.children}
    </Paper>
  );
}
