import React from 'react';
import { useProfile } from './useProfile';
import { UserContext } from './../UserContext';
import { User } from './User';
import { Redirect } from 'react-router-dom';
import useGetPlansMapping from './useGetPlansMapping';
import { setPlanMapping } from '../../utils/donnaPlansUtil';
import { setPlanMapping as setStripePlanMapping } from '@aureus/stripe-billing-automation/lib/utils/donnaPlansUtil';


export function UserContextProvider(props) {
  const profile = useProfile();
  const user = new User(profile);
  const success = user.isSuccess();
  const fetching = user.isFetching();
  const error = user.isError();
  const { success: planMappingSuccess, data: plansMapping } =
    useGetPlansMapping();
  setPlanMapping(plansMapping);
  setStripePlanMapping(plansMapping);

  return (
    <UserContext.Provider value={{ user: user }}>
      {fetching && <div>Loading...</div>}
      {error && <Redirect to="/login/retry" />}
      {success && planMappingSuccess && props.children}
    </UserContext.Provider>
  );
}
