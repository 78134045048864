import axios from 'axios';
import { useState, useEffect } from 'react';

function useGetSubUsers() {
  const [subUsers, setSubUsers] = useState({
    fetching: false,
    success: false,
    error: false,
    data: undefined,
  });

  const path = '/api/user/getSubUsers';

  useEffect(() => {
    const apiRequest = async () => {
      try {
        setSubUsers({
          fetching: true,
          success: false,
          error: false,
          data: undefined,
        });
        const response = await axios.get(path);

        if (response.status !== 200) {
          setSubUsers({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setSubUsers({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
        }
      } catch (error) {
        setSubUsers({
          fetching: false,
          success: false,
          error: true,
          data: undefined,
        });
      }
    };
    apiRequest();
  }, []);

  return subUsers;
}

export { useGetSubUsers };
