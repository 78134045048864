export const dashboardRedirectionMapping = {
  'Business Rockstar - Name': 'teamAnalyticsLink',
  'CX Rockstar - Name': 'teamAnalyticsLink',
  'Rockstar Advisor - Name': 'teamAnalyticsLink',
  'Total Premium-Shade2': 'customerAnalyticsLink',
  'Uplift Premium By LOB': 'customerAnalyticsLink',
  'Recommendation By LOB': 'customerAnalyticsLink',
  'Recommendation by Type': 'customerAnalyticsLink',
  'Average Agency Policies Per Customer': 'trendsAnalyticsLink',
  'Average Agent Premium Per Customer': 'trendsAnalyticsLink',
  // Metrics Screen - My Agency
  'analytics-Total Happy Customers': 'customerAnalyticsLink',
  'analytics-Total Unhappy Customers': 'customerAnalyticsLink',
  // Metrics Screen - My Business
  'my-analytics-Total Happy Customers': 'portfolioLink',
  'my-analytics-Total Unhappy Customers': 'portfolioLink',
  'Retention Rate': 'trendsAnalyticsLink',
};
