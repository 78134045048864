import React from 'react';
import PropTypes from 'prop-types';
import { StyledPaper } from './../Styled';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { UserDetails } from './UserDetails';
import { ProfileImage } from './ProfileImage';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export function ProfileCard(props) {
  const { info, removeAvatar, setToRefresh } = props;
  const { user, locations } = info;
  const classes = useStyles();
  return (
    <StyledPaper>
      <div className={classes.gridRoot}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={4}>
            <ProfileImage
              userDetails={user}
              removeAvatar={removeAvatar}
              setToRefresh={setToRefresh}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            className={classes.divider}
          />
          <Grid item sm={12} md={7}>
            <UserDetails userDetails={user} locations={locations} />
          </Grid>
        </Grid>
      </div>
    </StyledPaper>
  );
}

ProfileCard.props = {
  info: PropTypes.shape({
    user: PropTypes.object.isRequired,
    locations: PropTypes.array.isRequired,
  }),
  removeAvatar: PropTypes.func.isRequired,
  setToRefresh: PropTypes.func.isRequired,
};
