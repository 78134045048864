import React from 'react';
import { Title } from '../Header/Title';
import { UserManagementIcon } from '../Header/Icons';
import { UserManagement } from './UserManagement';

const UserManagementWrapper = () => {
  return (
    <>
      <Title icon={UserManagementIcon} titleText="User Management" />
      <UserManagement />
    </>
  );
};

export default UserManagementWrapper;
