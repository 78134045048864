import React from 'react';
import { Link } from 'react-router-dom';

const RouterLinkWithoutForwarding = (props, ref) => (
  <Link innerRef={ref} {...props} />
);

const RouterLink = React.forwardRef(RouterLinkWithoutForwarding);

export { RouterLink };
