import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function AnalyticsError() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {'Cannot create analytics'}
    </Alert>
  );
}

export { AnalyticsError };
