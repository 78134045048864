import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import { UserManagementSkeleton } from './UserManagementSkeleton';
import { useUserList } from './../userUserList';
import { UserListFetchError } from './UserListFetchError';
import { UserList } from './UserList';
import { actionTypeSuccessMessages, useUserAction } from './../useUserAction';
import {
  actionTypes,
  actionTypeFailureMessages,
  filterUsers,
} from '../useUserAction';
import Alert from '@material-ui/lab/Alert';

export function UserManagement(props) {
  const { guide } = props;
  const isLinkable = false;
  const [toRefresh, setToRefresh] = useState(true);
  const clearToRefresh = () => {
    setToRefresh(false);
  };
  const setRefresh = () => {
    setToRefresh(true);
  };
  const { fetching, error, success, data } = useUserList(
    toRefresh,
    clearToRefresh
  );
  const [action, setAction] = useState({
    type: undefined,
    payload: undefined,
  });
  const {
    fetching: actionFetching,
    error: actionError,
    success: actionSuccess,
  } = useUserAction(action, setRefresh);
  useEffect(() => {
    if (actionSuccess) {
      setSuccessSnackbarOpen(actionSuccess);
      setRefresh();
    }
  }, [actionSuccess]);
  const updateUser = (userDetails) => {
    setAction({
      type: actionTypes.SET_DETAILS,
      payload: userDetails,
    });
  };
  const newUser = (userDetails) => {
    setAction({
      type: actionTypes.NEW_USER,
      payload: userDetails,
    });
  };
  const changeUserDetails = (userDetails) => {
    setAction({
      type: actionTypes.UPDATE_DETAILS,
      payload: userDetails,
    });
  };

  const addUserAndActivate = (userDetails) => {
    setAction({
      type: actionTypes.ADD_AND_ACTIVATE_USER,
      payload: userDetails,
    });
  };
  const changeUserDetailsAndActivate = (userDetails) => {
    setAction({
      type: actionTypes.UPDATE_AND_ACTIVATE_USER,
      payload: userDetails,
    });
  };

   const updateUserAndActivate = (userDetails) => {
     setAction({
       type: actionTypes.SET_DETAILS_AND_ACTIVATE,
       payload: userDetails,
     });
   };

  const activateUser = (userDetails) => {
    setAction({
      type: actionTypes.ACTIVATE,
      payload: userDetails,
    });
  };
  const linkUser = (userDetails) => {
    setAction({
      type: actionTypes.LINK,
      payload: userDetails,
    });
  };
  const disableUser = (userDetails) => {
    setAction({
      type: actionTypes.DISABLE,
      payload: userDetails,
    });
  };
  const enableUser = (userDetails) => {
    setAction({
      type: actionTypes.ENABLE,
      payload: userDetails,
    });
  };
  const sendActivationEmail = (userDetails) => {
    setAction({
      type: actionTypes.ACTIVATION_EMAIL,
      payload: userDetails,
    });
  };
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  useEffect(() => {
    if (actionError) {
      setErrorSnackbarOpen(actionError);
    }
  }, [actionError]);

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };
  const handleErrorSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };
  const showSkeleton = fetching && !data;
  const showUserList = (fetching && data) || success;

  return (
    <div>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert severity="error">{actionTypeFailureMessages[action.type]}</Alert>
      </Snackbar>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert severity="success">
          {actionTypeSuccessMessages[action.type]}
        </Alert>
      </Snackbar>

      <Container maxWidth="lg">
        {showSkeleton && <UserManagementSkeleton />}
        {error && <UserListFetchError />}
        {showUserList && (
          <UserList
            users={filterUsers(data.users)}
            locations={data.locations}
            updateUser={updateUser}
            updateUserAndActivate={updateUserAndActivate}
            newUser={newUser}
            changeUserDetails={changeUserDetails}
            addUserAndActivate={addUserAndActivate}
            changeUserDetailsAndActivate={changeUserDetailsAndActivate}
            activateUser={activateUser}
            linkUser={linkUser}
            disableUser={disableUser}
            enableUser={enableUser}
            sendActivationEmail={sendActivationEmail}
            loading={fetching || actionFetching}
            allowLink={isLinkable}
            guide={guide}
          ></UserList>
        )}
      </Container>
    </div>
  );
}

UserManagement.props = {
  guide: PropTypes.bool.isRequired,
};
