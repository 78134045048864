import { dataGridScopes } from '@aureus/donna-data-grid';

const roundoutFilters = {
  GbScoreGroupRoundout: {
    values: ['G1', 'G2'],
    filterType: 'set',
  },
};

const retentionFilters = {
  GbScoreGroupRetention: {
    values: ['G4', 'G5'],
    filterType: 'set',
  },
};

const getScopeBasedFilter = (scope) => {
  if (scope) {
    if (scope === dataGridScopes.ROUND_OUT) {
      return roundoutFilters;
    } else if (scope === dataGridScopes.RETAIN) {
      return retentionFilters;
    }
  }
  return {};
};

export default getScopeBasedFilter;
