import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { TrendsIcon } from '../Header/Icons';
import { Title } from '../Header';
import { ViewSelector } from './ViewSelector';
import { TeamSelector } from './TeamSelector';
import addFilter from './../Diem/addFilter';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const getStartDate = () => {
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  const currentDateWithOffset = new Date(currentDate.getTime() - (offset * 60 * 1000));
  currentDateWithOffset.setFullYear(currentDateWithOffset.getFullYear() - 1);
  const startDate = currentDateWithOffset.toISOString().split('T')[0];
  return startDate;
};

const getEndDate = () => {
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  const currentDateWithOffset = new Date(currentDate.getTime() - (offset * 60 * 1000));
  const endDate = currentDateWithOffset.toISOString().split('T')[0];
  return endDate;
};

export function Trends(props) {
  const { type, subType } = props;
  const [typeToUse, setTypeToUse] = useState(type);
  const [teamMemberToUse, setTeamMemberToUse] = useState(-1);
  const showTypeSwitch = type === 'analytics';
  const showTeamSwitch = type === 'team-analytics';
  let left;
  let analyticsApiPath = `/api/metrics/v1/${typeToUse}/${subType}`;
  if (showTypeSwitch) {
    left = <ViewSelector value={typeToUse} setValue={setTypeToUse} />;
  }
  if (showTeamSwitch) {
    if (teamMemberToUse > -1) {
      analyticsApiPath = `/api/metrics/v1/${typeToUse}/${subType}/${teamMemberToUse}`;
    }
    left = (
      <TeamSelector
        value={teamMemberToUse}
        setValue={setTeamMemberToUse}
        page={'Trends'}
      />
    );
  }
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  const title = 'Trends';
  const icon = TrendsIcon;
  let dataWithStartDate, dataWithEndDate;
  if (success) {
    dataWithStartDate = addFilter(data, 'startDate', getStartDate());
    dataWithEndDate = addFilter(dataWithStartDate, 'endDate', getEndDate());
  }
  return (
    <div>
      <Title icon={icon} titleText={title} left={left} />
      {fetching && <AnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...dataWithEndDate}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <AnalyticsError />}
    </div>
  );
}

Trends.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};
