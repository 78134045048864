import axios from 'axios';
import { User } from '../UserContextProvider/User';

const path = '/api/user/profile';

const getUserEmail = async () => {
  let response = {};
  const profileData = { fetching: false, success: true, error: false };
  try {
    const { status, data } = await axios.get(path);
    const { error, data: profile } = data;
    if (status !== 200 || error) {
      throw new Error('Error getting profile details');
    }
    profileData.data = profile;
    const user = new User(profileData);
    const userEmail = user.getEmail();
    response = {
      error: false,
      data: {
        userEmail,
      },
    };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default getUserEmail;
