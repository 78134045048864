import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/insuredMine/v1/setInsuredMineEnabled';

export function useSetInsuredMineEnabled(
  saveInsuredMineEnableState,
  insuredMinePayload,
  setGetInsuredMineEnableState
) {
  const [insuredMineEnabled, setInsuredMineEnabled] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const setInsuredMineEnable = async () => {
      setInsuredMineEnabled({ fetching: true, error: false, success: false });
      try {
        const response = await axios.post(path, insuredMinePayload);
        if (response.status !== 200) {
          setInsuredMineEnabled({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setInsuredMineEnabled({
            fetching: false,
            success: true,
            error: false,
            data: response.data.data,
          });
          setGetInsuredMineEnableState(true);
        }
      } catch (e) {
        setInsuredMineEnabled({ fetching: false, error: true, success: false });
      }
    };
    if (saveInsuredMineEnableState) {
      setInsuredMineEnable();
    }
  }, [
    saveInsuredMineEnableState,
    insuredMinePayload,
    setGetInsuredMineEnableState,
  ]);
  return insuredMineEnabled;
}
