import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  bottomRight: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
  margin: {
    margin: theme.spacing(3),
  },
}));

export function JiraFab(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div className={classes.bottomRight}>
      <Tooltip title="Contact Support">
        <Fab
          size="medium"
          color="primary"
          aria-label="contact-support"
          className={classes.margin}
          onClick={onClick}
        >
          <ContactSupportIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}

JiraFab.propTypes = {
  onClick: PropTypes.func.isRequired,
};
