export const getTitle = (modalTitle, context, contextProperties) => {
  let title = '';
  if (modalTitle) {
    title = modalTitle;
  } else if (context && contextProperties[context]) {
    title = contextProperties[context].title;
  }
  return title;
};

export const getCustomerArr = (customerIdsWithContext, customers) => {
  let customersArr = [];
  if (customerIdsWithContext) {
    customersArr = customerIdsWithContext;
  }
  if (customers) {
    customersArr = customers;
  }
  return customersArr;
};

export const getCustomerID = (customerIdsWithContext, customers) => {
  let customerID = '';
  if (customerIdsWithContext) {
    customerID = 'customerId';
  }
  if (customers) {
    customerID = 'Main';
  }
  return customerID;
};

export const setPrevNextDisabledValue = (
  customersArr,
  customerID,
  customerId
) => {
  let isDisableNext = false;
  let isDisablePrev = false;
  let currentIndex = -1;
  if (!Array.isArray(customersArr)) {
    return { isDisableNext, isDisablePrev, currentIndex };
  }
  customersArr.forEach((customer, index) => {
    if (customerId === customer[customerID]) {
      currentIndex = index;
    }
  });

  if (currentIndex <= 0) {
    isDisablePrev = true;
  }
  if (currentIndex >= customersArr.length - 1) {
    isDisableNext = true;
  }
  return { isDisableNext, isDisablePrev, currentIndex };
};
