import axios from 'axios';

const basePath = '/api/ai/v1/activity/action';

const sendAction = async ({
  customerId,
  activityId,
  reasonType,
  reason,
  comment,
  actionType,
  reminderTimestamp,
}) => {
  const path = `${basePath}/${customerId}/${activityId}`;
  const action = {
    reasonType,
    reason,
    comment,
    actionType,
    reminderTimestamp,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  const { status, data } = await axios.post(path, action, {
    headers,
  });
  return { status, data };
};

export default sendAction;
