import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { MenuButton } from './MenuButton';
import { AppLogo } from './AppLogo';
import { UserInfo } from './UserInfo';
import { JiraFabWithDialog } from './../Jira';
import PlanDetails from './PlanDetails';
import DonnaGPT from './DonnaGPT';
import { UserContext } from '../UserContext';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  appBar: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.down('md')]: {
      height: 147,
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
    },
    boxShadow: '0px 0px 10px rgba(45, 96, 137, 0.5)',
  },
  userDetails: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

export function Header() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const role = user.getTopRole();
  const isRoleAllowed =
    role && (role === 'ceo' || role === 'super-user') ? true : false;
  const gridPoints = {
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.gridRoot}>
            <Grid container spacing={2} justify="space-between">
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
                spacing={1}
                justify="flex-start"
                align="center"
                container
              >
                <MenuButton />
                <AppLogo />
              </Grid>
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
                className={classes.userDetails}
              >
                <PlanDetails />
                <UserInfo />
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      {isRoleAllowed && <DonnaGPT />}
      <JiraFabWithDialog />
    </>
  );
}
