const getValueForFieldName = (pairs, fieldName) => {
  return pairs.find((pair) => pair.fieldName === fieldName);
};

const getFirstMatchingPair = (pairs, fieldNames) => {
  for (let i = 0; i < fieldNames.length; i++) {
    const pair = getValueForFieldName(pairs, fieldNames[i]);
    if (pair) {
      return pair;
    }
  }
};

const getSingleMarkValue = (marks, orderedKeys) => {
  if (marks.length !== 1) {
    return;
  }
  const mark = marks[0];
  const pairs = mark.getPairs();
  const pair = getFirstMatchingPair(pairs, orderedKeys);
  if (pair) {
    return pair.value;
  }
};

const getAllMarkValues = (marks, orderedKeys) => {
  return marks.map((mark) => {
    const pairs = mark.getPairs();
    const pair = getFirstMatchingPair(pairs, orderedKeys);
    if (pair) {
      return pair.value;
    } else {
      return undefined;
    }
  });
};

export { getSingleMarkValue, getAllMarkValues };
