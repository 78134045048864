import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { DiemSkeleton } from './DiemSkeleton';
import { DiemError } from './DiemError';
import { CardRow } from './CardRow';
import { contextProperties } from './ContextProperties';
import { QuickViewModal } from '../QuickView/QuickViewModal';
import { OneView } from '../OneView/OneView';

export function CardRowWrapper(props) {
  const history = useHistory();
  const {
    success,
    fetching,
    error,
    customers,
    customerIdsWithContext,
    goToViewAll,
    context,
    reload,
    path,
    customerCount,
  } = props;
  const currentContextProperties = contextProperties[context];
  const onSelect = (customerId, customersarr, context, cardIndex) => {
    if (customerId) {
      history.push(`${path}/${context}/quickview/${customerId}/${context}`);
    }
  };
  return (
    <>
      <Route path={`${path}/${context}/oneview/:customerId/:selectedView?`}>
        <OneView />
      </Route>
      <Route path={`${path}/${context}/quickview/:customerId/:context?`}>
        <QuickViewModal
          customers={customers}
          customerIdsWithContext={customerIdsWithContext}
          backPath={path}
          prefixContext={true}
          customerCount={customerCount}
        />
      </Route>
      {fetching && <DiemSkeleton />}
      {success && (
        <CardRow
          onSelect={onSelect}
          goToViewAll={goToViewAll}
          context={context}
          customers={customers}
          customerIdsWithContext={customerIdsWithContext}
          reload={reload}
          {...currentContextProperties}
          {...props}
        />
      )}
      {!fetching && error && <DiemError />}
    </>
  );
}

CardRowWrapper.propTypes = {
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  error: PropTypes.bool,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      Main: PropTypes.string.isRequired,
    })
  ),
  customerIdsWithContext: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      context: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  context: PropTypes.string,
  grid: PropTypes.bool,
  reload: PropTypes.func,
};
