import { quoteTypes } from './quoteTypes';

let quoteRuleIds;
try {
  quoteRuleIds = JSON.parse(process.env.REACT_APP_QUOTE_RULE_IDS);
} catch (error) {
  quoteRuleIds = [];
}

const showQuote = async (payload, type) => {
  let isShowQuote = false;
  if (type === quoteTypes.ACTIVITY && quoteRuleIds.includes(payload.ruleID)) {
    isShowQuote = true;
  }
  return {
    error: false,
    data: {
      isShowQuote,
    },
  };
};

export default showQuote;
