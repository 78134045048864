import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Portal, Snackbar, makeStyles } from '@material-ui/core';
import {
  dialogConfig,
  dialogType,
} from '@aureus/outbound-channels/dist/config/dialogConfig';
import { ChannelDialog, ChannelListManager } from '@aureus/outbound-channels';

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: 20,
  },
  icon: {
    fontSize: 32,
  },
  channelListForSettings: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(6),
  },
}));

const OutboundChannelListManager = (props) => {
  const classes = useStyles();
  const {
    tenant,
    userID,
    providerName,
    addAccountApiPath,
    getAccountsApiPath,
    createChannelApiPath,
    getChannelsApiPath,
    updateChannelApiPath,
    sendToChannelsApiPath,
    getLeadCreationDetails,
    getLeadCreationDetailsPath,
    triggerChannelFetch,
    featureLockConfig,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [leadSnackbarOpen, setLeadSnackbarOpen] = useState(false);
  const [editChannelDetails, setEditChannelDetails] = useState(null);

  const [alertObject, setAlertObject] = useState({
    type: '',
    message: '',
  });

  const handleClose = () => {
    setModalConfig({});
    setEditChannelDetails(null);
    setOpenDialog(false);
  };

  const handleOpen = (key) => {
    setModalConfig(dialogConfig[key]);
    setOpenDialog(true);
  };

  const leadSnackbarClose = () => {
    setLeadSnackbarOpen(false);
  };

  const getLeadCreationDetailsFailed = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message:
        'Something went wrong while getting lead details, please try again',
    });
  };

  const channelCreationSuccess = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'success',
      message: 'Channel Created Successfully',
    });
  };

  const channelCreationFailed = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message: 'Failed to create channel. Please try again after sometime',
    });
  };

  const addAccountSuccess = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'success',
      message: 'Account Details Added Successfully',
    });
  };

  const sendNowFailure = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message:
        'Failed to send to selected channels. Please try again after sometime',
    });
  };

  return (
    <>
      <div className={classes.channelListForSettings}>
        <ChannelListManager
          handleClose={handleClose}
          handleOpen={handleOpen}
          modalConfig={modalConfig}
          tenant={tenant}
          userID={userID}
          providerName={providerName}
          getChannelsApiPath={getChannelsApiPath}
          updateChannelApiPath={updateChannelApiPath}
          getLeadCreationDetails={getLeadCreationDetails}
          getLeadCreationDetailsFailed={getLeadCreationDetailsFailed}
          editChannelDetails={editChannelDetails}
          setEditChannelDetails={setEditChannelDetails}
          getLeadCreationDetailsPath={getLeadCreationDetailsPath}
          triggerChannelFetch={triggerChannelFetch}
          featureLockConfig={featureLockConfig}
        />
      </div>
      {openDialog && (
        <ChannelDialog
          handleClose={handleClose}
          handleOpen={handleOpen}
          modalConfig={modalConfig}
          tenant={tenant}
          userID={userID}
          addAccountApiPath={addAccountApiPath}
          getAccountsApiPath={getAccountsApiPath}
          createChannelApiPath={createChannelApiPath}
          getChannelsApiPath={getChannelsApiPath}
          updateChannelApiPath={updateChannelApiPath}
          sendToChannelsApiPath={sendToChannelsApiPath}
          getLeadCreationDetails={getLeadCreationDetails}
          getLeadCreationDetailsFailed={getLeadCreationDetailsFailed}
          channelCreationSuccess={channelCreationSuccess}
          channelCreationFailed={channelCreationFailed}
          addAccountSuccess={addAccountSuccess}
          editChannelDetails={editChannelDetails}
          setEditChannelDetails={setEditChannelDetails}
          sendNowFailure={sendNowFailure}
          getLeadCreationDetailsPath={getLeadCreationDetailsPath}
        />
      )}
      {leadSnackbarOpen &&
        alertObject !== null &&
        alertObject.type !== '' &&
        alertObject.message !== '' && (
          <Portal>
            <Snackbar
              open={leadSnackbarOpen}
              // autoHideDuration={5000}
              onClose={leadSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert
                severity={alertObject.type}
                elevation={6}
                variant="filled"
                onClose={leadSnackbarClose}
                classes={{
                  message: classes.message,
                  icon: classes.icon,
                }}
              >
                {alertObject.message}
              </MuiAlert>
            </Snackbar>
          </Portal>
        )}
    </>
  );
};

export default OutboundChannelListManager;
