import { useState, useEffect } from 'react';
import axios from 'axios';

const path = '/api/role/v1/getRolesConfig';

function useGetRolesConfig() {
  const [rolesConfig, setRolesConfig] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
  });
  useEffect(() => {
    const getRolesConfig = async () => {
      setRolesConfig({
        fetching: true,
        error: false,
        success: false,
        data: undefined,
      });
      try {
        const { error, data } = await axios.get(path);
        if (error || data.error) {
          setRolesConfig({
            fetching: false,
            success: false,
            error: true,
            data: undefined,
          });
        } else {
          setRolesConfig({
            fetching: false,
            success: true,
            error: false,
            data: data.data,
          });
        }
      } catch (e) {
        setRolesConfig({
          fetching: false,
          error: true,
          success: false,
          data: undefined,
        });
      }
    };
    getRolesConfig();
  }, []);

  return rolesConfig;
}

export default useGetRolesConfig;
