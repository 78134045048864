import axios from 'axios';

export const removeAvatar = async () => {
  const path = '/api/image/v1/clearTenantLogo';
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    const { status, data } = await axios.post(path, {}, { headers });
    return { status, data };
  } catch (e) {
    return false;
  }
};
