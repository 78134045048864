import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Title } from '../Header';
import { InsightsIcon } from '../Header/Icons';
import { useViewDetails } from '../useViewDetails';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';
import { AnalyticsError } from './AnalyticsError';
import {
  getUnderlyingSummaryData,
  TableauMarkSelectionView,
} from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import {
  isCustomerValid,
  getCovFromCustomerData,
} from './../Diem/getCovFromCustomerData';
import { InsightCustomers } from './InsightCustomers';

import { ViewSelector } from './ViewSelector';
import { gaEvent } from '../GoogleAnalytics/gaEvent';
import { TeamSelector } from './TeamSelector';
import { getInsightCustomerCountFromMarks } from '../TableauView/getInsightCustomerCountFromMarks';
import defaultContext from '../../Enums/defaultContext';

const TITLE = 'Insights';
const MAX_CUSTOMER_ALLOWED = 30;

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const useStyles = makeStyles((theme) => ({
  vizContainer: {
    display: 'flex',
  },
  separator: {
    width: theme.spacing(4),
    height: '100%',
    flexShrink: 0,
  },
}));

const Insights = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { type, subType } = props;
  const [typeToUse, setTypeToUse] = useState(type);
  const [teamMemberToUse, setTeamMemberToUse] = useState(-1);
  const [customers, setCustomers] = useState();
  const [insight, setInsight] = useState();
  const showTypeSwitch = type === 'analytics';
  const showTeamSwitch = type === 'team-analytics';
  let left;
  let apiPath = `/api/metrics/v1/${typeToUse}/${subType}`;
  if (showTypeSwitch) {
    left = <ViewSelector value={typeToUse} setValue={setTypeToUse} />;
  }
  if (showTeamSwitch) {
    if (teamMemberToUse > -1) {
      apiPath = `/api/metrics/v1/${typeToUse}/${subType}/${teamMemberToUse}`;
    }
    left = (
      <TeamSelector
        value={teamMemberToUse}
        setValue={setTeamMemberToUse}
        page={'Insights'}
      />
    );
  }
  const analyticsDetails = useViewDetails(apiPath);
  const { fetching, success, error, data } = analyticsDetails;
  useEffect(() => {
    setCustomers();
  }, [typeToUse]);

  useEffect(() => {
    setCustomers();
  }, [teamMemberToUse]);

  const gaTracking = (worksheetName, customersDisplayed) => {
    const category = 'InsightUsed';
    const action = 'Marks selected from ' + match.path;
    const label = worksheetName;
    const value = customersDisplayed;
    gaEvent(category, action, label, value);
  };

  const gaTrackingQuickView = (customerId) => {
    const category = 'QuickViewInsight';
    const action = 'From ' + insight;
    const label = customerId;
    gaEvent(category, action, label);
  };

  const dedupeCustomers = (customers) => {
    if (Array.isArray(customers)) {
      const seen = {};
      return customers.filter((customer) => {
        const { Main: customerId } = customer;
        if (seen[customerId] === true) {
          return false;
        } else {
          seen[customerId] = true;
          return true;
        }
      });
    } else {
      return customers;
    }
  };

  const onSelect = async (marks, worksheetName, viz) => {
    setInsight(worksheetName);

    let insightCustomerCount;
    insightCustomerCount = parseInt(getInsightCustomerCountFromMarks(marks)[0]);
    if (isNaN(insightCustomerCount)) {
      insightCustomerCount = MAX_CUSTOMER_ALLOWED;
    }
    const summaryData = await getUnderlyingSummaryData(viz);
    const validSummaryData = summaryData.filter((customerSummary) =>
      isCustomerValid(customerSummary)
    );
    const covData = dedupeCustomers(
      validSummaryData.map((customerSummary) =>
        getCovFromCustomerData(customerSummary)
      )
    );
    const slicedCustomers = covData.slice(0, insightCustomerCount);
    setCustomers();
    setCustomers(slicedCustomers);

    gaTracking(worksheetName, slicedCustomers.length);
  };

  const onCustomerSelect = (customerId) => {
    if (customerId) {
      gaTrackingQuickView(customerId);
      history.push(
        `${match.url}/${defaultContext}/quickview/${customerId}/${defaultContext}`
      );
    }
  };

  return (
    <>
      <Title icon={InsightsIcon} titleText={TITLE} left={left} />

      {fetching && <AnalyticsSkeleton />}
      {success && (
        <div className={classes.vizContainer}>
          <TableauMarkSelectionView
            {...data}
            {...dimensions}
            adjustHeight={true}
            onSelect={onSelect}
          />
          <div className={classes.separator}></div>

          <InsightCustomers
            customers={customers}
            onSelect={onCustomerSelect}
            insightViewName={insight}
          />
        </div>
      )}
      {error && <AnalyticsError />}
    </>
  );
};

Insights.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};

export { Insights };
