import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { UserConfigContext } from '../UserConfig';

const useStyles = makeStyles((theme) => ({
  content: {
    fontSize: 14,
    color: '#4A4A4A',
    fontFamily: 'Barlow',
  },
  titleRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 0,
    background: '#CBD5DD',
    '& h2': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 'initial',
    color: '#4A4A4A',
  },
  saveChangesBtn: {
    background: '#2D6089',
    borderRadius: 4,
    '&:hover': {
      background: '#2D6089',
    },
  },
  disableBtn: {
    background: 'rgba(0, 0, 0, 0.26)',
  },
  saveLabel: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
  },
  closeBtn: {
    background: '#d5dfe7',
    borderRadius: 4,
    '&:hover': {
      background: '#d5dfe7',
    },
    height: 30,
    width: 70,
  },
  closeBtnLabel: {
    color: '#2D6089',
    textTransform: 'capitalize',
    fontSize: 14,
    lineHeight: 'initial',
    height: 30,
    width: 70,
  },
}));

export function ResetHomePopup(props) {
  const { open, onClose } = props;
  const { setAndReload } = useContext(UserConfigContext);
  const classes = useStyles();

  const setAsHome = () => {
    setAndReload({ action: 'setHomeScreen', value: 'DEFAULT' });
    onClose();
  };
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle classes={{ root: classes.titleRoot, h6: classes.titleFlex }}>
        <Typography className={classes.dialogTitle}>Reset The View</Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <p>Do you want to reset the view?</p>
        <p>
          Performing this action will reset the home page and all recommendation
          types will be shown.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          classes={{ root: classes.closeBtn, label: classes.closeBtnLabel }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          classes={{
            root: classes.saveChangesBtn,
            label: classes.saveLabel,
          }}
          onClick={setAsHome}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResetHomePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
