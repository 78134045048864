import React from 'react';
import NotificationSettings from '@aureus/notifications-module/dist/components/Settings/NotificationSettings.js';

const getAllSettingsEndpoint = '/api/notification/v1/getAllSettings/';
const updateSettingsEndpoint = '/api/notification/v1/updateSettings/';
const notificationSettingDetails = JSON.parse(
  process.env.REACT_APP_NOTIFICATION_SETTINGS_DETAILS
);
const rolesConfig = JSON.parse(process.env.REACT_APP_NOTIFICATION_ROLES_CONFIG);
const notificationTypeConfig = JSON.parse(
  process.env.REACT_APP_NOTIFICATION_TYPE_CONFIG
);

function NotificationSetting() {
  return (
    <NotificationSettings
      getAllSettingsEndpoint={getAllSettingsEndpoint}
      updateSettingsEndpoint={updateSettingsEndpoint}
      notificationSettingDetails={notificationSettingDetails}
      rolesConfig={rolesConfig}
      channelsConfig={notificationTypeConfig}
    />
  );
}

export default NotificationSetting;
