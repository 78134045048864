import React, { cloneElement, useContext } from 'react';
import PropTypes from 'prop-types';
import { MyListContext } from '../CardView/MyListContext';

const MyListCustomerProvider = (props) => {
  const { context, maxCustomers, children } = props;
  const { fetching, success, error, data } = useContext(MyListContext);
  let filteredCustomerIdsWithContext = data;
  if (maxCustomers && Array.isArray(data)) {
    filteredCustomerIdsWithContext = data.slice(0, maxCustomers);
  }
  return (
    <>
      {cloneElement(children, {
        fetching,
        success,
        error,
        myListCustomers: filteredCustomerIdsWithContext,
        context,
      })}
    </>
  );
};

MyListCustomerProvider.propTypes = {
  context: PropTypes.string.isRequired,
  maxCustomers: PropTypes.number,
};

export { MyListCustomerProvider };
