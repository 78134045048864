import React, { useState } from 'react';
import { UserConfigContext } from './UserConfigContext';
import setUserConfig from './setUserConfig';
import useUserConfig from './useUserConfig';

export function UserConfigContextProvider(props) {
  const [load, setLoad] = useState(true);
  const reload = () => {
    setLoad(true);
  };
  const stopLoad = () => {
    setLoad(false);
  };
  const setAndReload = async ({ action, value }) => {
    await setUserConfig({ action, value });
    reload();
  };
  const { fetching, success, error, data } = useUserConfig(load, stopLoad);
  const getProperty = (property) => {
    if (Array.isArray(data)) {
      const propertyPair = data.find(({ key }) => key === property);
      if (propertyPair) {
        const { value } = propertyPair;
        return value;
      }
    }
  };
  return (
    <UserConfigContext.Provider
      value={{
        fetching,
        success,
        error,
        data,
        reload,
        setAndReload,
        getProperty,
      }}
    >
      {props.children}
    </UserConfigContext.Provider>
  );
}
