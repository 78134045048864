import React from 'react';
import {
  getLabelForRole,
  getAssignableRoleLabelMapping,
} from '@aureus/donna-roles';
import { StatusDetails } from './StatusDetails';
import { UserPermissions } from './UserPermissions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { ActionButtons } from './ActionButtons';
import {
  getReportToUsersList,
} from './getReportToUsers';

const CEO_ROLE_KEY = 'ceo';

export const getReportsToMapping = (users, initialsOnly) => {
  return getReportToUsersList(users);
};

export const getLocationMapping = (locations) => {
  const locationMapping = {
    0: '',
  };
  locations.forEach((location) => {
    const { locationID, name } = location;
    locationMapping[locationID] = name;
  });
  return locationMapping;
};

const hasCeo = (users) => {
  return users.find((user) => user.userType === CEO_ROLE_KEY) !== undefined;
};

export const getColumns = (
  users,
  locations,
  onUserActivation,
  onUserDisable,
  onUserEnable,
  onSendActivationEmail,
  onRowUpdate
) => {
  const columns = [
    {
      title: 'Actions',
      field: 'actions',
      sorting: false,
      editable: 'never',
      render: (userData) => (
        <ActionButtons
          rowData={userData}
          onUserActivation={onUserActivation}
          onUserDisable={onUserDisable}
          onUserEnable={onUserEnable}
          onSendActivationEmail={onSendActivationEmail}
          onRowUpdate={onRowUpdate}
          users={users}
          locations={locations}
        />
      ),
    },
    {
      title: '',
      editable: 'never',
      sorting: false,
      render: (userData) => <StatusDetails userData={userData} />,
    },
    {
      title: 'ID',
      field: 'userInitials',
      editable: 'never',
    },
    {
      title: 'First Name',
      field: 'firstName',
      defaultSort: 'asc',
    },
    {
      title: 'Last Name',
      field: 'lastName',
    },
    {
      title: 'Email ID',
      field: 'emailID',
      editComponent: (props) => {
        const {
          columnDef,
          rowData,
          onRowDataChange,
          errorState,
          ...textFieldProps
        } = props;
        const disabled = rowData.hasBasicDetails;
        return (
          <TextField
            {...textFieldProps}
            fullWidth
            disabled={disabled}
            style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
            type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
            placeholder={
              props.columnDef.editPlaceholder || props.columnDef.title
            }
            value={props.value === undefined ? '' : props.value}
            onChange={(event) =>
              props.onChange(
                props.columnDef.type === 'numeric'
                  ? event.target.valueAsNumber
                  : event.target.value
              )
            }
            InputProps={{
              style: {
                fontSize: 13,
              },
              inputProps: {
                'aria-label': props.columnDef.title,
              },
            }}
          />
        );
      },
      render: (rowData) => {
        return <span style={{ width: 'fit-content' }}>{rowData.emailID}</span>;
      },
    },
    {
      title: 'Role',
      field: 'userType',
      lookup: getAssignableRoleLabelMapping(),
      defaultGroupOrder: 0,
      editComponent: (props) => {
        const {
          columnDef,
          rowData,
          onRowDataChange,
          errorState,
          ...otherProps
        } = props;
        const { helperText, error, ...selectProps } = otherProps;
        const disabled = props.value === CEO_ROLE_KEY;
        return (
          <FormControl error={Boolean(error)}>
            <Select
              {...selectProps}
              value={props.value === undefined ? '' : props.value}
              onChange={(event) => props.onChange(event.target.value)}
              style={{
                fontSize: 13,
              }}
              disabled={disabled}
              SelectDisplayProps={{ 'aria-label': props.columnDef.title }}
            >
              {Object.keys(props.columnDef.lookup)
                .filter((key) => {
                  // TODO: Add role based filtering
                  if (key === CEO_ROLE_KEY && !rowData.userInitials) {
                    return false;
                  } else if (
                    key === CEO_ROLE_KEY &&
                    props.value !== CEO_ROLE_KEY
                  ) {
                    return !hasCeo(users);
                  } else {
                    return true;
                  }
                })
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {props.columnDef.lookup[key]}
                  </MenuItem>
                ))}
            </Select>
            {Boolean(helperText) && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      title: 'Designation',
      field: 'designation',
    },
    {
      title: 'Reporting To',
      field: 'reportsTo',
      lookup: getReportsToMapping(users),
      initialEditValue: 0,
      render: (rowData) => {
        const reportsToMapping = getReportsToMapping(users);
        return (
          <span style={{ width: 'fit-content' }}>
            {reportsToMapping['U-' + rowData.reportsTo]}
          </span>
        );
      },
    },
    {
      title: 'Location',
      field: 'location',
      lookup: getLocationMapping(locations),
      initialEditValue: 0,
    },
    {
      title: 'Customize Data Access',
      field: 'customizeDataAccess',
      sorting: false,
      editable: 'never',
      render: (userData) => <UserPermissions rowData={userData} />,
    },
  ];
  return columns;
};
