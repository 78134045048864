import React from 'react';
import { Redirect } from 'react-router-dom';
import useGetStageProgress from '@aureus/donna-onboarding/dist/hooks/useGetStageProgress';

export function AgencySetup({ children }) {
  const stageProgressEndpoint = '/api/v1/onboarding/getStageProgress';
  const { fetching, success, error } = useGetStageProgress(
    stageProgressEndpoint
  );

  return (
    <>
      {fetching && <div>Loading...</div>}
      {error && <Redirect to="/login/retry" />}
      {success && <>{children}</>}
    </>
  );
}
