import { useState, useEffect } from 'react';

const USER_LIST_API_PATH = '/api/admin/v1/user/list';

export function useUserList(toRefresh, clearToRefresh) {
  const [userList, setUserList] = useState((prevUserList) => {
    let data;
    if (prevUserList && prevUserList.data && prevUserList.data.success) {
      data = prevUserList.data;
    }
    return {
      fetching: false,
      error: false,
      success: false,
      data: data,
    };
  });
  useEffect(() => {
    const getUserList = async () => {
      setUserList({
        fetching: true,
        error: false,
        success: false,
        data: userList.data,
      });
      try {
        const response = await fetch(USER_LIST_API_PATH);
        if (response.redirected) {
          window.location.href = response.url;
        }
        const responseObject = await response.json();
        const { error, errorMessage, data } = responseObject;
        if (error) {
          throw new Error(errorMessage);
        }
        setUserList({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (e) {
        setUserList({
          fetching: false,
          error: true,
          success: false,
          data: undefined,
        });
      }
    };
    if (toRefresh) {
      clearToRefresh();
      getUserList();
    }
  }, [toRefresh, clearToRefresh, userList.data]);
  return userList;
}
