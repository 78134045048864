import axios from 'axios';

const path = '/api/agencyZoom/v1/getLeadHistory';

const headers = {
  'Content-Type': 'application/json',
};

const getLeadHistory = async (params) => {
  try {
    const { status, data } = await axios.get(path, {
      headers,
      params,
    });
    if (status !== 200) {
      throw new Error('Error getting lead history');
    }
    const { error, errorMessage } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    return data;
  } catch (error) {
    return {
      error: true,
      errorMessage: error.message,
    };
  }
};

export default getLeadHistory;
