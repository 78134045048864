import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  labelContainer: {
    marginTop: theme.spacing(1),
    width: '50%',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export function RoleSelection(props) {
  const classes = useStyles();
  const { value, setValue, hasCeo } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend">Select Role</FormLabel>
      <RadioGroup
        aria-label="role"
        name="role"
        value={value}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        <div className={classes.labelContainer}>
          <FormControlLabel
            value="admin"
            control={<Radio color="primary" />}
            label="Admin"
          />
          <div className={classes.chipContainer}>
            <Chip label="Manage Users" size="small" variant="outlined" />
            <Chip label="Company Settings" size="small" variant="outlined" />
          </div>
        </div>
        <div className={classes.labelContainer}>
          <FormControlLabel
            value="ceo"
            control={<Radio color="primary" />}
            label="CEO"
            disabled={hasCeo}
          />
          <div className={classes.chipContainer}>
            <Chip label="Company Dashboard" size="small" variant="outlined" />
            <Chip label="Analytics" size="small" variant="outlined" />
            <Chip label="Portfolio" size="small" variant="outlined" />
            <Chip label="Manage Users" size="small" variant="outlined" />
            <Chip label="Company Settings" size="small" variant="outlined" />
          </div>
        </div>
      </RadioGroup>
    </FormControl>
  );
}

RoleSelection.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  hasCeo: PropTypes.bool.isRequired,
};
